import * as React from 'react';
import type { ReactNode } from 'react';
import { Link } from '../Link';

export const MarkdownLink = ({
  children,
  href,
}: {
  children: ReactNode;
  href: string;
}) => {
  // The href of user input is cleaned by react-markdown transformLinkUri filter
  // We do no open absolute paths, # or /, in a new tab
  if (href.startsWith('/') || href.startsWith('#'))
    return <Link href={href}>{children}</Link>;

  const isMentimeterOrMentiDomain =
    href.includes('mentimeter.com') || href.includes('menti.com');
  // We open all mentimeter or menti domains in a new tab with with only noopener
  if (isMentimeterOrMentiDomain)
    return (
      <Link href={href} target="_blank" rel="noopener">
        {children}
      </Link>
    );
  // We open external domains in a new tab with noopener noreferrer nofollow
  return (
    <Link href={href} target="_blank" rel="noopener noreferrer nofollow">
      {children}
    </Link>
  );
};
