import React from 'react';
import { Box, MotionContainer, Text } from '@mentimeter/ragnar-ui';
import { HourGlass } from '@mentimeter/ragnar-visuals';

const PresentationInProgress = ({
  pictogramColor,
}: {
  pictogramColor: string | undefined;
}) => {
  return (
    <Box width="100%" my="space14" alignItems="center">
      <MotionContainer animation="fade">
        <MotionContainer animation="float">
          <Box height="112px">
            <HourGlass userColor={pictogramColor} />
          </Box>
        </MotionContainer>
      </MotionContainer>
      <Text mt="space6" mb="space2">
        Presentation in progress...
      </Text>
    </Box>
  );
};

export default PresentationInProgress;
