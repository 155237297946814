import * as React from 'react';
import { useDevice } from '@mentimeter/ragnar-device';
import { Button, TooltipControlled, type ButtonT } from '@mentimeter/ragnar-ui';
import type { Extend } from '@mentimeter/ragnar-dsc';
import { postIntegrationMessage } from '@mentimeter/integrations-shared';
import { trackEvent, useTrack } from '../../utils/tracker';
import { isTryMode } from '../../utils/isTryMode';
import { useTimedUserFeedback } from '../../utils/useTimedController';
import { useSplitTrack } from '../../split.io';
import type { IntegrationContextValueT } from '../integrations';
import { useIntegration } from '../integrations';
import { useTranslate } from '../localization/Translate';

interface Props {
  hasCustomTheme?: boolean;
  variant?: ButtonT['variant'];
  size?: ButtonT['size'];
  text?: string;
  extend?: Extend;
  continueParam?: string;
}

const wwwUrl = process.env.NEXT_PUBLIC_WWW_URL;
const DEFAULT_UTM_SOURCE = 'govote';

function SignUpButton({
  hasCustomTheme,
  variant,
  text,
  extend,
  size = 'large',
  continueParam,
}: Props) {
  const translate = useTranslate();
  const { userFeedback, setUserFeedback } = useTimedUserFeedback();
  const { integrationId, isInternalIntegration }: IntegrationContextValueT =
    useIntegration();
  const device = useDevice();
  const trackSplit = useSplitTrack();
  const trackVoter = useTrack();

  const utmSource = integrationId || DEFAULT_UTM_SOURCE;

  const continueQuery = continueParam ? `&continueParam=${continueParam}` : '';
  const signUpUrl = `${wwwUrl}/auth/justvoted?utm_campaign=try%20mentimeter%20after%20voting&utm_medium=button&utm_source=${utmSource}${continueQuery}`;

  // Hide signup when ...

  // ... on the server, to avoid users who shouldn't see the
  // button having it visible during javascript rehydration
  if (!device.initialized) return null;

  // ... the presenter is using their own custom theme
  if (hasCustomTheme) return null;

  if (isTryMode())
    return (
      <>
        <TooltipControlled
          show={userFeedback.show}
          referenceId={userFeedback.referenceId}
          description="Feature not available in Preview"
        />
        <Button
          size="large"
          id="signup-button-tooltip-anchor"
          mb={2}
          onClick={() =>
            setUserFeedback({
              show: true,
              referenceId: 'signup-button-tooltip-anchor',
            })
          }
        >
          {text ?? translate('success.signup')}
        </Button>
      </>
    );

  const onSignUpClick = (e: React.MouseEvent) => {
    trackEvent('link', 'clicked', 'Try Mentimeter');
    trackSplit('End_screen.Link_clicked.Try_mentimeter');
    trackVoter({
      event: 'Clicked sign up',
      properties: { context: 'End screen' },
    });

    if (isInternalIntegration && window.parent) {
      e.preventDefault();

      // For integrations: this sends an event to the frame holding the voting web
      postIntegrationMessage(
        window?.parent,
        {
          type: 'open-url',
          payload: signUpUrl,
        },
        wwwUrl || '*',
      );
    }
  };

  return (
    <Button
      size={size}
      // If external integration, we need to open mentimeter.com in new window
      target={integrationId && !isInternalIntegration ? '_blank' : '_self'}
      rel={
        integrationId && !isInternalIntegration
          ? 'noopener noreferrer'
          : undefined
      }
      href={signUpUrl}
      onClick={onSignUpClick}
      variant={variant}
      extend={extend}
    >
      {text ?? translate('success.signup')}
    </Button>
  );
}

export default SignUpButton;
