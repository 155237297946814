import React from 'react';
import { type As } from '@mentimeter/ragnar-dsc';

interface ScreenReaderOnlyT {
  children: React.ReactNode;
  id?: string;
  as?: As;
}

export const ScreenReaderOnly = ({ children, id, as }: ScreenReaderOnlyT) => {
  const Element = as || 'span';
  return (
    <Element className="sr-only" id={id}>
      {children}
    </Element>
  );
};
