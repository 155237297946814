import type Ably from 'ably';

const ablyKey = process.env.NEXT_PUBLIC_ABLY_KEY as string;
const ablyRealtimeHost = process.env.NEXT_PUBLIC_ABLY_REALTIME_HOST;
const ablyFallbacks = process.env.NEXT_PUBLIC_ABLY_FALLBACKS;

export const ablyConfig: Ably.ClientOptions = {
  key: ablyKey,
  restHost: ablyRealtimeHost ? ablyRealtimeHost : '',
  realtimeHost: ablyRealtimeHost ? ablyRealtimeHost : '',
  fallbackHosts: ablyFallbacks ? ablyFallbacks.split(',') : [],
};
