import * as React from 'react';
import { alpha } from '@mentimeter/ragnar-colors';
import { Svg } from '../../shared-components';
import { COLOR_MAP } from '../colorMap';
import type { Pictogram } from '../types';

export const PaperPlane = ({ userColor, variant = 'green' }: Pictogram) => {
  return (
    <Svg viewBox="0 0 112 120" aria-hidden="true" className="light">
      <path
        d="M96 113V17L47.57 64.978 96 113Z"
        fill={
          userColor
            ? userColor && alpha(userColor, 0.6)
            : COLOR_MAP[variant].pictogramTertiary
        }
      />
      <path
        d="m96 17-96 .27 47.978 47.978L96 17Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramSecondary}
      />
    </Svg>
  );
};
