import { Box, List, Text, type BoxT } from '@mentimeter/ragnar-ui';
import * as React from 'react';
import { StopSolidIcon } from '@mentimeter/ragnar-visuals';
import { SeriesLogo } from '../questions/SeriesLogo';
import { Footer, PageLayout } from '../../ui';
import { useTranslate } from '../localization/Translate';

interface IdentificationScreenProps {
  children: React.ReactNode;
}

interface ListItemProps extends BoxT {
  text: string;
}

const ListItem = ({ text, ...rest }: ListItemProps) => {
  return (
    <Box as="li" flexDirection="row" alignItems="center" gap={3} {...rest}>
      <Box>
        <StopSolidIcon size={1} />
      </Box>
      <Text fontSize="100" lineHeight="150">
        {text}
      </Text>
    </Box>
  );
};

export const IdentificationScreen = ({
  children,
}: IdentificationScreenProps) => {
  const translate = useTranslate();

  return (
    <PageLayout footer={<Footer context="Home" />}>
      <Box
        flex="1"
        width="100%"
        gap="space2"
        alignItems="stretch"
        m="auto"
        maxWidth={[null, '600px']}
      >
        <SeriesLogo />
        <Text fontSize="175" mb={2} color="text">
          {translate('identified_responses.what_is_your_name')}
        </Text>
        <List>
          <ListItem
            text={translate(
              'identified_responses.participant_names_are_required',
            )}
            mb={3}
          />
          <ListItem
            text={translate('identified_responses.your_name_will_be_recorded')}
          />
        </List>
        {children}
      </Box>
    </PageLayout>
  );
};
