import { cva } from '@mentimeter/ragnar-tailwind-config';
import { type VariantProps } from 'class-variance-authority';

export const variants = cva([], {
  variants: {
    ratio: {
      square: ['before:pb-[100%]'], //1:1
      landscape: ['before:pb-[56.25%]'], //16:9
      portrait: ['before:pb-[177.7778%]'], //9:16
    },
  },
  defaultVariants: {
    ratio: 'square',
  },
});

export type RatioVariantProps = VariantProps<typeof variants>;
