import React from 'react';
import type { VotingSDKT } from '@mentimeter/question-modules-types';

export const VotingContext = React.createContext<VotingSDKT | null>(null);
VotingContext.displayName = 'QuestionModulesVotingContext';

export function useVotingContext() {
  const context = React.useContext(VotingContext);
  if (!context) {
    throw new Error(
      'Trying to use voting context before it is set or without adding a provider.',
    );
  }

  return context;
}
