import { cva } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva([], {
  variants: {
    variant: {
      heading: ['text text-175', 'leading-snug mb-2'],
      subheading: ['text text-100', 'font-semibold', 'leading-snug mb-2'],
      paragraph: ['text-100', 'leading-body mb-2'],
      strong: ['font-semibold', 'font-size-inherit', 'leading-inherit'],
      meta: ['text-87.5', 'leading-normal', 'text-weaker'],
    },
  },
});
