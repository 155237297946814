import * as React from 'react';
import useSWR from 'swr';
import { getSeriesByKey } from '../api/series';
import { useAppDispatch } from '../redux-hooks';
import { refreshSeriesSuccess } from '../reducers/seriesSlice';

const hardReloadPageTimeout = process.env.NEXT_PUBLIC_HARD_RELOAD_PAGE_MS
  ? parseInt(process.env.NEXT_PUBLIC_HARD_RELOAD_PAGE_MS, 10)
  : 1000 * 60 * 60 * 24;

const createdAt = Date.now();

export function useRefreshSeries(voteKey: string, enableAutoRefresh = true) {
  const dispatch = useAppDispatch();

  const { mutate } = useSWR(
    `/series/${voteKey}`,
    async () => {
      try {
        const { data } = await getSeriesByKey(voteKey);
        dispatch(refreshSeriesSuccess(data));
        return data;
      } catch (err) {
        // Ignore fails on refresh
        // TODO: Does it make sense to act on errors here?
        return;
      }
    },
    {
      refreshInterval: 0,
      revalidateOnFocus: enableAutoRefresh,
      revalidateOnReconnect: enableAutoRefresh,
    },
  );

  return React.useCallback(() => {
    const isExpired =
      createdAt > 0 && Date.now() - createdAt > hardReloadPageTimeout;
    if (isExpired) window.location.reload();
    else mutate();
  }, [mutate]);
}
