import { useEffect, useRef } from 'react';
import type { Question } from '@mentimeter/http-clients';

interface UseAutoOpenQfaPanelParameters {
  moduleId: Question['module_id'] | undefined;
  openQfaPanel: () => void;
  isQfaPanelOpen: boolean;
}

export function useAutoOpenQfaPanel({
  moduleId,
  openQfaPanel,
  isQfaPanelOpen,
}: UseAutoOpenQfaPanelParameters) {
  const qfaPanelStateRef = useRef(isQfaPanelOpen);

  useEffect(() => {
    qfaPanelStateRef.current = isQfaPanelOpen;
  }, [qfaPanelStateRef, isQfaPanelOpen]);

  // this needs to know the current open/close state, but it should NOT run when that state changes, hence the ref
  useEffect(() => {
    if (moduleId === 'qfa' && !qfaPanelStateRef.current) {
      openQfaPanel();
    }
  }, [moduleId, qfaPanelStateRef, openQfaPanel]);
}
