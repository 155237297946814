import * as React from 'react';
import { Button, Box } from '@mentimeter/ragnar-ui';
import type { AudienceQfaQuestion } from '@mentimeter/http-clients';
import { useTranslate } from '../localization/Translate';
import { useAppDispatch } from '../../redux-hooks';
import { submitAudienceQuestion } from './qfaSlice';
import { QfaBrowseView } from './QfaBrowseView';
import { QfaFormContainer } from './QfaFormContainer';
import { QfaForm } from './QfaForm';

export const QfaPanelContent = ({
  voteKey,
  questions,
  activePublicKey,
}: {
  voteKey: string;
  questions: AudienceQfaQuestion[] | undefined;
  activePublicKey?: string | undefined;
}) => {
  const translate = useTranslate();
  const dispatch = useAppDispatch();
  const [postQuestionView, setPostQuestionView] = React.useState(false);
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const onSubmit = (question: string) => {
    if (question.length > 0) {
      dispatch(
        submitAudienceQuestion({
          voteKey,
          question,
          askedOnQuestionKey: activePublicKey,
        }),
      );
    }
    timeoutRef.current = setTimeout(() => {
      setPostQuestionView(false);
    }, 2000);
  };

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <Box
      pt="space14"
      pb="space10"
      width="100%"
      height="80vh"
      overflow="auto"
      extend={() => ({
        scrollBehavior: 'smooth',
      })}
      ref={containerRef}
    >
      <Box width="100%" alignItems="stretch">
        {postQuestionView ? (
          <QfaFormContainer goBack={() => setPostQuestionView(false)}>
            <QfaForm onSubmit={onSubmit} />
          </QfaFormContainer>
        ) : (
          <>
            <QfaBrowseView />

            <Box
              alignItems="center"
              pt="space5"
              {...(questions?.length && {
                borderColor: 'borderWeak',
                borderTopWidth: '1px',
                borderStyle: 'solid',
              })}
            >
              <Button
                size="large"
                aria-label="Ask new question"
                variant="primary"
                onClick={() => {
                  containerRef?.current?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                  setPostQuestionView(true);
                }}
              >
                {translate('buttons.ask_question')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
