import { usePathname, useRouter } from 'next/navigation';
import { resetIdentifier } from '../../utils/identifier';

export const useVoteAgain = (voteKey: string) => {
  const router = useRouter();
  const pathname = usePathname();

  return function voteAgain() {
    resetIdentifier();
    if (pathname === `/${voteKey}` || pathname === `/${voteKey}/0`) {
      router.refresh();
    } else {
      router.push(`/${voteKey}`);
    }
  };
};
