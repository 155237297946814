import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva([], {
  variants: {
    size: {
      default: ['p-1.5'],
      compact: ['p-1'],
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export type BadgeIconContainerVariantProps = VariantProps<typeof variants>;
