import * as React from 'react';
import {
  Actionsheet as ActionsheetFixed,
  Box,
  Clickable,
  ThemeBgSensitive,
  type ThemeBgSensitiveT,
} from '@mentimeter/ragnar-ui';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { ActionsheetAbsolute } from './ActionsheetAbsolute';
export interface BottomPanelProps extends Pick<ThemeBgSensitiveT, 'themeBg'> {
  children: React.ReactNode;
  show: boolean;
  onDismiss: () => void;
  absolute?: boolean;
}

export const BottomPanel = ({
  children,
  show,
  onDismiss,
  absolute = false,
  themeBg,
}: BottomPanelProps) => {
  React.useEffect(() => {
    if (!show) return;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [show]);
  const Actionsheet = absolute ? ActionsheetAbsolute : ActionsheetFixed;
  const containerProps = absolute
    ? { left: 0, right: 0 }
    : { width: ['100vw', '600px'] };

  return (
    <Actionsheet show={show} onDismiss={onDismiss}>
      <ThemeBgSensitive themeBg={themeBg}>
        <Box
          className="bottom-panel"
          alignItems="stretch"
          height="100%"
          {...containerProps}
        >
          <Box
            width="100%"
            bg="bg"
            borderTopLeftRadius="xl"
            borderTopRightRadius="xl"
            alignItems="stretch"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <Clickable
              position="absolute"
              right={4}
              top={4}
              zIndex={999}
              p="space4"
              onClick={onDismiss}
              data-testid="bottom-panel-close"
              aria-label="Close panel"
            >
              <CrossIcon size={3} color="text" />
            </Clickable>

            {children}
          </Box>
        </Box>
      </ThemeBgSensitive>
    </Actionsheet>
  );
};
