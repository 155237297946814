import { createAsyncThunk } from '@reduxjs/toolkit';
import type { UpvotePayload } from '@mentimeter/http-clients';
import {
  addBreadcrumb,
  captureException,
  MentiError,
} from '@mentimeter/errors/sentry';
import { voting, wasHandledNetworkError } from '@mentimeter/http-clients';
import * as seriesApi from '../api/series';
import { UNKNOWN, RESPONSE } from '../utils/errorMessages';
import { resetIdentifier } from '../utils/identifier';

// For edge case in which all votes/identifiers are deleted
// (i.e. via adm-app spam vote tool). This resets server-side
// voter ID, client cookie and triggers submitVote again.
// identifierAlreadyReset is an easy but ugly way to
// avoid an infinite loop that could potentially
// create many unnecessary identifiers, effectively
// spamming our own back end.
let identifierAlreadyReset = false;
function refreshUserIdentity() {
  identifierAlreadyReset = true;
  resetIdentifier();
}

export interface SubmitVoteBody<T = unknown> {
  questionPublicKey: string;
  payload: T;
  partial?: boolean;
}

export type SubmitVoteFn<T = unknown> = (body: SubmitVoteBody<T>) => void;

export const submitVote = createAsyncThunk<
  { partial: boolean; questionId: string },
  SubmitVoteBody<unknown>,
  { rejectValue: string }
>(
  'submitVote',
  async (
    { questionPublicKey, partial = false, payload },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await seriesApi.postVote(questionPublicKey, payload);
      return { partial, questionId: questionPublicKey };
    } catch (err: any) {
      const reason = err?.response?.data?.reason;
      const message = err?.response?.data?.message;
      const status = err?.response?.status;
      const code = err?.code;

      if (reason) {
        return rejectWithValue(reason);
      } else if (
        // Super special use case for 401 response
        // after adm-app spam vote tool is employed
        status === 401 &&
        message === 'Identifier is invalid, try create one' &&
        !identifierAlreadyReset
      ) {
        refreshUserIdentity();
        dispatch(submitVote({ questionPublicKey, payload, partial }));

        return rejectWithValue(UNKNOWN);
      } else if (
        code === 'ECONNABORTED' ||
        code === 'ETIMEDOUT' ||
        code === 'ERR_NETWORK' ||
        err?.crossDomain
      ) {
        return rejectWithValue(RESPONSE);
      } else if (err instanceof Error && !wasHandledNetworkError(err)) {
        // Only report unknown failures
        addBreadcrumb({
          message: 'submitVote error',
          data: {
            identifierAlreadyReset,
            questionPublicKey,
            partial,
            payload,
            err,
          },
        });
        captureException(
          new MentiError('submit vote failed', {
            cause: err,
            feature: 'live',
          }),
        );
        return rejectWithValue(UNKNOWN);
      } else {
        return rejectWithValue(UNKNOWN);
      }
    }
  },
);

interface SubmitUpvoteBody {
  questionPublicKey: string;
  payload: UpvotePayload;
  partial?: boolean;
  voteId: string;
}

export type SubmitUpvoteFn = (body: SubmitUpvoteBody) => void;

export const submitUpvote = createAsyncThunk<
  { partial: boolean; questionId: string },
  SubmitUpvoteBody,
  { rejectValue: string }
>(
  'submitUpvote',
  async (
    { questionPublicKey, partial = false, payload, voteId },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await seriesApi.postUpvote({
        questionPublicKey,
        payload,
        voteId,
      });
      return { partial, questionId: questionPublicKey };
    } catch (err: any) {
      const reason = err?.response?.data?.reason;
      const message = err?.response?.data?.message;
      const status = err?.response?.status;
      const code = err?.code;

      if (reason) {
        return rejectWithValue(reason);
      } else if (
        // Super special use case for 401 response
        // after adm-app spam vote tool is employed
        status === 401 &&
        message === 'Identifier is invalid, try create one' &&
        !identifierAlreadyReset
      ) {
        refreshUserIdentity();
        dispatch(submitUpvote({ questionPublicKey, payload, partial, voteId }));
        return rejectWithValue(UNKNOWN);
      } else if (
        code === 'ECONNABORTED' ||
        code === 'ETIMEDOUT' ||
        code === 'ERR_NETWORK' ||
        err?.crossDomain
      ) {
        return rejectWithValue(RESPONSE);
      } else if (err instanceof Error && !wasHandledNetworkError(err)) {
        // Only report unknown failures
        addBreadcrumb({
          message: 'submitUpvote error',
          data: {
            identifierAlreadyReset,
            questionPublicKey,
            partial,
            payload,
            err,
          },
        });
        captureException(
          new MentiError('submit upvote failed', {
            cause: err,
            feature: 'live',
          }),
        );
        return rejectWithValue(UNKNOWN);
      } else {
        return rejectWithValue(UNKNOWN);
      }
    }
  },
);

export const fetchVoterResults = async (questionPublicKey: string) => {
  return voting().results.get(questionPublicKey);
};
