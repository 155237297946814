import React from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { Title } from '@radix-ui/react-dialog';
import type { DialogTitleProps } from '@radix-ui/react-dialog';
import { cn, cva } from '@mentimeter/ragnar-tailwind-config';

interface DialogTitlePrimitiveProps extends DialogTitleProps {
  size?: 'small' | 'medium' | 'large' | undefined;
}

const variants = cva(['leading-snug', 'font-heading', 'text', 'font-normal'], {
  variants: {
    size: { large: 'text-175', medium: 'text-125', small: 'text-100' },
  },
  defaultVariants: { size: 'medium' },
});

const FelaModalTitle = styled(
  Title,
  {
    displayName: 'DialogRadixTitleStyled',
  },
  true,
)('color', 'kosmosSpacing');

export const ModalTitle = React.forwardRef<
  HTMLHeadingElement,
  DialogTitlePrimitiveProps
>((props, ref) => {
  const { className, size, ...rest } = props;

  return (
    <FelaModalTitle
      ref={ref}
      className={cn(variants({ size }), className)}
      {...rest}
    />
  );
});
