import React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { As } from '@mentimeter/ragnar-dsc';

/**
 * Portal helper for aligning portal elements.
 * This is the only component that should have `pointerEvents`
 * as it's responsible for making the UI snappy even though animations
 * happen.
 */
interface Props {
  center?: boolean;
  zIndex?: number;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  as?: As;
}

export const PortalPositioner = ({
  className,
  center,
  zIndex,
  children,
  style,
  as,
}: Props) => {
  const Element = as || 'div';

  const defaultClasses = [
    'fixed',
    'top-0',
    'left-0',
    'w-screen',
    'h-screen',
    'overflow-auto',
    'flex',
    'justify-center',
    center ? 'items-center' : 'items-start',
    'z-[var(--portal-helper-z-index)]',
  ];

  return (
    <Element
      style={
        {
          '--portal-helper-z-index': zIndex !== undefined ? zIndex : 1,
          ...style,
        } as React.CSSProperties
      }
      className={cn(defaultClasses, className)}
    >
      {children}
    </Element>
  );
};
