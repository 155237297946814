import * as React from 'react';
import { alpha } from '@mentimeter/ragnar-colors';
import { Svg } from '../../shared-components';
import { COLOR_MAP } from '../colorMap';
import type { Pictogram } from '../types';

export const HourGlass = ({ userColor, variant = 'yellow' }: Pictogram) => {
  return (
    <Svg viewBox="0 0 80 120" aria-hidden="true" className="light">
      <path
        d="M80.022 6H0v17.783h.008A39.698 39.698 0 0 1 0 22.98h80.022c0 .268-.003.536-.009.803h.009V6ZM54.117 60.253H25.904l.044.017a40.024 40.024 0 0 0-14.227 9.124A39.746 39.746 0 0 0 .009 96.818H0V114.6h80.022V97.56h.001l-.001-.385v-.357h-.006a39.746 39.746 0 0 0-11.711-27.424 40.025 40.025 0 0 0-14.23-9.125l.042-.016Z"
        fill={
          userColor
            ? userColor && alpha(userColor, 0.6)
            : COLOR_MAP[variant].pictogramTertiary
        }
      />
      <path
        d="M68.303 51.146A39.743 39.743 0 0 0 80.022 22.98H0a39.743 39.743 0 0 0 11.719 28.166 40.024 40.024 0 0 0 14.18 9.107h28.223a40.024 40.024 0 0 0 14.18-9.107Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramSecondary}
      />
    </Svg>
  );
};
