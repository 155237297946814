import React from 'react';
import type { NodeType } from '@mentimeter/ragnar-markdown';
import { RagnarMarkdown } from '@mentimeter/ragnar-markdown';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { RemarkMath } from './MathParser';
import EmojiParser from './EmojiParser';

interface MathT {
  value: string;
}
interface NodeT {
  children: React.ReactNode;
  [x: string]: any;
}
interface LinkT extends NodeT {
  href: string;
}

export interface ComponentsT {
  Emoji?: React.FC<NodeT>;
  Em?: React.FC<NodeT>;
  Strong?: React.FC<NodeT>;
  Code?: React.FC<NodeT>;
  Math?: React.FC<MathT>;
  Link?: React.FC<LinkT>;
}

const createRenderers = (
  components: ComponentsT = {},
  source: string,
  disableMarkdown: boolean,
) => ({
  // For all text nodes, check for tweemoji and set it dangerously
  text: ({ children }: NodeT) => {
    const C = components?.Emoji ?? EmojiParser;
    return <C>{disableMarkdown ? source : children}</C>;
  },
  paragraph: ({ children }: NodeT) => {
    const C = components?.Emoji ?? EmojiParser;
    if (disableMarkdown) return <C>{source}</C>;
    // Do not render the <p> tag for this renderer.
    return (
      <>
        {children}
        <br />
      </>
    );
  },
  emphasis: ({ children }: NodeT) => {
    const C = components?.Em ?? 'em';
    return <C>{children}</C>;
  },
  strong: ({ children }: NodeT) => {
    const C = components?.Strong ?? 'strong';
    return <C>{children}</C>;
  },
  inlineCode: ({ children }: NodeT) => {
    const C = components?.Code ?? 'code';
    return <C>{children}</C>;
  },
  math: ({ value }: MathT) =>
    components?.Math ? <components.Math value={value} /> : value,
  inlineMath: ({ value }: MathT) =>
    components?.Math ? <components.Math value={value} /> : value,
  link: ({ children, href }: LinkT) => {
    const C = components?.Link ?? 'a';
    const sanitizedUrl = sanitizeUrl(href);
    const isURL = /^https?:\/\/|^mailto:/i;
    if (isURL.test(sanitizedUrl)) {
      return <C href={sanitizedUrl}>{children}</C>;
    } else {
      return children;
    }
  },
});

interface StringParserT {
  source: string;
  components?: ComponentsT;
  disableMarkdown?: boolean;
  disableLineBreaks?: boolean;
  allowBlankLines?: boolean;
  allowHyperlinks?: boolean;
}

const plugins = [RemarkMath];
const MAX_CHARACTERS = 1000;

const StringParser = ({
  source,
  components,
  disableMarkdown = false,
  disableLineBreaks = false,
  allowBlankLines = false,
  allowHyperlinks = false,
}: StringParserT) => {
  const memoizedRenderers = React.useMemo(
    () => createRenderers(components, source, disableMarkdown),
    [components, source, disableMarkdown],
  );

  const memoizedAllowedTypes = React.useMemo(() => {
    const allowedTypes: NodeType[] = [
      'emphasis',
      'text',
      'strong',
      'inlineCode',
      'html',
      'delete',
    ];
    if (allowHyperlinks) allowedTypes.push('link');
    if (!disableLineBreaks) allowedTypes.push('paragraph');
    return allowedTypes;
  }, [disableLineBreaks, allowHyperlinks]);

  const text = React.useMemo(() => {
    if (typeof source !== 'string') {
      return null;
    }
    if (source.length > MAX_CHARACTERS) {
      return source;
    }
    // modify text so markdown recognizes blank lines
    if (allowBlankLines) {
      // split the text before and after every linebreak
      const modifiedSegments = source.split(/(\n)/).map((segment) =>
        // an empty segment represents a blank line -> add a non-breaking space
        segment === '' ? '&nbsp;' : segment,
      );
      return modifiedSegments.join('');
    }
    return source;
  }, [source, allowBlankLines]);

  if (text === null) {
    return null;
  }

  if (text.length > MAX_CHARACTERS) {
    return source;
  }

  return (
    <RagnarMarkdown
      plugins={plugins}
      renderers={memoizedRenderers}
      source={text}
      unwrapDisallowed
      allowedTypes={memoizedAllowedTypes}
    />
  );
};

export default StringParser;
