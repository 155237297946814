import * as React from 'react';
import { useCallback } from 'react';
import { BottomPanel } from '@mentimeter/voting-ui';
import type { Question } from '@mentimeter/http-clients';
import { withTheme, type ThemeProps } from '@mentimeter/ragnar-react';
import { useAppDispatch, useAppSelector } from '../../redux-hooks';
import {
  closeQfaPanel,
  openQfaPanel as openQfaPanelAction,
} from '../qfa/qfaSlice';
import { trackEvent } from '../../utils/tracker';
import { useSplitTrack } from '../../split.io';
import { QfaButton } from './QfaButton';
import { QfaPanelContent } from './QfaPanelContent';
import { useAutoOpenQfaPanel } from './useAutoOpenQfaPanel';

export interface QfaProps extends ThemeProps {
  absolute?: boolean;
  isFinishScreen?: boolean;
  activePublicKey?: string | undefined;
  moduleId?: Question['module_id'] | undefined;
  Trigger?: React.ComponentType<{ onClick: () => void }>;
}

const QfaWithTheme = ({
  isFinishScreen = false,
  absolute = false,
  activePublicKey,
  moduleId,
  Trigger = QfaButton,
  theme,
}: QfaProps) => {
  const { questions, isQfaPanelOpen } = useAppSelector((state) => state.qfa);
  const voteKey = useAppSelector((state) => state.series.vote_key);

  const dispatch = useAppDispatch();
  const trackSplit = useSplitTrack();

  const openQfaPanel = useCallback(
    () => dispatch(openQfaPanelAction()),
    [dispatch],
  );

  const openQfa = React.useCallback(() => {
    trackEvent('QFA', 'Opened QFA List');
    if (isFinishScreen) trackSplit('End_screen.Button_clicked.Open_QFA');
    openQfaPanel();
  }, [isFinishScreen, trackSplit, openQfaPanel]);

  const onDismiss = React.useCallback(() => {
    dispatch(closeQfaPanel());
  }, [dispatch]);

  useAutoOpenQfaPanel({
    moduleId,
    isQfaPanelOpen,
    openQfaPanel,
  });

  return (
    <>
      <Trigger onClick={openQfa} />

      <BottomPanel
        absolute={absolute}
        show={isQfaPanelOpen}
        onDismiss={onDismiss}
        themeBg={theme.colors.bg}
      >
        <QfaPanelContent
          questions={questions}
          voteKey={voteKey}
          activePublicKey={activePublicKey}
        />
      </BottomPanel>
    </>
  );
};

export const Qfa = withTheme(QfaWithTheme);
