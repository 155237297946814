import * as React from 'react';
import { Box, Clickable, Text, Button } from '@mentimeter/ragnar-ui';

export const MissingQuestionModuleError = ({
  isContent = false,
  hasLink = false,
}) => {
  const onClick = () => window.location.reload();
  const action = 'Reload';
  const message = isContent ? 'render this content' : 'show this slide';
  return (
    <Box mt={3} width="100%" alignItems="center" justifyContent="center">
      {hasLink ? (
        <Clickable onClick={onClick}>
          <Text fontSize={4} color="primary" fontWeight="semiBold">
            {action}
          </Text>
        </Clickable>
      ) : (
        <Button onClick={onClick}>{action}</Button>
      )}
      <Text mt={2} textAlign="center">
        Something went wrong when trying to {message}. Try reloading. Contact
        support if this problem persists.
      </Text>
    </Box>
  );
};
