import dynamic from 'next/dynamic';
import type { ModuleId, ValidModuleId } from '@mentimeter/http-clients';
import { MentiError } from '@mentimeter/errors/sentry';
import { getValidModuleIdFromString } from './get-valid-module-id';
import { LoadingPlaceholder } from './LoadingPlaceholder';

const MODULE_IMPORT_MAP: Partial<
  Record<
    ValidModuleId,
    () => Promise<{
      Interactive?: React.ComponentType;
      Content: React.ComponentType;
    }>
  >
> = {
  winner: () => import('@mentimeter/question-module-winner/voting'),
};

const EmptyInteractive = () => null;

export function importVotingDynamically(
  moduleId: ModuleId,
  componentType: 'interactive' | 'content',
) {
  const validModuleId = getValidModuleIdFromString(moduleId);
  // Evaluate only when it gets called
  const importFn = MODULE_IMPORT_MAP[validModuleId];

  return dynamic(
    () => {
      if (!importFn) {
        throw new MentiError(
          `Tried to load voting question module ${moduleId} from importVotingDynamically but has already been moved to the voting app.`,
          { feature: 'voting-slide-type-importer' },
        );
      }
      return importFn()
        .then((c) =>
          componentType === 'interactive'
            ? (c.Interactive ?? EmptyInteractive)
            : c.Content,
        )
        .catch((error) => {
          throw new Error(
            `Question modules: Failed to load Voting dynamically for moduleId: '${moduleId}'. Error message: ${error}`,
          );
        });
    },
    { loading: LoadingPlaceholder },
  );
}
