/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import type { Connection } from '../Connection';
import { createQuizConnectionOnce } from '../Connection';
import type { CreateSocketOptions } from '../Socket';

interface SocketIoConnectionContext {
  connection: Connection | null;
  init: () => Connection | void;
}
export const socketIoConnectionContext =
  React.createContext<null | SocketIoConnectionContext>(null);
socketIoConnectionContext.displayName = 'socketIoConnectionContext';

/**
 * Provider for a Socket IO connection, the provider does _not_
 * automatically connect to the API.
 */
export const SocketIoConnectionProvider = React.memo<{
  children: React.ReactNode;
  url: string;
  enabled?: boolean;
  config?: CreateSocketOptions;
  logger?: (message: string) => void;
}>(({ children, url, config, enabled = true, logger }) => {
  const [connection, setConnection] = React.useState<null | Connection>(null);

  const init = React.useCallback((): Connection | void => {
    if (enabled) {
      const connection = createQuizConnectionOnce(url, config);
      setConnection(connection);
      return connection;
    }
  }, [enabled, url, config]);

  React.useEffect(() => {
    if (!enabled) {
      connection?.disconnect();
      setConnection(null);
    }
  }, [connection, enabled]);

  React.useEffect(() => {
    if (logger && connection?.socket) {
      const { socket } = connection;
      const onError = (err: any) => {
        logger(`error due to ${err.message}`);
      };
      const onConnectError = (err: any) => {
        logger(`connect_error due to ${err.message}`);
      };
      socket.on('error', onError);
      socket.on('connect_error', onConnectError);
      return () => {
        socket.off('error', onError);
        socket.off('connect_error', onConnectError);
      };
    }
    return () => {};
  }, [logger, connection]);

  const value = React.useMemo(() => ({ connection, init }), [connection, init]);
  return (
    <socketIoConnectionContext.Provider value={value}>
      {children}
    </socketIoConnectionContext.Provider>
  );
});
SocketIoConnectionProvider.displayName = 'SocketIoConnectionProvider';

/**
 * Consumes the Socket IO context, triggering this hook
 * will initiate a connection with the provided url. The
 * context is optional by design because not all instances
 * of a presentation should have a connection to the quiz-api.
 */
export const useSocketIoConnection = () => {
  const ctx = React.useContext(socketIoConnectionContext);

  React.useEffect(() => {
    if (ctx && !ctx.connection) {
      const connection = ctx.init();
      if (connection && connection.timeSync) {
        // Restart on creation
        connection.timeSync.restart();
      }
    }
  }, [ctx]);

  return ctx || { connection: null, init: () => {} };
};
