import type { Socket } from 'socket.io-client';
import { standardDeviation, median, average, round } from './math';
import type {
  DataPoint,
  OnUpdateDebugFn,
  OnUpdateFn,
  TimeSyncOptions,
} from './types';

const CONNECT_EVENT = 'connect';
const DISCONNECT_EVENT = 'disconnect';
const ERROR_EVENT = 'error';

class TimeSync {
  socket: Socket;
  latencyHistorySize: number;
  discardOutliersWhenHistorySize: number;
  burstRequests: number;
  burstRequestsInterval: number;
  pingInterval: number;
  pingEvent: string; // Subset of strings?
  pongEvent: string;
  timeOffset: number;
  latency: number;
  ready: boolean;

  _pingTimeoutId: ReturnType<typeof setTimeout> | null;
  _data: DataPoint[];
  _burst: boolean;
  _pings: number;

  onUpdate: OnUpdateFn | undefined;
  onUpdateDebug: OnUpdateDebugFn | undefined;

  listeners: OnUpdateFn[] = [];

  constructor({
    socket,
    onUpdate,
    onUpdateDebug,
    latencyHistorySize = 6,
    discardOutliersWhenHistorySize = 2,
    burstRequests = 5,
    burstRequestsInterval = 5000,
    pingInterval = 15000,
    pingEvent = 'ts:client',
    pongEvent = 'ts:server',
  }: TimeSyncOptions) {
    this.socket = socket;
    this.onUpdate = onUpdate;
    this.onUpdateDebug = onUpdateDebug;
    this.latencyHistorySize = latencyHistorySize;
    this.discardOutliersWhenHistorySize = discardOutliersWhenHistorySize;
    this.burstRequests = burstRequests;
    this.burstRequestsInterval = burstRequestsInterval;
    this.pingInterval = pingInterval;
    this.pingEvent = pingEvent;
    this.pongEvent = pongEvent;
    this.timeOffset = -1;
    this.latency = -1;
    this.ready = false;
    this._data = [];
    this._burst = true;
    this._pings = 0;
    this._pingTimeoutId = null;
  }

  addUpdateListener = (fn: OnUpdateFn) => {
    this.listeners.push(fn);
  };

  removeUpdateListener = (fn: OnUpdateFn) => {
    this.listeners = this.listeners.filter((f) => f !== fn);
  };

  start = () => {
    // Reset any old data before starting
    if (this.socket.connected) {
      // In case socket is already had the "connect" event happened
      this._onSocketConnect();
    } else {
      this.socket.on(CONNECT_EVENT, this._onSocketConnect);
    }
  };

  stop = () => {
    if (this._pingTimeoutId) {
      clearTimeout(this._pingTimeoutId);
    }

    // Remove specific listeners related to this class instance
    this.socket.listeners(this.pongEvent);
    this.socket.off(this.pongEvent, this._onPong);
    this.socket.off(ERROR_EVENT, this._onSocketError);
    this.socket.off(DISCONNECT_EVENT, this._onSocketDisconnect);
    this.socket.off(CONNECT_EVENT, this._onSocketConnect);
    // Reset data
    this._defaultData();
  };

  restart = () => {
    // Stop ongoing ping pong, remove event listeners and reset data
    this.stop();
    // Start time sync again (if we are disconnected it will wait for connect)
    this.start();
  };

  getReady = () => this.ready;

  getTimeOffset = () => this.timeOffset;

  getLatency = () => this.latency;

  getAdjustedDate = (clientTime: number | null = null) =>
    getAdjustedDate(clientTime || Date.now(), this.timeOffset);

  getAdjustedFutureDate = (date: number) =>
    getAdjustedFutureDate(date, this.timeOffset);

  _defaultData = () => {
    this.timeOffset = -1;
    this.latency = -1;
    this.ready = false;
    this._data = [];
    this._burst = true;
    this._pings = 0;

    if (this._pingTimeoutId) {
      clearTimeout(this._pingTimeoutId);
      this._pingTimeoutId = null;
    }
  };

  _onSocketError = () => this.restart();

  _onSocketDisconnect = () => this.restart();

  _onSocketConnect = () => {
    // Setup listeners
    this.socket.on(this.pongEvent, this._onPong);
    this.socket.on(ERROR_EVENT, this._onSocketError);
    this.socket.on(DISCONNECT_EVENT, this._onSocketDisconnect);
    // Send a ping (upon receiving a pong we will schedule another ping)
    this._ping();
  };

  _ping = () => {
    const date = Date.now();
    this.socket.emit(this.pingEvent, {
      t0: date,
    });
    this._pings++;
  };

  _schedulePing = () => {
    if (this._pings > this.burstRequests) {
      this._burst = false;
    }
    const timeout = this._burst
      ? this.burstRequestsInterval
      : this.pingInterval;

    this._pingTimeoutId = setTimeout(this._ping, timeout);
  };

  /**
   * An algorithm as described by http://www.mine-control.com/zack/timesync/timesync.html (2016-04-13)
   * A simple algorithm with these properties is as follows:
   * 1. Client stamps current local time on a "time request" packet and sends to server
   * 2. Upon receipt by server, server stamps server-time and returns
   * 3. Upon receipt by client, client subtracts current time from sent time and divides by two to compute latency. It subtracts current time from server time to determine client-server time delta and adds in the half-latency to get the correct clock delta. (So far this algothim is very similar to SNTP)
   * 4. The first result should immediately be used to update the clock since it will get the local clock into at least the right ballpark (at least the right timezone!)
   * 5. The client repeats steps 1 through 3 five or more times, pausing a few seconds each time. Other traffic may be allowed in the interim, but should be minimized for best results
   * 6. The results of the packet receipts are accumulated and sorted in lowest-latency to highest-latency order. The median latency is determined by picking the mid-point sample from this ordered list.
   * 7. All samples above approximately 1 standard-deviation from the median are discarded and the remaining samples are averaged using an arithmetic mean.
   * Timestamps:
   * t0 (client) ------> t1 (server) ------> t2 (client)
   */
  _onPong = (serverData: { t0: number; t1: number }) => {
    // Data point
    const t2 = Date.now();
    const latency = t2 - serverData.t0;
    const timeOffset = serverData.t1 - t2 + latency / 2;
    const dataPoint = { latency, timeOffset };

    // Append the new data point
    this._data.unshift(dataPoint);
    if (this._data.length > this.latencyHistorySize) {
      this._data.pop();
    }

    // Filter out outlier latencies using median and standard deviation
    const latencies = this._data.map((d) => d.latency);
    const timeOffsets = this._data.map((d) => d.timeOffset);
    let filteredData: DataPoint[];
    let std = 0;
    let med = 0;
    if (this._data.length >= this.discardOutliersWhenHistorySize) {
      med = median(latencies);
      std = standardDeviation(latencies);
      const latencyLimit = med + std;

      // Keep inclusive check to always have at least one data point
      filteredData = this._data.filter((d) => d.latency <= latencyLimit);
    } else {
      // We did not filter anything
      filteredData = this._data;
    }

    // Update state with latest data
    const filteredTimeOffsets = filteredData.map((d) => d.timeOffset);
    const averageTimeOffset = average(filteredTimeOffsets);
    const filteredLatencies = filteredData.map((d) => d.latency);
    const averageLatency = average(filteredLatencies);
    this.timeOffset = round(averageTimeOffset);
    this.latency = round(averageLatency);

    // Notify callback that we have updated state
    if (!this.ready) this.ready = true;
    if (this.onUpdate) {
      this.onUpdate({ latency: this.latency, timeOffset: this.timeOffset });
    }
    this.listeners.forEach((fn) =>
      fn({ latency: this.latency, timeOffset: this.timeOffset }),
    );

    // Used for debugging purposes
    if (this.onUpdateDebug) {
      this.onUpdateDebug({
        latency: {
          value: this.latency,
          latestMeasurement: latency,
          std,
          median: med,
          average: averageLatency,
        },
        timeOffset: {
          value: this.timeOffset,
          average: averageTimeOffset,
        },
        latencies: {
          all: latencies,
          size: latencies.length,
          filtered: filteredLatencies,
          fSize: filteredLatencies.length,
        },
        timeOffsets: {
          all: timeOffsets,
          size: timeOffsets.length,
          filtered: filteredTimeOffsets,
          fSize: filteredTimeOffsets.length,
        },
      });
    }

    // Ping again
    this._schedulePing();
  };
}

export const getAdjustedDate = (date: number, timeOffset: number): number =>
  date + timeOffset;

export const getAdjustedFutureDate = (
  date: number,
  timeOffset: number,
): number => {
  const now = Date.now();
  return now + (date - getAdjustedDate(now, timeOffset));
};

export default TimeSync;
