import {
  configureStore,
  createListenerMiddleware,
  type StateFromReducersMapObject,
} from '@reduxjs/toolkit';
import { votingSlice } from './reducers/votingSlice';
import { seriesSlice } from './reducers/seriesSlice';
import { qfaSlice } from './features/qfa/qfaSlice';

const listenerMiddleware = createListenerMiddleware();

const reducer = {
  series: seriesSlice.reducer,
  voting: votingSlice.reducer,
  qfa: qfaSlice.reducer,
};

const initialState = {
  series: seriesSlice.getInitialState(),
  voting: votingSlice.getInitialState(),
  qfa: qfaSlice.getInitialState(),
};

export type RootState = StateFromReducersMapObject<typeof reducer>;
export interface DefaultMiddlewareOptions {
  immutableCheck?: boolean;
  serializableCheck?: boolean;
}

export function createStore(
  preloadedState: Partial<RootState>,
  defaultMiddlewareOptions?: DefaultMiddlewareOptions,
) {
  return configureStore({
    reducer,
    preloadedState: { ...initialState, ...preloadedState },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(defaultMiddlewareOptions).prepend(
        listenerMiddleware.middleware,
      ),
  });
}

export type Store = ReturnType<typeof createStore>;
export type AppDispatch = Store['dispatch'];
