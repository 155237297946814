import * as React from 'react';
import { ModalContent } from './ModalContent';
import type { ModalContentProps } from './ModalContent';
import { ModalOverlay } from './ModalOverlay';
import { ModalPortal } from './ModalPortal';

interface ModalContainerProps extends ModalContentProps {
  withPortal?: boolean;
}

export const ModalContainer = React.forwardRef(
  (
    { withPortal = true, ...contentProps }: ModalContainerProps,
    forwardedRef,
  ) => {
    return withPortal ? (
      <ModalPortal>
        <ModalOverlay />
        <ModalContent ref={forwardedRef} {...contentProps} />
      </ModalPortal>
    ) : (
      <>
        <ModalOverlay />
        <ModalContent ref={forwardedRef} {...contentProps} />
      </>
    );
  },
);
