import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui';
import { PageLayout } from '../../ui';

export const Loading = () => (
  <PageLayout>
    <Box
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      bg="bg"
      p={3}
    >
      <LoaderIcon />
    </Box>
  </PageLayout>
);
