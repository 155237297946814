import useSWR from 'swr';
import { getSeriesIdByKey } from '../../api/series';

export const useSeriesIdByKey = (voteKey: string, enabled: boolean) => {
  const { data: seriesId } = useSWR(
    enabled ? `/series/${voteKey}/series_id` : null,
    async () => {
      try {
        const { data } = await getSeriesIdByKey(voteKey);
        return data.series_id;
      } catch (err) {
        return null;
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return seriesId ?? null;
};
