import type { Context } from './types';

export const DEFAULT_BREAKPOINT = 2;
export const DEFAULT_ORIENTATION = 'portrait';

export const DEFAULT_CONTEXT: Context = {
  isTouch: true,
  orientation: DEFAULT_ORIENTATION,
  breakpoint: DEFAULT_BREAKPOINT,
  initialized: false,
};
