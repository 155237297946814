import * as React from 'react';
import { useAppDispatch } from '../../redux-hooks';
import { onAudiencePaceSlideChange } from '../../reducers/votingSlice';
import { AudiencePaceQuestion } from './AudiencePaceQuestion';
import { useAudiencePace } from './navigation/useAudiencePace';

export const AudiencePace = ({ voteKey }: { voteKey: string }) => {
  const { questionIndex } = useAudiencePace();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(onAudiencePaceSlideChange());
  }, [dispatch, questionIndex]);

  return (
    <AudiencePaceQuestion voteKey={voteKey} questionIndex={questionIndex} />
  );
};
