import type { Socket } from 'socket.io-client';
import type { CreateSocketOptions } from './Socket';
import { defaultSocketOptions, createQuizSocket } from './Socket';
import TimeSync from './TimeSync';

export interface Connection {
  socket: Socket;
  timeSync: TimeSync;
  disconnect: () => void;
}

let socket: Socket | null = null;
let timeSync: TimeSync | null = null;

/**
 * Creates a Socket IO instance together with a TimeSync instance.
 * This function acts as a wrapper of the two classes in order to
 * ensure that we only instantiate one instance of each class. The
 * reason behind this is because it is used in different parts of
 * the codebase.
 */
export const createQuizConnectionOnce = (
  quizUrl: string,
  options: CreateSocketOptions = defaultSocketOptions,
): Connection => {
  if (!socket && !timeSync) {
    socket = createQuizSocket(quizUrl, options);
    timeSync = new TimeSync({ socket });
  }

  const disconnect = () => {
    if (timeSync) timeSync.stop();
    if (socket) socket.disconnect();
    socket = null;
    timeSync = null;
  };

  return {
    socket,
    timeSync,
    disconnect,
  } as Connection;
};
