import React, { useState, useEffect } from 'react';
import * as RadixPortal from '@radix-ui/react-portal';

export function Portal({
  domId,
  asChild = false,
  children,
}: {
  domId?: string;
  asChild?: boolean;
  children: React.ReactNode;
}) {
  const [target, setTarget] = useState<Element | null>(null);

  useEffect(() => {
    if (!domId) return;
    const element = document.getElementById(domId);
    setTarget(element);
  }, [domId]);

  return (
    <RadixPortal.Root asChild={asChild} container={target}>
      {children}
    </RadixPortal.Root>
  );
}
