import React from 'react';
import { Box, Button, CalloutCard } from '@mentimeter/ragnar-ui';
import { ArrowLeftIcon } from '@mentimeter/ragnar-visuals';
import { useTranslate } from '../localization/Translate';
import { useAppSelector } from '../../redux-hooks';

export const QfaFormContainer = ({
  goBack,
  children,
}: {
  goBack: () => void;
  children: React.ReactNode;
}) => {
  const translate = useTranslate();
  const qfaModerationEnabled = useAppSelector((state) =>
    Boolean(state.series.qfa_moderation_enabled),
  );

  return (
    <Box width="100%" flex="1 1 auto" px="space8">
      <Box width="100%" pb="space6">
        <Button
          size="large"
          onClick={goBack}
          variant="subtle"
          iconLeading={ArrowLeftIcon}
        >
          {translate('buttons.back')}
        </Button>
      </Box>

      <Box alignItems="stretch" width="100%" flex="1 1 auto">
        {qfaModerationEnabled && (
          <CalloutCard
            theme="neutral"
            mb="space10"
            compact
            description={translate('qfa.questions_are_moderated')}
          />
        )}
        {children}
      </Box>
    </Box>
  );
};
