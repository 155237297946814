import type { TextTransformProperty } from '@mentimeter/ragnar-styled';
import { QuestionMarkCircleIcon } from '@mentimeter/ragnar-visuals';
import * as React from 'react';
import type { ColorsEnum } from '@mentimeter/ragnar-colors';
import { Box, type BoxT } from '../Box';
import type { PlacementT } from '../Placement';
import { PopoverGeneric } from '../Popover';
import { Text } from '../Text';
import { TooltipGeneric, type TooltipGenericT } from './TooltipGeneric';

const DEFAULT_DELAY = 150;

interface GenericT extends BoxT {
  description: string;
  forceOneLine?: boolean;
}
const Generic = ({
  description,
  forceOneLine = true,
  width = 'auto',
  ...props
}: GenericT) => (
  <Box
    width={width}
    {...props}
    borderRadius="xl"
    bg="highContrast"
    py="space2"
    px="space3"
    extend={() => ({
      whiteSpace: forceOneLine ? 'pre' : undefined,
    })}
  >
    <Text color="onHighContrast" fontSize="87.5">
      {description}
    </Text>
  </Box>
);

export interface TooltipControlledT extends GenericT {
  referenceId: string;
  placement?: TooltipGenericT['placement'];
  show: boolean;
}

export const TooltipControlled = ({
  show,
  referenceId,
  placement,
  ...props
}: TooltipControlledT) => (
  <PopoverGeneric show={show} referenceId={referenceId} placement={placement}>
    <Generic {...props} />
  </PopoverGeneric>
);

export interface TooltipT extends BoxT {
  referenceId: string;
  description: string;
  delay?: number;
  forceOneLine?: boolean;
  placement?: TooltipGenericT['placement'];
  textTransform?: TextTransformProperty;
}
export const Tooltip = ({
  referenceId,
  description,
  delay = DEFAULT_DELAY,
  placement = 'top',
  forceOneLine = true,
  width = 'auto',
  textTransform = 'none',
  ...props
}: TooltipT) => (
  <TooltipGeneric
    referenceId={referenceId}
    bg="highContrast"
    delay={delay}
    placement={placement}
    ignorePointer
  >
    <Box
      width={width}
      {...props}
      borderRadius="xl"
      bg="highContrast"
      py="space2"
      px="space3"
      extend={() => ({ whiteSpace: forceOneLine ? 'pre' : undefined })}
    >
      <Text
        color="onHighContrast"
        fontSize="87.5"
        textTransform={textTransform}
      >
        {description}
      </Text>
    </Box>
  </TooltipGeneric>
);

export interface TooltipDescriptiveT
  extends BoxT,
    Pick<TooltipGenericT, 'portalPosition'> {
  id: string;
  description: string;
  delay?: number;
  disabled?: boolean;
  forceOneLine?: boolean;
  placement?: PlacementT;
}
export const TooltipDescriptive = ({
  id,
  description,
  delay = DEFAULT_DELAY,
  disabled,
  forceOneLine = true,
  placement = 'top',
  width = 'auto',
  maxWidth,
  portalPosition,
  color = 'primary',
  ...props
}: TooltipDescriptiveT) => (
  <>
    <TooltipGeneric
      referenceId={id}
      bg="highContrast"
      delay={delay}
      placement={placement}
      portalPosition={portalPosition}
    >
      <Generic
        description={description}
        forceOneLine={forceOneLine}
        width={width}
        maxWidth={maxWidth}
      />
    </TooltipGeneric>
    {disabled ? null : (
      <Box
        id={id}
        data-testid={`tooltip-${id}`}
        ml="space2"
        display="inline-flex"
        {...props}
        justifyContent="center"
        alignItems="center"
        extend={() => ({ alignSelf: 'center' })}
      >
        <QuestionMarkCircleIcon color={color as ColorsEnum} />
      </Box>
    )}
  </>
);
