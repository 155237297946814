import * as React from 'react';
import katex from 'katex';

export { default as RemarkMath } from 'remark-math';

const renderLatexToString = (value: string) => {
  try {
    return katex.renderToString(value, {
      output: 'html',
    });
  } catch (error) {
    return undefined;
  }
};

export const WRAPPER_STYLES = { margin: '2% 0px' };
interface SvgPropsT {
  math: string;
}

export const MathSvg = ({ math }: SvgPropsT) => {
  const value = math.replace('block', '');

  const svg = renderLatexToString(value);

  const isInline = Boolean(math) && math.indexOf('block') <= -1;
  const Element = isInline ? 'span' : 'div';

  return svg ? (
    <Element
      className="theme-text-color--color"
      style={WRAPPER_STYLES}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  ) : (
    <Element>{math}</Element>
  );
};
