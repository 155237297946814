import * as React from 'react';
import {
  Box,
  Button,
  Label,
  SelectItem,
  type ButtonT,
} from '@mentimeter/ragnar-ui';
import { ArrowUpIcon, Qa, RefreshIcon } from '@mentimeter/ragnar-visuals';
import { usePrevious } from '@mentimeter/react-hooks';
import difference from 'lodash/difference';
import debounce from 'lodash/debounce';
import { type PropsWithChildren, useMemo, useState } from 'react';
import { animated, useTransition, type SpringValues } from '@react-spring/web';
import { useTranslate } from '../localization/Translate';
import { useAppDispatch, useAppSelector } from '../../redux-hooks';
import { trackEvent } from '../../utils/tracker';
import {
  fetchAudienceQuestions,
  fetchNextAudienceQuestionsPage,
  setSortByUpvote,
  updateUpvote,
} from './qfaSlice';
import { QfaQuestions } from './QfaQuestions';
import { useQfaUpdates } from './useQfaUpdates';

const SCROLL_THRESHOLD_FOR_HIDING_NEW_QUESTIONS_BUTTON = 50;

type UpdateNotification = 'new_questions' | 'updated_questions';

export const QfaBrowseView = () => {
  useQfaUpdates();

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const [updateNotification, setUpdateNotification] = useState<
    UpdateNotification | undefined
  >();

  const dispatch = useAppDispatch();
  const translate = useTranslate();

  React.useEffect(() => {
    dispatch(fetchAudienceQuestions());
  }, [dispatch]);

  const loadMore = React.useCallback(() => {
    dispatch(fetchNextAudienceQuestionsPage());
  }, [dispatch]);

  const updateUpvoteForQuestion = React.useCallback(
    (id: number, isUpvoted: boolean) => {
      dispatch(updateUpvote({ id, isUpvoted }));
    },
    [dispatch],
  );
  const {
    shouldDoLiveUpdate,
    hasNextPage,
    questions,
    sortByUpvote,
    userQuestions,
  } = useAppSelector((state) => state.qfa);
  const qfaIntercomEnabled = useAppSelector(
    (state) => state.series.qfa_intercom_enabled,
  );
  const [visibleQuestions, setVisibleQuestions] = React.useState(
    questions || [],
  );

  const prevQuestions = usePrevious(questions);
  React.useEffect(() => {
    if (!questions) return;

    if (!shouldDoLiveUpdate || sortByUpvote) {
      setVisibleQuestions(questions);
      return;
    }

    // Update the visible questions if the user is at the top or initial load
    if (
      !containerRef.current ||
      containerRef.current.scrollTop <
        SCROLL_THRESHOLD_FOR_HIDING_NEW_QUESTIONS_BUTTON ||
      !prevQuestions
    ) {
      setVisibleQuestions(questions);
      return;
    }

    if (updateNotification === 'new_questions') {
      // we're already showing the notification, do not need to perform the diffs below
      return;
    }

    // Show the button if there are new questions from other users
    const prevOtherUsersQuestionIds = prevQuestions
      .map(({ id }) => id)
      .filter((id) => !userQuestions.includes(id));
    const otherUsersQuestionIds = questions
      .map(({ id }) => id)
      .filter((id) => !userQuestions.includes(id));

    const thereAreNewQuestions =
      difference(otherUsersQuestionIds, prevOtherUsersQuestionIds).length > 0;

    const thereAreUpdatedQuestions =
      difference(prevOtherUsersQuestionIds, otherUsersQuestionIds).length > 0;

    if (thereAreNewQuestions) {
      setUpdateNotification('new_questions');
    } else if (thereAreUpdatedQuestions) {
      setUpdateNotification('updated_questions');
    }
  }, [
    prevQuestions,
    questions,
    shouldDoLiveUpdate,
    sortByUpvote,
    userQuestions,
    updateNotification,
  ]);

  const onScrollContainer = useMemo(
    () =>
      debounce(() => {
        if (
          containerRef.current &&
          containerRef.current.scrollTop <
            SCROLL_THRESHOLD_FOR_HIDING_NEW_QUESTIONS_BUTTON
        ) {
          if (questions) {
            setVisibleQuestions(questions);
          }
          setUpdateNotification(undefined);
        }
      }, 10),
    [questions],
  );

  const onSortChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      containerRef.current?.scrollTo({
        top: 0,
        left: 0,
      });
      const shouldSortByUpvote = e.target.value === 'upvotes';

      trackEvent(
        'QFA',
        'Changed QFA sorting',
        shouldSortByUpvote ? 'Top' : 'Recent',
      );
      dispatch(setSortByUpvote(shouldSortByUpvote));
      dispatch(fetchAudienceQuestions());
    },
    [dispatch],
  );

  const handleUpdateNotificationClick = React.useCallback(() => {
    if (questions) {
      setVisibleQuestions(questions);
    }
    setUpdateNotification(undefined);
  }, [questions]);

  return (
    <>
      <Label
        htmlFor="qfa-sort"
        fontSize="125"
        fontWeight="regular"
        lineHeight="snug"
        mb="space6"
        mx="space4"
        px="space4"
      >
        {translate('qfa.qna_title')}
      </Label>
      {visibleQuestions.length > 0 && qfaIntercomEnabled && (
        <Box pb="space6" mx="space4" px="space4">
          <SelectItem
            id="qfa-sort"
            name="qfa-sort"
            onChange={onSortChange}
            value={sortByUpvote ? 'upvotes' : 'time-added'}
          >
            <SelectItem.Option value="time-added">
              {translate('qfa.time_added')}
            </SelectItem.Option>

            <SelectItem.Option value="upvotes">
              {translate('qfa.upvotes')}
            </SelectItem.Option>
          </SelectItem>
        </Box>
      )}

      <Box position="relative" width="100%">
        <UpdateNotification show={updateNotification !== undefined}>
          {updateNotification === 'new_questions' && (
            <UpdateNotificationButton
              iconLeading={ArrowUpIcon}
              onClick={() => {
                handleUpdateNotificationClick();
                containerRef.current?.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
            >
              {translate('buttons.new_questions')}
            </UpdateNotificationButton>
          )}
          {updateNotification === 'updated_questions' && (
            <UpdateNotificationButton
              iconLeading={RefreshIcon}
              onClick={handleUpdateNotificationClick}
            >
              {translate('buttons.questions_updated')}
            </UpdateNotificationButton>
          )}
        </UpdateNotification>

        <Box
          width="100%"
          alignItems="stretch"
          maxHeight="45vh"
          ref={containerRef}
          overflow="auto"
          onScroll={onScrollContainer}
          px="space4"
        >
          <QfaQuestions
            questions={visibleQuestions}
            hasNextPage={Boolean(hasNextPage)}
            sortByUpvote={sortByUpvote}
            userQuestions={userQuestions}
            qfaIntercomEnabled={qfaIntercomEnabled}
            onLoadMore={loadMore}
            updateUpvoteForQuestion={updateUpvoteForQuestion}
          />
        </Box>
      </Box>
      {visibleQuestions.length === 0 && (
        <Box height="120px" my="space18">
          <Qa />
        </Box>
      )}
    </>
  );
};

const UpdateNotification = ({
  show,
  children,
}: PropsWithChildren<{
  show: boolean;
}>) => {
  const transitions = useTransition(show, {
    from: { opacity: 0, transform: 'scale(0.85)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.85)' },
    delay: 300,
  });

  return transitions((styles, shouldShow) => (
    <Box
      position="absolute"
      top={0}
      alignItems="center"
      width="100%"
      zIndex={2}
      as={animated.div}
      style={styles as SpringValues}
    >
      {shouldShow && children}
    </Box>
  ));
};

const UpdateNotificationButton = ({
  iconLeading,
  onClick,
  children,
}: PropsWithChildren<Pick<ButtonT, 'iconLeading' | 'onClick'>>) => {
  return (
    <Button
      iconLeading={iconLeading}
      size="compact"
      variant="tertiary"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
