import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(
  ['absolute', 'flex-row', 'z-[2]', 'top-0', 'right-0'],
  {
    variants: {
      transform: {
        0: 'translate-y-[-3px] translate-x-[3px]',
        1: 'translate-y-[-5px] translate-x-[5px]',
        2: 'translate-y-[-7px] translate-x-[7px]',
        3: 'translate-y-[-10px] translate-x-[10px]',
        compact: 'translate-y-[-16px] translate-x-[16px]',
        none: 'transform-none',
      },
    },
    defaultVariants: {
      transform: 3,
    },
  },
);

export type BadgeWrapVariantProps = VariantProps<typeof variants>;
