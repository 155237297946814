import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva([], {
  variants: {
    size: {
      compact: ['w-[20px]', 'h-[20px]'],
      default: ['w-[32px]', 'h-[32px]'],
      large: ['w-[40px]', 'h-[40px]'],
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export type AvatarVariantProps = VariantProps<typeof variants>;

export const textVariants = cva(['leading-normal'], {
  variants: {
    size: {
      compact: ['text-62.5'],
      default: ['text-62.5'],
      large: ['text-75'],
    },
  },
  defaultVariants: {
    size: 'default',
  },
});
