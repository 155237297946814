import type { Question } from '@mentimeter/http-clients';
import { useAppSelector } from '../../redux-hooks';
import { ProgressBar } from '../../ui';
import { usePresentationState } from '../../presentation-state';
import { Loading } from '../presenter-pace/Loading';
import { getDSCWithTheme } from '../../selectors';
import { PresentationIframe } from './PresentationIFrame';
import { DesktopExperienceLayout } from './DesktopExperienceLayout';
import { VotingPanel } from './VotingPanel';

export const PresenterPaceQuestion = ({
  question,
  seriesId,
}: {
  question: Question;
  seriesId: string;
}) => {
  const { isLoading, progress } = usePresentationState();

  const theme = useAppSelector(getDSCWithTheme);

  if (isLoading) return <Loading />;

  return (
    <DesktopExperienceLayout
      theme={theme}
      header={<ProgressBar progress={progress} />}
      canvas={<PresentationIframe seriesId={seriesId} />}
      voting={<VotingPanel question={question} />}
    />
  );
};
