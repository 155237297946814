interface Emoji {
  short_name: string;
  unified: string;
}

export const emojiData: Emoji[] = [
  { short_name: 'copyright', unified: '00A9' },
  { short_name: 'registered', unified: '00AE' },
  { short_name: 'bangbang', unified: '203C' },
  { short_name: 'interrobang', unified: '2049' },
  { short_name: 'tm', unified: '2122' },
  { short_name: 'information_source', unified: '2139' },
  { short_name: 'left_right_arrow', unified: '2194' },
  { short_name: 'arrow_up_down', unified: '2195' },
  { short_name: 'arrow_upper_left', unified: '2196' },
  { short_name: 'arrow_upper_right', unified: '2197' },
  { short_name: 'arrow_lower_right', unified: '2198' },
  { short_name: 'arrow_lower_left', unified: '2199' },
  { short_name: 'leftwards_arrow_with_hook', unified: '21A9' },
  { short_name: 'arrow_right_hook', unified: '21AA' },
  { short_name: 'watch', unified: '231A' },
  { short_name: 'hourglass', unified: '231B' },
  { short_name: 'keyboard', unified: '2328' },
  { short_name: 'eject', unified: '23CF' },
  { short_name: 'fast_forward', unified: '23E9' },
  { short_name: 'rewind', unified: '23EA' },
  { short_name: 'arrow_double_up', unified: '23EB' },
  { short_name: 'arrow_double_down', unified: '23EC' },
  {
    short_name: 'black_right_pointing_double_triangle_with_vertical_bar',
    unified: '23ED',
  },
  {
    short_name: 'black_left_pointing_double_triangle_with_vertical_bar',
    unified: '23EE',
  },
  {
    short_name: 'black_right_pointing_triangle_with_double_vertical_bar',
    unified: '23EF',
  },
  { short_name: 'alarm_clock', unified: '23F0' },
  { short_name: 'stopwatch', unified: '23F1' },
  { short_name: 'timer_clock', unified: '23F2' },
  { short_name: 'hourglass_flowing_sand', unified: '23F3' },
  { short_name: 'double_vertical_bar', unified: '23F8' },
  { short_name: 'black_square_for_stop', unified: '23F9' },
  { short_name: 'black_circle_for_record', unified: '23FA' },
  { short_name: 'm', unified: '24C2' },
  { short_name: 'black_small_square', unified: '25AA' },
  { short_name: 'white_small_square', unified: '25AB' },
  { short_name: 'arrow_forward', unified: '25B6' },
  { short_name: 'arrow_backward', unified: '25C0' },
  { short_name: 'white_medium_square', unified: '25FB' },
  { short_name: 'black_medium_square', unified: '25FC' },
  { short_name: 'white_medium_small_square', unified: '25FD' },
  { short_name: 'black_medium_small_square', unified: '25FE' },
  { short_name: 'sunny', unified: '2600' },
  { short_name: 'cloud', unified: '2601' },
  { short_name: 'umbrella', unified: '2602' },
  { short_name: 'snowman', unified: '2603' },
  { short_name: 'comet', unified: '2604' },
  { short_name: 'phone', unified: '260E' },
  { short_name: 'ballot_box_with_check', unified: '2611' },
  { short_name: 'umbrella_with_rain_drops', unified: '2614' },
  { short_name: 'coffee', unified: '2615' },
  { short_name: 'shamrock', unified: '2618' },
  { short_name: 'point_up', unified: '261D' },
  { short_name: 'skull_and_crossbones', unified: '2620' },
  { short_name: 'radioactive_sign', unified: '2622' },
  { short_name: 'biohazard_sign', unified: '2623' },
  { short_name: 'orthodox_cross', unified: '2626' },
  { short_name: 'star_and_crescent', unified: '262A' },
  { short_name: 'peace_symbol', unified: '262E' },
  { short_name: 'yin_yang', unified: '262F' },
  { short_name: 'wheel_of_dharma', unified: '2638' },
  { short_name: 'white_frowning_face', unified: '2639' },
  { short_name: 'relaxed', unified: '263A' },
  { short_name: 'female_sign', unified: '2640' },
  { short_name: 'male_sign', unified: '2642' },
  { short_name: 'aries', unified: '2648' },
  { short_name: 'taurus', unified: '2649' },
  { short_name: 'gemini', unified: '264A' },
  { short_name: 'cancer', unified: '264B' },
  { short_name: 'leo', unified: '264C' },
  { short_name: 'virgo', unified: '264D' },
  { short_name: 'libra', unified: '264E' },
  { short_name: 'scorpius', unified: '264F' },
  { short_name: 'sagittarius', unified: '2650' },
  { short_name: 'capricorn', unified: '2651' },
  { short_name: 'aquarius', unified: '2652' },
  { short_name: 'pisces', unified: '2653' },
  { short_name: 'spades', unified: '2660' },
  { short_name: 'clubs', unified: '2663' },
  { short_name: 'hearts', unified: '2665' },
  { short_name: 'diamonds', unified: '2666' },
  { short_name: 'hotsprings', unified: '2668' },
  { short_name: 'recycle', unified: '267B' },
  { short_name: 'wheelchair', unified: '267F' },
  { short_name: 'hammer_and_pick', unified: '2692' },
  { short_name: 'anchor', unified: '2693' },
  { short_name: 'crossed_swords', unified: '2694' },
  { short_name: 'staff_of_aesculapius', unified: '2695' },
  { short_name: 'scales', unified: '2696' },
  { short_name: 'alembic', unified: '2697' },
  { short_name: 'gear', unified: '2699' },
  { short_name: 'atom_symbol', unified: '269B' },
  { short_name: 'fleur_de_lis', unified: '269C' },
  { short_name: 'warning', unified: '26A0' },
  { short_name: 'zap', unified: '26A1' },
  { short_name: 'white_circle', unified: '26AA' },
  { short_name: 'black_circle', unified: '26AB' },
  { short_name: 'coffin', unified: '26B0' },
  { short_name: 'funeral_urn', unified: '26B1' },
  { short_name: 'soccer', unified: '26BD' },
  { short_name: 'baseball', unified: '26BE' },
  { short_name: 'snowman_without_snow', unified: '26C4' },
  { short_name: 'partly_sunny', unified: '26C5' },
  { short_name: 'thunder_cloud_and_rain', unified: '26C8' },
  { short_name: 'ophiuchus', unified: '26CE' },
  { short_name: 'pick', unified: '26CF' },
  { short_name: 'helmet_with_white_cross', unified: '26D1' },
  { short_name: 'chains', unified: '26D3' },
  { short_name: 'no_entry', unified: '26D4' },
  { short_name: 'shinto_shrine', unified: '26E9' },
  { short_name: 'church', unified: '26EA' },
  { short_name: 'mountain', unified: '26F0' },
  { short_name: 'umbrella_on_ground', unified: '26F1' },
  { short_name: 'fountain', unified: '26F2' },
  { short_name: 'golf', unified: '26F3' },
  { short_name: 'ferry', unified: '26F4' },
  { short_name: 'boat', unified: '26F5' },
  { short_name: 'skier', unified: '26F7' },
  { short_name: 'ice_skate', unified: '26F8' },
  { short_name: 'person_with_ball', unified: '26F9' },
  { short_name: 'tent', unified: '26FA' },
  { short_name: 'fuelpump', unified: '26FD' },
  { short_name: 'scissors', unified: '2702' },
  { short_name: 'white_check_mark', unified: '2705' },
  { short_name: 'airplane', unified: '2708' },
  { short_name: 'email', unified: '2709' },
  { short_name: 'fist', unified: '270A' },
  { short_name: 'hand', unified: '270B' },
  { short_name: 'v', unified: '270C' },
  { short_name: 'writing_hand', unified: '270D' },
  { short_name: 'pencil2', unified: '270F' },
  { short_name: 'black_nib', unified: '2712' },
  { short_name: 'heavy_check_mark', unified: '2714' },
  { short_name: 'heavy_multiplication_x', unified: '2716' },
  { short_name: 'latin_cross', unified: '271D' },
  { short_name: 'star_of_david', unified: '2721' },
  { short_name: 'sparkles', unified: '2728' },
  { short_name: 'eight_spoked_asterisk', unified: '2733' },
  { short_name: 'eight_pointed_black_star', unified: '2734' },
  { short_name: 'snowflake', unified: '2744' },
  { short_name: 'sparkle', unified: '2747' },
  { short_name: 'x', unified: '274C' },
  { short_name: 'negative_squared_cross_mark', unified: '274E' },
  { short_name: 'question', unified: '2753' },
  { short_name: 'grey_question', unified: '2754' },
  { short_name: 'grey_exclamation', unified: '2755' },
  { short_name: 'exclamation', unified: '2757' },
  { short_name: 'heavy_heart_exclamation_mark_ornament', unified: '2763' },
  { short_name: 'heart', unified: '2764' },
  { short_name: 'heavy_plus_sign', unified: '2795' },
  { short_name: 'heavy_minus_sign', unified: '2796' },
  { short_name: 'heavy_division_sign', unified: '2797' },
  { short_name: 'arrow_right', unified: '27A1' },
  { short_name: 'curly_loop', unified: '27B0' },
  { short_name: 'loop', unified: '27BF' },
  { short_name: 'arrow_heading_up', unified: '2934' },
  { short_name: 'arrow_heading_down', unified: '2935' },
  { short_name: 'arrow_left', unified: '2B05' },
  { short_name: 'arrow_up', unified: '2B06' },
  { short_name: 'arrow_down', unified: '2B07' },
  { short_name: 'black_large_square', unified: '2B1B' },
  { short_name: 'white_large_square', unified: '2B1C' },
  { short_name: 'star', unified: '2B50' },
  { short_name: 'o', unified: '2B55' },
  { short_name: 'wavy_dash', unified: '3030' },
  { short_name: 'part_alternation_mark', unified: '303D' },
  { short_name: 'congratulations', unified: '3297' },
  { short_name: 'secret', unified: '3299' },
  { short_name: 'mahjong', unified: '1F004' },
  { short_name: 'black_joker', unified: '1F0CF' },
  { short_name: 'a', unified: '1F170' },
  { short_name: 'b', unified: '1F171' },
  { short_name: 'o2', unified: '1F17E' },
  { short_name: 'parking', unified: '1F17F' },
  { short_name: 'ab', unified: '1F18E' },
  { short_name: 'cl', unified: '1F191' },
  { short_name: 'cool', unified: '1F192' },
  { short_name: 'free', unified: '1F193' },
  { short_name: 'id', unified: '1F194' },
  { short_name: 'new', unified: '1F195' },
  { short_name: 'ng', unified: '1F196' },
  { short_name: 'ok', unified: '1F197' },
  { short_name: 'sos', unified: '1F198' },
  { short_name: 'up', unified: '1F199' },
  { short_name: 'vs', unified: '1F19A' },
  { short_name: 'koko', unified: '1F201' },
  { short_name: 'sa', unified: '1F202' },
  { short_name: 'u7121', unified: '1F21A' },
  { short_name: 'u6307', unified: '1F22F' },
  { short_name: 'u7981', unified: '1F232' },
  { short_name: 'u7a7a', unified: '1F233' },
  { short_name: 'u5408', unified: '1F234' },
  { short_name: 'u6e80', unified: '1F235' },
  { short_name: 'u6709', unified: '1F236' },
  { short_name: 'u6708', unified: '1F237' },
  { short_name: 'u7533', unified: '1F238' },
  { short_name: 'u5272', unified: '1F239' },
  { short_name: 'u55b6', unified: '1F23A' },
  { short_name: 'ideograph_advantage', unified: '1F250' },
  { short_name: 'accept', unified: '1F251' },
  { short_name: 'cyclone', unified: '1F300' },
  { short_name: 'foggy', unified: '1F301' },
  { short_name: 'closed_umbrella', unified: '1F302' },
  { short_name: 'night_with_stars', unified: '1F303' },
  { short_name: 'sunrise_over_mountains', unified: '1F304' },
  { short_name: 'sunrise', unified: '1F305' },
  { short_name: 'city_sunset', unified: '1F306' },
  { short_name: 'city_sunrise', unified: '1F307' },
  { short_name: 'rainbow', unified: '1F308' },
  { short_name: 'bridge_at_night', unified: '1F309' },
  { short_name: 'ocean', unified: '1F30A' },
  { short_name: 'volcano', unified: '1F30B' },
  { short_name: 'milky_way', unified: '1F30C' },
  { short_name: 'earth_africa', unified: '1F30D' },
  { short_name: 'earth_americas', unified: '1F30E' },
  { short_name: 'earth_asia', unified: '1F30F' },
  { short_name: 'globe_with_meridians', unified: '1F310' },
  { short_name: 'new_moon', unified: '1F311' },
  { short_name: 'waxing_crescent_moon', unified: '1F312' },
  { short_name: 'first_quarter_moon', unified: '1F313' },
  { short_name: 'moon', unified: '1F314' },
  { short_name: 'full_moon', unified: '1F315' },
  { short_name: 'waning_gibbous_moon', unified: '1F316' },
  { short_name: 'last_quarter_moon', unified: '1F317' },
  { short_name: 'waning_crescent_moon', unified: '1F318' },
  { short_name: 'crescent_moon', unified: '1F319' },
  { short_name: 'new_moon_with_face', unified: '1F31A' },
  { short_name: 'first_quarter_moon_with_face', unified: '1F31B' },
  { short_name: 'last_quarter_moon_with_face', unified: '1F31C' },
  { short_name: 'full_moon_with_face', unified: '1F31D' },
  { short_name: 'sun_with_face', unified: '1F31E' },
  { short_name: 'star2', unified: '1F31F' },
  { short_name: 'stars', unified: '1F320' },
  { short_name: 'thermometer', unified: '1F321' },
  { short_name: 'mostly_sunny', unified: '1F324' },
  { short_name: 'barely_sunny', unified: '1F325' },
  { short_name: 'partly_sunny_rain', unified: '1F326' },
  { short_name: 'rain_cloud', unified: '1F327' },
  { short_name: 'snow_cloud', unified: '1F328' },
  { short_name: 'lightning', unified: '1F329' },
  { short_name: 'tornado', unified: '1F32A' },
  { short_name: 'fog', unified: '1F32B' },
  { short_name: 'wind_blowing_face', unified: '1F32C' },
  { short_name: 'hotdog', unified: '1F32D' },
  { short_name: 'taco', unified: '1F32E' },
  { short_name: 'burrito', unified: '1F32F' },
  { short_name: 'chestnut', unified: '1F330' },
  { short_name: 'seedling', unified: '1F331' },
  { short_name: 'evergreen_tree', unified: '1F332' },
  { short_name: 'deciduous_tree', unified: '1F333' },
  { short_name: 'palm_tree', unified: '1F334' },
  { short_name: 'cactus', unified: '1F335' },
  { short_name: 'hot_pepper', unified: '1F336' },
  { short_name: 'tulip', unified: '1F337' },
  { short_name: 'cherry_blossom', unified: '1F338' },
  { short_name: 'rose', unified: '1F339' },
  { short_name: 'hibiscus', unified: '1F33A' },
  { short_name: 'sunflower', unified: '1F33B' },
  { short_name: 'blossom', unified: '1F33C' },
  { short_name: 'corn', unified: '1F33D' },
  { short_name: 'ear_of_rice', unified: '1F33E' },
  { short_name: 'herb', unified: '1F33F' },
  { short_name: 'four_leaf_clover', unified: '1F340' },
  { short_name: 'maple_leaf', unified: '1F341' },
  { short_name: 'fallen_leaf', unified: '1F342' },
  { short_name: 'leaves', unified: '1F343' },
  { short_name: 'mushroom', unified: '1F344' },
  { short_name: 'tomato', unified: '1F345' },
  { short_name: 'eggplant', unified: '1F346' },
  { short_name: 'grapes', unified: '1F347' },
  { short_name: 'melon', unified: '1F348' },
  { short_name: 'watermelon', unified: '1F349' },
  { short_name: 'tangerine', unified: '1F34A' },
  { short_name: 'lemon', unified: '1F34B' },
  { short_name: 'banana', unified: '1F34C' },
  { short_name: 'pineapple', unified: '1F34D' },
  { short_name: 'apple', unified: '1F34E' },
  { short_name: 'green_apple', unified: '1F34F' },
  { short_name: 'pear', unified: '1F350' },
  { short_name: 'peach', unified: '1F351' },
  { short_name: 'cherries', unified: '1F352' },
  { short_name: 'strawberry', unified: '1F353' },
  { short_name: 'hamburger', unified: '1F354' },
  { short_name: 'pizza', unified: '1F355' },
  { short_name: 'meat_on_bone', unified: '1F356' },
  { short_name: 'poultry_leg', unified: '1F357' },
  { short_name: 'rice_cracker', unified: '1F358' },
  { short_name: 'rice_ball', unified: '1F359' },
  { short_name: 'rice', unified: '1F35A' },
  { short_name: 'curry', unified: '1F35B' },
  { short_name: 'ramen', unified: '1F35C' },
  { short_name: 'spaghetti', unified: '1F35D' },
  { short_name: 'bread', unified: '1F35E' },
  { short_name: 'fries', unified: '1F35F' },
  { short_name: 'sweet_potato', unified: '1F360' },
  { short_name: 'dango', unified: '1F361' },
  { short_name: 'oden', unified: '1F362' },
  { short_name: 'sushi', unified: '1F363' },
  { short_name: 'fried_shrimp', unified: '1F364' },
  { short_name: 'fish_cake', unified: '1F365' },
  { short_name: 'icecream', unified: '1F366' },
  { short_name: 'shaved_ice', unified: '1F367' },
  { short_name: 'ice_cream', unified: '1F368' },
  { short_name: 'doughnut', unified: '1F369' },
  { short_name: 'cookie', unified: '1F36A' },
  { short_name: 'chocolate_bar', unified: '1F36B' },
  { short_name: 'candy', unified: '1F36C' },
  { short_name: 'lollipop', unified: '1F36D' },
  { short_name: 'custard', unified: '1F36E' },
  { short_name: 'honey_pot', unified: '1F36F' },
  { short_name: 'cake', unified: '1F370' },
  { short_name: 'bento', unified: '1F371' },
  { short_name: 'stew', unified: '1F372' },
  { short_name: 'fried_egg', unified: '1F373' },
  { short_name: 'fork_and_knife', unified: '1F374' },
  { short_name: 'tea', unified: '1F375' },
  { short_name: 'sake', unified: '1F376' },
  { short_name: 'wine_glass', unified: '1F377' },
  { short_name: 'cocktail', unified: '1F378' },
  { short_name: 'tropical_drink', unified: '1F379' },
  { short_name: 'beer', unified: '1F37A' },
  { short_name: 'beers', unified: '1F37B' },
  { short_name: 'baby_bottle', unified: '1F37C' },
  { short_name: 'knife_fork_plate', unified: '1F37D' },
  { short_name: 'champagne', unified: '1F37E' },
  { short_name: 'popcorn', unified: '1F37F' },
  { short_name: 'ribbon', unified: '1F380' },
  { short_name: 'gift', unified: '1F381' },
  { short_name: 'birthday', unified: '1F382' },
  { short_name: 'jack_o_lantern', unified: '1F383' },
  { short_name: 'christmas_tree', unified: '1F384' },
  { short_name: 'santa', unified: '1F385' },
  { short_name: 'fireworks', unified: '1F386' },
  { short_name: 'sparkler', unified: '1F387' },
  { short_name: 'balloon', unified: '1F388' },
  { short_name: 'tada', unified: '1F389' },
  { short_name: 'confetti_ball', unified: '1F38A' },
  { short_name: 'tanabata_tree', unified: '1F38B' },
  { short_name: 'crossed_flags', unified: '1F38C' },
  { short_name: 'bamboo', unified: '1F38D' },
  { short_name: 'dolls', unified: '1F38E' },
  { short_name: 'flags', unified: '1F38F' },
  { short_name: 'wind_chime', unified: '1F390' },
  { short_name: 'rice_scene', unified: '1F391' },
  { short_name: 'school_satchel', unified: '1F392' },
  { short_name: 'mortar_board', unified: '1F393' },
  { short_name: 'medal', unified: '1F396' },
  { short_name: 'reminder_ribbon', unified: '1F397' },
  { short_name: 'studio_microphone', unified: '1F399' },
  { short_name: 'level_slider', unified: '1F39A' },
  { short_name: 'control_knobs', unified: '1F39B' },
  { short_name: 'film_frames', unified: '1F39E' },
  { short_name: 'admission_tickets', unified: '1F39F' },
  { short_name: 'carousel_horse', unified: '1F3A0' },
  { short_name: 'ferris_wheel', unified: '1F3A1' },
  { short_name: 'roller_coaster', unified: '1F3A2' },
  { short_name: 'fishing_pole_and_fish', unified: '1F3A3' },
  { short_name: 'microphone', unified: '1F3A4' },
  { short_name: 'movie_camera', unified: '1F3A5' },
  { short_name: 'cinema', unified: '1F3A6' },
  { short_name: 'headphones', unified: '1F3A7' },
  { short_name: 'art', unified: '1F3A8' },
  { short_name: 'tophat', unified: '1F3A9' },
  { short_name: 'circus_tent', unified: '1F3AA' },
  { short_name: 'ticket', unified: '1F3AB' },
  { short_name: 'clapper', unified: '1F3AC' },
  { short_name: 'performing_arts', unified: '1F3AD' },
  { short_name: 'video_game', unified: '1F3AE' },
  { short_name: 'dart', unified: '1F3AF' },
  { short_name: 'slot_machine', unified: '1F3B0' },
  { short_name: '8ball', unified: '1F3B1' },
  { short_name: 'game_die', unified: '1F3B2' },
  { short_name: 'bowling', unified: '1F3B3' },
  { short_name: 'flower_playing_cards', unified: '1F3B4' },
  { short_name: 'musical_note', unified: '1F3B5' },
  { short_name: 'notes', unified: '1F3B6' },
  { short_name: 'saxophone', unified: '1F3B7' },
  { short_name: 'guitar', unified: '1F3B8' },
  { short_name: 'musical_keyboard', unified: '1F3B9' },
  { short_name: 'trumpet', unified: '1F3BA' },
  { short_name: 'violin', unified: '1F3BB' },
  { short_name: 'musical_score', unified: '1F3BC' },
  { short_name: 'running_shirt_with_sash', unified: '1F3BD' },
  { short_name: 'tennis', unified: '1F3BE' },
  { short_name: 'ski', unified: '1F3BF' },
  { short_name: 'basketball', unified: '1F3C0' },
  { short_name: 'checkered_flag', unified: '1F3C1' },
  { short_name: 'snowboarder', unified: '1F3C2' },
  { short_name: 'runner', unified: '1F3C3' },
  { short_name: 'surfer', unified: '1F3C4' },
  { short_name: 'sports_medal', unified: '1F3C5' },
  { short_name: 'trophy', unified: '1F3C6' },
  { short_name: 'horse_racing', unified: '1F3C7' },
  { short_name: 'football', unified: '1F3C8' },
  { short_name: 'rugby_football', unified: '1F3C9' },
  { short_name: 'swimmer', unified: '1F3CA' },
  { short_name: 'weight_lifter', unified: '1F3CB' },
  { short_name: 'golfer', unified: '1F3CC' },
  { short_name: 'racing_motorcycle', unified: '1F3CD' },
  { short_name: 'racing_car', unified: '1F3CE' },
  { short_name: 'cricket_bat_and_ball', unified: '1F3CF' },
  { short_name: 'volleyball', unified: '1F3D0' },
  { short_name: 'field_hockey_stick_and_ball', unified: '1F3D1' },
  { short_name: 'ice_hockey_stick_and_puck', unified: '1F3D2' },
  { short_name: 'table_tennis_paddle_and_ball', unified: '1F3D3' },
  { short_name: 'snow_capped_mountain', unified: '1F3D4' },
  { short_name: 'camping', unified: '1F3D5' },
  { short_name: 'beach_with_umbrella', unified: '1F3D6' },
  { short_name: 'building_construction', unified: '1F3D7' },
  { short_name: 'house_buildings', unified: '1F3D8' },
  { short_name: 'cityscape', unified: '1F3D9' },
  { short_name: 'derelict_house_building', unified: '1F3DA' },
  { short_name: 'classical_building', unified: '1F3DB' },
  { short_name: 'desert', unified: '1F3DC' },
  { short_name: 'desert_island', unified: '1F3DD' },
  { short_name: 'national_park', unified: '1F3DE' },
  { short_name: 'stadium', unified: '1F3DF' },
  { short_name: 'house', unified: '1F3E0' },
  { short_name: 'house_with_garden', unified: '1F3E1' },
  { short_name: 'office', unified: '1F3E2' },
  { short_name: 'post_office', unified: '1F3E3' },
  { short_name: 'european_post_office', unified: '1F3E4' },
  { short_name: 'hospital', unified: '1F3E5' },
  { short_name: 'bank', unified: '1F3E6' },
  { short_name: 'atm', unified: '1F3E7' },
  { short_name: 'hotel', unified: '1F3E8' },
  { short_name: 'love_hotel', unified: '1F3E9' },
  { short_name: 'convenience_store', unified: '1F3EA' },
  { short_name: 'school', unified: '1F3EB' },
  { short_name: 'department_store', unified: '1F3EC' },
  { short_name: 'factory', unified: '1F3ED' },
  { short_name: 'izakaya_lantern', unified: '1F3EE' },
  { short_name: 'japanese_castle', unified: '1F3EF' },
  { short_name: 'european_castle', unified: '1F3F0' },
  { short_name: 'waving_white_flag', unified: '1F3F3' },
  { short_name: 'waving_black_flag', unified: '1F3F4' },
  { short_name: 'rosette', unified: '1F3F5' },
  { short_name: 'label', unified: '1F3F7' },
  { short_name: 'badminton_racquet_and_shuttlecock', unified: '1F3F8' },
  { short_name: 'bow_and_arrow', unified: '1F3F9' },
  { short_name: 'amphora', unified: '1F3FA' },
  { short_name: 'skin-tone-2', unified: '1F3FB' },
  { short_name: 'skin-tone-3', unified: '1F3FC' },
  { short_name: 'skin-tone-4', unified: '1F3FD' },
  { short_name: 'skin-tone-5', unified: '1F3FE' },
  { short_name: 'skin-tone-6', unified: '1F3FF' },
  { short_name: 'rat', unified: '1F400' },
  { short_name: 'mouse2', unified: '1F401' },
  { short_name: 'ox', unified: '1F402' },
  { short_name: 'water_buffalo', unified: '1F403' },
  { short_name: 'cow2', unified: '1F404' },
  { short_name: 'tiger2', unified: '1F405' },
  { short_name: 'leopard', unified: '1F406' },
  { short_name: 'rabbit2', unified: '1F407' },
  { short_name: 'cat2', unified: '1F408' },
  { short_name: 'dragon', unified: '1F409' },
  { short_name: 'crocodile', unified: '1F40A' },
  { short_name: 'whale2', unified: '1F40B' },
  { short_name: 'snail', unified: '1F40C' },
  { short_name: 'snake', unified: '1F40D' },
  { short_name: 'racehorse', unified: '1F40E' },
  { short_name: 'ram', unified: '1F40F' },
  { short_name: 'goat', unified: '1F410' },
  { short_name: 'sheep', unified: '1F411' },
  { short_name: 'monkey', unified: '1F412' },
  { short_name: 'rooster', unified: '1F413' },
  { short_name: 'chicken', unified: '1F414' },
  { short_name: 'dog2', unified: '1F415' },
  { short_name: 'pig2', unified: '1F416' },
  { short_name: 'boar', unified: '1F417' },
  { short_name: 'elephant', unified: '1F418' },
  { short_name: 'octopus', unified: '1F419' },
  { short_name: 'shell', unified: '1F41A' },
  { short_name: 'bug', unified: '1F41B' },
  { short_name: 'ant', unified: '1F41C' },
  { short_name: 'bee', unified: '1F41D' },
  { short_name: 'beetle', unified: '1F41E' },
  { short_name: 'fish', unified: '1F41F' },
  { short_name: 'tropical_fish', unified: '1F420' },
  { short_name: 'blowfish', unified: '1F421' },
  { short_name: 'turtle', unified: '1F422' },
  { short_name: 'hatching_chick', unified: '1F423' },
  { short_name: 'baby_chick', unified: '1F424' },
  { short_name: 'hatched_chick', unified: '1F425' },
  { short_name: 'bird', unified: '1F426' },
  { short_name: 'penguin', unified: '1F427' },
  { short_name: 'koala', unified: '1F428' },
  { short_name: 'poodle', unified: '1F429' },
  { short_name: 'dromedary_camel', unified: '1F42A' },
  { short_name: 'camel', unified: '1F42B' },
  { short_name: 'dolphin', unified: '1F42C' },
  { short_name: 'mouse', unified: '1F42D' },
  { short_name: 'cow', unified: '1F42E' },
  { short_name: 'tiger', unified: '1F42F' },
  { short_name: 'rabbit', unified: '1F430' },
  { short_name: 'cat', unified: '1F431' },
  { short_name: 'dragon_face', unified: '1F432' },
  { short_name: 'whale', unified: '1F433' },
  { short_name: 'horse', unified: '1F434' },
  { short_name: 'monkey_face', unified: '1F435' },
  { short_name: 'dog', unified: '1F436' },
  { short_name: 'pig', unified: '1F437' },
  { short_name: 'frog', unified: '1F438' },
  { short_name: 'hamster', unified: '1F439' },
  { short_name: 'wolf', unified: '1F43A' },
  { short_name: 'bear', unified: '1F43B' },
  { short_name: 'panda_face', unified: '1F43C' },
  { short_name: 'pig_nose', unified: '1F43D' },
  { short_name: 'feet', unified: '1F43E' },
  { short_name: 'chipmunk', unified: '1F43F' },
  { short_name: 'eyes', unified: '1F440' },
  { short_name: 'eye', unified: '1F441' },
  { short_name: 'ear', unified: '1F442' },
  { short_name: 'nose', unified: '1F443' },
  { short_name: 'lips', unified: '1F444' },
  { short_name: 'tongue', unified: '1F445' },
  { short_name: 'point_up_2', unified: '1F446' },
  { short_name: 'point_down', unified: '1F447' },
  { short_name: 'point_left', unified: '1F448' },
  { short_name: 'point_right', unified: '1F449' },
  { short_name: 'facepunch', unified: '1F44A' },
  { short_name: 'wave', unified: '1F44B' },
  { short_name: 'ok_hand', unified: '1F44C' },
  { short_name: '+1', unified: '1F44D' },
  { short_name: '-1', unified: '1F44E' },
  { short_name: 'clap', unified: '1F44F' },
  { short_name: 'open_hands', unified: '1F450' },
  { short_name: 'crown', unified: '1F451' },
  { short_name: 'womans_hat', unified: '1F452' },
  { short_name: 'eyeglasses', unified: '1F453' },
  { short_name: 'necktie', unified: '1F454' },
  { short_name: 'shirt', unified: '1F455' },
  { short_name: 'jeans', unified: '1F456' },
  { short_name: 'dress', unified: '1F457' },
  { short_name: 'kimono', unified: '1F458' },
  { short_name: 'bikini', unified: '1F459' },
  { short_name: 'womans_clothes', unified: '1F45A' },
  { short_name: 'purse', unified: '1F45B' },
  { short_name: 'handbag', unified: '1F45C' },
  { short_name: 'pouch', unified: '1F45D' },
  { short_name: 'mans_shoe', unified: '1F45E' },
  { short_name: 'athletic_shoe', unified: '1F45F' },
  { short_name: 'high_heel', unified: '1F460' },
  { short_name: 'sandal', unified: '1F461' },
  { short_name: 'boot', unified: '1F462' },
  { short_name: 'footprints', unified: '1F463' },
  { short_name: 'bust_in_silhouette', unified: '1F464' },
  { short_name: 'busts_in_silhouette', unified: '1F465' },
  { short_name: 'boy', unified: '1F466' },
  { short_name: 'girl', unified: '1F467' },
  { short_name: 'man', unified: '1F468' },
  { short_name: 'woman', unified: '1F469' },
  { short_name: 'family', unified: '1F46A' },
  { short_name: 'couple', unified: '1F46B' },
  { short_name: 'two_men_holding_hands', unified: '1F46C' },
  { short_name: 'two_women_holding_hands', unified: '1F46D' },
  { short_name: 'cop', unified: '1F46E' },
  { short_name: 'dancers', unified: '1F46F' },
  { short_name: 'bride_with_veil', unified: '1F470' },
  { short_name: 'person_with_blond_hair', unified: '1F471' },
  { short_name: 'man_with_gua_pi_mao', unified: '1F472' },
  { short_name: 'man_with_turban', unified: '1F473' },
  { short_name: 'older_man', unified: '1F474' },
  { short_name: 'older_woman', unified: '1F475' },
  { short_name: 'baby', unified: '1F476' },
  { short_name: 'construction_worker', unified: '1F477' },
  { short_name: 'princess', unified: '1F478' },
  { short_name: 'japanese_ogre', unified: '1F479' },
  { short_name: 'japanese_goblin', unified: '1F47A' },
  { short_name: 'ghost', unified: '1F47B' },
  { short_name: 'angel', unified: '1F47C' },
  { short_name: 'alien', unified: '1F47D' },
  { short_name: 'space_invader', unified: '1F47E' },
  { short_name: 'imp', unified: '1F47F' },
  { short_name: 'skull', unified: '1F480' },
  { short_name: 'information_desk_person', unified: '1F481' },
  { short_name: 'guardsman', unified: '1F482' },
  { short_name: 'dancer', unified: '1F483' },
  { short_name: 'lipstick', unified: '1F484' },
  { short_name: 'nail_care', unified: '1F485' },
  { short_name: 'massage', unified: '1F486' },
  { short_name: 'haircut', unified: '1F487' },
  { short_name: 'barber', unified: '1F488' },
  { short_name: 'syringe', unified: '1F489' },
  { short_name: 'pill', unified: '1F48A' },
  { short_name: 'kiss', unified: '1F48B' },
  { short_name: 'love_letter', unified: '1F48C' },
  { short_name: 'ring', unified: '1F48D' },
  { short_name: 'gem', unified: '1F48E' },
  { short_name: 'couplekiss', unified: '1F48F' },
  { short_name: 'bouquet', unified: '1F490' },
  { short_name: 'couple_with_heart', unified: '1F491' },
  { short_name: 'wedding', unified: '1F492' },
  { short_name: 'heartbeat', unified: '1F493' },
  { short_name: 'broken_heart', unified: '1F494' },
  { short_name: 'two_hearts', unified: '1F495' },
  { short_name: 'sparkling_heart', unified: '1F496' },
  { short_name: 'heartpulse', unified: '1F497' },
  { short_name: 'cupid', unified: '1F498' },
  { short_name: 'blue_heart', unified: '1F499' },
  { short_name: 'green_heart', unified: '1F49A' },
  { short_name: 'yellow_heart', unified: '1F49B' },
  { short_name: 'purple_heart', unified: '1F49C' },
  { short_name: 'gift_heart', unified: '1F49D' },
  { short_name: 'revolving_hearts', unified: '1F49E' },
  { short_name: 'heart_decoration', unified: '1F49F' },
  { short_name: 'diamond_shape_with_a_dot_inside', unified: '1F4A0' },
  { short_name: 'bulb', unified: '1F4A1' },
  { short_name: 'anger', unified: '1F4A2' },
  { short_name: 'bomb', unified: '1F4A3' },
  { short_name: 'zzz', unified: '1F4A4' },
  { short_name: 'boom', unified: '1F4A5' },
  { short_name: 'sweat_drops', unified: '1F4A6' },
  { short_name: 'droplet', unified: '1F4A7' },
  { short_name: 'dash', unified: '1F4A8' },
  { short_name: 'hankey', unified: '1F4A9' },
  { short_name: 'muscle', unified: '1F4AA' },
  { short_name: 'dizzy', unified: '1F4AB' },
  { short_name: 'speech_balloon', unified: '1F4AC' },
  { short_name: 'thought_balloon', unified: '1F4AD' },
  { short_name: 'white_flower', unified: '1F4AE' },
  { short_name: '100', unified: '1F4AF' },
  { short_name: 'moneybag', unified: '1F4B0' },
  { short_name: 'currency_exchange', unified: '1F4B1' },
  { short_name: 'heavy_dollar_sign', unified: '1F4B2' },
  { short_name: 'credit_card', unified: '1F4B3' },
  { short_name: 'yen', unified: '1F4B4' },
  { short_name: 'dollar', unified: '1F4B5' },
  { short_name: 'euro', unified: '1F4B6' },
  { short_name: 'pound', unified: '1F4B7' },
  { short_name: 'money_with_wings', unified: '1F4B8' },
  { short_name: 'chart', unified: '1F4B9' },
  { short_name: 'seat', unified: '1F4BA' },
  { short_name: 'computer', unified: '1F4BB' },
  { short_name: 'briefcase', unified: '1F4BC' },
  { short_name: 'minidisc', unified: '1F4BD' },
  { short_name: 'floppy_disk', unified: '1F4BE' },
  { short_name: 'cd', unified: '1F4BF' },
  { short_name: 'dvd', unified: '1F4C0' },
  { short_name: 'file_folder', unified: '1F4C1' },
  { short_name: 'open_file_folder', unified: '1F4C2' },
  { short_name: 'page_with_curl', unified: '1F4C3' },
  { short_name: 'page_facing_up', unified: '1F4C4' },
  { short_name: 'date', unified: '1F4C5' },
  { short_name: 'calendar', unified: '1F4C6' },
  { short_name: 'card_index', unified: '1F4C7' },
  { short_name: 'chart_with_upwards_trend', unified: '1F4C8' },
  { short_name: 'chart_with_downwards_trend', unified: '1F4C9' },
  { short_name: 'bar_chart', unified: '1F4CA' },
  { short_name: 'clipboard', unified: '1F4CB' },
  { short_name: 'pushpin', unified: '1F4CC' },
  { short_name: 'round_pushpin', unified: '1F4CD' },
  { short_name: 'paperclip', unified: '1F4CE' },
  { short_name: 'straight_ruler', unified: '1F4CF' },
  { short_name: 'triangular_ruler', unified: '1F4D0' },
  { short_name: 'bookmark_tabs', unified: '1F4D1' },
  { short_name: 'ledger', unified: '1F4D2' },
  { short_name: 'notebook', unified: '1F4D3' },
  { short_name: 'notebook_with_decorative_cover', unified: '1F4D4' },
  { short_name: 'closed_book', unified: '1F4D5' },
  { short_name: 'book', unified: '1F4D6' },
  { short_name: 'green_book', unified: '1F4D7' },
  { short_name: 'blue_book', unified: '1F4D8' },
  { short_name: 'orange_book', unified: '1F4D9' },
  { short_name: 'books', unified: '1F4DA' },
  { short_name: 'name_badge', unified: '1F4DB' },
  { short_name: 'scroll', unified: '1F4DC' },
  { short_name: 'memo', unified: '1F4DD' },
  { short_name: 'telephone_receiver', unified: '1F4DE' },
  { short_name: 'pager', unified: '1F4DF' },
  { short_name: 'fax', unified: '1F4E0' },
  { short_name: 'satellite_antenna', unified: '1F4E1' },
  { short_name: 'loudspeaker', unified: '1F4E2' },
  { short_name: 'mega', unified: '1F4E3' },
  { short_name: 'outbox_tray', unified: '1F4E4' },
  { short_name: 'inbox_tray', unified: '1F4E5' },
  { short_name: 'package', unified: '1F4E6' },
  { short_name: 'e-mail', unified: '1F4E7' },
  { short_name: 'incoming_envelope', unified: '1F4E8' },
  { short_name: 'envelope_with_arrow', unified: '1F4E9' },
  { short_name: 'mailbox_closed', unified: '1F4EA' },
  { short_name: 'mailbox', unified: '1F4EB' },
  { short_name: 'mailbox_with_mail', unified: '1F4EC' },
  { short_name: 'mailbox_with_no_mail', unified: '1F4ED' },
  { short_name: 'postbox', unified: '1F4EE' },
  { short_name: 'postal_horn', unified: '1F4EF' },
  { short_name: 'newspaper', unified: '1F4F0' },
  { short_name: 'iphone', unified: '1F4F1' },
  { short_name: 'calling', unified: '1F4F2' },
  { short_name: 'vibration_mode', unified: '1F4F3' },
  { short_name: 'mobile_phone_off', unified: '1F4F4' },
  { short_name: 'no_mobile_phones', unified: '1F4F5' },
  { short_name: 'signal_strength', unified: '1F4F6' },
  { short_name: 'camera', unified: '1F4F7' },
  { short_name: 'camera_with_flash', unified: '1F4F8' },
  { short_name: 'video_camera', unified: '1F4F9' },
  { short_name: 'tv', unified: '1F4FA' },
  { short_name: 'radio', unified: '1F4FB' },
  { short_name: 'vhs', unified: '1F4FC' },
  { short_name: 'film_projector', unified: '1F4FD' },
  { short_name: 'prayer_beads', unified: '1F4FF' },
  { short_name: 'twisted_rightwards_arrows', unified: '1F500' },
  { short_name: 'repeat', unified: '1F501' },
  { short_name: 'repeat_one', unified: '1F502' },
  { short_name: 'arrows_clockwise', unified: '1F503' },
  { short_name: 'arrows_counterclockwise', unified: '1F504' },
  { short_name: 'low_brightness', unified: '1F505' },
  { short_name: 'high_brightness', unified: '1F506' },
  { short_name: 'mute', unified: '1F507' },
  { short_name: 'speaker', unified: '1F508' },
  { short_name: 'sound', unified: '1F509' },
  { short_name: 'loud_sound', unified: '1F50A' },
  { short_name: 'battery', unified: '1F50B' },
  { short_name: 'electric_plug', unified: '1F50C' },
  { short_name: 'mag', unified: '1F50D' },
  { short_name: 'mag_right', unified: '1F50E' },
  { short_name: 'lock_with_ink_pen', unified: '1F50F' },
  { short_name: 'closed_lock_with_key', unified: '1F510' },
  { short_name: 'key', unified: '1F511' },
  { short_name: 'lock', unified: '1F512' },
  { short_name: 'unlock', unified: '1F513' },
  { short_name: 'bell', unified: '1F514' },
  { short_name: 'no_bell', unified: '1F515' },
  { short_name: 'bookmark', unified: '1F516' },
  { short_name: 'link', unified: '1F517' },
  { short_name: 'radio_button', unified: '1F518' },
  { short_name: 'back', unified: '1F519' },
  { short_name: 'end', unified: '1F51A' },
  { short_name: 'on', unified: '1F51B' },
  { short_name: 'soon', unified: '1F51C' },
  { short_name: 'top', unified: '1F51D' },
  { short_name: 'underage', unified: '1F51E' },
  { short_name: 'keycap_ten', unified: '1F51F' },
  { short_name: 'capital_abcd', unified: '1F520' },
  { short_name: 'abcd', unified: '1F521' },
  { short_name: '1234', unified: '1F522' },
  { short_name: 'symbols', unified: '1F523' },
  { short_name: 'abc', unified: '1F524' },
  { short_name: 'fire', unified: '1F525' },
  { short_name: 'flashlight', unified: '1F526' },
  { short_name: 'wrench', unified: '1F527' },
  { short_name: 'hammer', unified: '1F528' },
  { short_name: 'nut_and_bolt', unified: '1F529' },
  { short_name: 'hocho', unified: '1F52A' },
  { short_name: 'gun', unified: '1F52B' },
  { short_name: 'microscope', unified: '1F52C' },
  { short_name: 'telescope', unified: '1F52D' },
  { short_name: 'crystal_ball', unified: '1F52E' },
  { short_name: 'six_pointed_star', unified: '1F52F' },
  { short_name: 'beginner', unified: '1F530' },
  { short_name: 'trident', unified: '1F531' },
  { short_name: 'black_square_button', unified: '1F532' },
  { short_name: 'white_square_button', unified: '1F533' },
  { short_name: 'red_circle', unified: '1F534' },
  { short_name: 'large_blue_circle', unified: '1F535' },
  { short_name: 'large_orange_diamond', unified: '1F536' },
  { short_name: 'large_blue_diamond', unified: '1F537' },
  { short_name: 'small_orange_diamond', unified: '1F538' },
  { short_name: 'small_blue_diamond', unified: '1F539' },
  { short_name: 'small_red_triangle', unified: '1F53A' },
  { short_name: 'small_red_triangle_down', unified: '1F53B' },
  { short_name: 'arrow_up_small', unified: '1F53C' },
  { short_name: 'arrow_down_small', unified: '1F53D' },
  { short_name: 'om_symbol', unified: '1F549' },
  { short_name: 'dove_of_peace', unified: '1F54A' },
  { short_name: 'kaaba', unified: '1F54B' },
  { short_name: 'mosque', unified: '1F54C' },
  { short_name: 'synagogue', unified: '1F54D' },
  { short_name: 'menorah_with_nine_branches', unified: '1F54E' },
  { short_name: 'clock1', unified: '1F550' },
  { short_name: 'clock2', unified: '1F551' },
  { short_name: 'clock3', unified: '1F552' },
  { short_name: 'clock4', unified: '1F553' },
  { short_name: 'clock5', unified: '1F554' },
  { short_name: 'clock6', unified: '1F555' },
  { short_name: 'clock7', unified: '1F556' },
  { short_name: 'clock8', unified: '1F557' },
  { short_name: 'clock9', unified: '1F558' },
  { short_name: 'clock10', unified: '1F559' },
  { short_name: 'clock11', unified: '1F55A' },
  { short_name: 'clock12', unified: '1F55B' },
  { short_name: 'clock130', unified: '1F55C' },
  { short_name: 'clock230', unified: '1F55D' },
  { short_name: 'clock330', unified: '1F55E' },
  { short_name: 'clock430', unified: '1F55F' },
  { short_name: 'clock530', unified: '1F560' },
  { short_name: 'clock630', unified: '1F561' },
  { short_name: 'clock730', unified: '1F562' },
  { short_name: 'clock830', unified: '1F563' },
  { short_name: 'clock930', unified: '1F564' },
  { short_name: 'clock1030', unified: '1F565' },
  { short_name: 'clock1130', unified: '1F566' },
  { short_name: 'clock1230', unified: '1F567' },
  { short_name: 'candle', unified: '1F56F' },
  { short_name: 'mantelpiece_clock', unified: '1F570' },
  { short_name: 'hole', unified: '1F573' },
  { short_name: 'man_in_business_suit_levitating', unified: '1F574' },
  { short_name: 'sleuth_or_spy', unified: '1F575' },
  { short_name: 'dark_sunglasses', unified: '1F576' },
  { short_name: 'spider', unified: '1F577' },
  { short_name: 'spider_web', unified: '1F578' },
  { short_name: 'joystick', unified: '1F579' },
  { short_name: 'man_dancing', unified: '1F57A' },
  { short_name: 'linked_paperclips', unified: '1F587' },
  { short_name: 'lower_left_ballpoint_pen', unified: '1F58A' },
  { short_name: 'lower_left_fountain_pen', unified: '1F58B' },
  { short_name: 'lower_left_paintbrush', unified: '1F58C' },
  { short_name: 'lower_left_crayon', unified: '1F58D' },
  { short_name: 'raised_hand_with_fingers_splayed', unified: '1F590' },
  { short_name: 'middle_finger', unified: '1F595' },
  { short_name: 'spock-hand', unified: '1F596' },
  { short_name: 'black_heart', unified: '1F5A4' },
  { short_name: 'desktop_computer', unified: '1F5A5' },
  { short_name: 'printer', unified: '1F5A8' },
  { short_name: 'three_button_mouse', unified: '1F5B1' },
  { short_name: 'trackball', unified: '1F5B2' },
  { short_name: 'frame_with_picture', unified: '1F5BC' },
  { short_name: 'card_index_dividers', unified: '1F5C2' },
  { short_name: 'card_file_box', unified: '1F5C3' },
  { short_name: 'file_cabinet', unified: '1F5C4' },
  { short_name: 'wastebasket', unified: '1F5D1' },
  { short_name: 'spiral_note_pad', unified: '1F5D2' },
  { short_name: 'spiral_calendar_pad', unified: '1F5D3' },
  { short_name: 'compression', unified: '1F5DC' },
  { short_name: 'old_key', unified: '1F5DD' },
  { short_name: 'rolled_up_newspaper', unified: '1F5DE' },
  { short_name: 'dagger_knife', unified: '1F5E1' },
  { short_name: 'speaking_head_in_silhouette', unified: '1F5E3' },
  { short_name: 'left_speech_bubble', unified: '1F5E8' },
  { short_name: 'right_anger_bubble', unified: '1F5EF' },
  { short_name: 'ballot_box_with_ballot', unified: '1F5F3' },
  { short_name: 'world_map', unified: '1F5FA' },
  { short_name: 'mount_fuji', unified: '1F5FB' },
  { short_name: 'tokyo_tower', unified: '1F5FC' },
  { short_name: 'statue_of_liberty', unified: '1F5FD' },
  { short_name: 'japan', unified: '1F5FE' },
  { short_name: 'moyai', unified: '1F5FF' },
  { short_name: 'grinning', unified: '1F600' },
  { short_name: 'grin', unified: '1F601' },
  { short_name: 'joy', unified: '1F602' },
  { short_name: 'smiley', unified: '1F603' },
  { short_name: 'smile', unified: '1F604' },
  { short_name: 'sweat_smile', unified: '1F605' },
  { short_name: 'laughing', unified: '1F606' },
  { short_name: 'innocent', unified: '1F607' },
  { short_name: 'smiling_imp', unified: '1F608' },
  { short_name: 'wink', unified: '1F609' },
  { short_name: 'blush', unified: '1F60A' },
  { short_name: 'yum', unified: '1F60B' },
  { short_name: 'relieved', unified: '1F60C' },
  { short_name: 'heart_eyes', unified: '1F60D' },
  { short_name: 'sunglasses', unified: '1F60E' },
  { short_name: 'smirk', unified: '1F60F' },
  { short_name: 'neutral_face', unified: '1F610' },
  { short_name: 'expressionless', unified: '1F611' },
  { short_name: 'unamused', unified: '1F612' },
  { short_name: 'sweat', unified: '1F613' },
  { short_name: 'pensive', unified: '1F614' },
  { short_name: 'confused', unified: '1F615' },
  { short_name: 'confounded', unified: '1F616' },
  { short_name: 'kissing', unified: '1F617' },
  { short_name: 'kissing_heart', unified: '1F618' },
  { short_name: 'kissing_smiling_eyes', unified: '1F619' },
  { short_name: 'kissing_closed_eyes', unified: '1F61A' },
  { short_name: 'stuck_out_tongue', unified: '1F61B' },
  { short_name: 'stuck_out_tongue_winking_eye', unified: '1F61C' },
  { short_name: 'stuck_out_tongue_closed_eyes', unified: '1F61D' },
  { short_name: 'disappointed', unified: '1F61E' },
  { short_name: 'worried', unified: '1F61F' },
  { short_name: 'angry', unified: '1F620' },
  { short_name: 'rage', unified: '1F621' },
  { short_name: 'cry', unified: '1F622' },
  { short_name: 'persevere', unified: '1F623' },
  { short_name: 'triumph', unified: '1F624' },
  { short_name: 'disappointed_relieved', unified: '1F625' },
  { short_name: 'frowning', unified: '1F626' },
  { short_name: 'anguished', unified: '1F627' },
  { short_name: 'fearful', unified: '1F628' },
  { short_name: 'weary', unified: '1F629' },
  { short_name: 'sleepy', unified: '1F62A' },
  { short_name: 'tired_face', unified: '1F62B' },
  { short_name: 'grimacing', unified: '1F62C' },
  { short_name: 'sob', unified: '1F62D' },
  { short_name: 'open_mouth', unified: '1F62E' },
  { short_name: 'hushed', unified: '1F62F' },
  { short_name: 'cold_sweat', unified: '1F630' },
  { short_name: 'scream', unified: '1F631' },
  { short_name: 'astonished', unified: '1F632' },
  { short_name: 'flushed', unified: '1F633' },
  { short_name: 'sleeping', unified: '1F634' },
  { short_name: 'dizzy_face', unified: '1F635' },
  { short_name: 'no_mouth', unified: '1F636' },
  { short_name: 'mask', unified: '1F637' },
  { short_name: 'smile_cat', unified: '1F638' },
  { short_name: 'joy_cat', unified: '1F639' },
  { short_name: 'smiley_cat', unified: '1F63A' },
  { short_name: 'heart_eyes_cat', unified: '1F63B' },
  { short_name: 'smirk_cat', unified: '1F63C' },
  { short_name: 'kissing_cat', unified: '1F63D' },
  { short_name: 'pouting_cat', unified: '1F63E' },
  { short_name: 'crying_cat_face', unified: '1F63F' },
  { short_name: 'scream_cat', unified: '1F640' },
  { short_name: 'slightly_frowning_face', unified: '1F641' },
  { short_name: 'slightly_smiling_face', unified: '1F642' },
  { short_name: 'upside_down_face', unified: '1F643' },
  { short_name: 'face_with_rolling_eyes', unified: '1F644' },
  { short_name: 'no_good', unified: '1F645' },
  { short_name: 'ok_woman', unified: '1F646' },
  { short_name: 'bow', unified: '1F647' },
  { short_name: 'see_no_evil', unified: '1F648' },
  { short_name: 'hear_no_evil', unified: '1F649' },
  { short_name: 'speak_no_evil', unified: '1F64A' },
  { short_name: 'raising_hand', unified: '1F64B' },
  { short_name: 'raised_hands', unified: '1F64C' },
  { short_name: 'person_frowning', unified: '1F64D' },
  { short_name: 'person_with_pouting_face', unified: '1F64E' },
  { short_name: 'pray', unified: '1F64F' },
  { short_name: 'rocket', unified: '1F680' },
  { short_name: 'helicopter', unified: '1F681' },
  { short_name: 'steam_locomotive', unified: '1F682' },
  { short_name: 'railway_car', unified: '1F683' },
  { short_name: 'bullettrain_side', unified: '1F684' },
  { short_name: 'bullettrain_front', unified: '1F685' },
  { short_name: 'train2', unified: '1F686' },
  { short_name: 'metro', unified: '1F687' },
  { short_name: 'light_rail', unified: '1F688' },
  { short_name: 'station', unified: '1F689' },
  { short_name: 'tram', unified: '1F68A' },
  { short_name: 'train', unified: '1F68B' },
  { short_name: 'bus', unified: '1F68C' },
  { short_name: 'oncoming_bus', unified: '1F68D' },
  { short_name: 'trolleybus', unified: '1F68E' },
  { short_name: 'busstop', unified: '1F68F' },
  { short_name: 'minibus', unified: '1F690' },
  { short_name: 'ambulance', unified: '1F691' },
  { short_name: 'fire_engine', unified: '1F692' },
  { short_name: 'police_car', unified: '1F693' },
  { short_name: 'oncoming_police_car', unified: '1F694' },
  { short_name: 'taxi', unified: '1F695' },
  { short_name: 'oncoming_taxi', unified: '1F696' },
  { short_name: 'car', unified: '1F697' },
  { short_name: 'oncoming_automobile', unified: '1F698' },
  { short_name: 'blue_car', unified: '1F699' },
  { short_name: 'truck', unified: '1F69A' },
  { short_name: 'articulated_lorry', unified: '1F69B' },
  { short_name: 'tractor', unified: '1F69C' },
  { short_name: 'monorail', unified: '1F69D' },
  { short_name: 'mountain_railway', unified: '1F69E' },
  { short_name: 'suspension_railway', unified: '1F69F' },
  { short_name: 'mountain_cableway', unified: '1F6A0' },
  { short_name: 'aerial_tramway', unified: '1F6A1' },
  { short_name: 'ship', unified: '1F6A2' },
  { short_name: 'rowboat', unified: '1F6A3' },
  { short_name: 'speedboat', unified: '1F6A4' },
  { short_name: 'traffic_light', unified: '1F6A5' },
  { short_name: 'vertical_traffic_light', unified: '1F6A6' },
  { short_name: 'construction', unified: '1F6A7' },
  { short_name: 'rotating_light', unified: '1F6A8' },
  { short_name: 'triangular_flag_on_post', unified: '1F6A9' },
  { short_name: 'door', unified: '1F6AA' },
  { short_name: 'no_entry_sign', unified: '1F6AB' },
  { short_name: 'smoking', unified: '1F6AC' },
  { short_name: 'no_smoking', unified: '1F6AD' },
  { short_name: 'put_litter_in_its_place', unified: '1F6AE' },
  { short_name: 'do_not_litter', unified: '1F6AF' },
  { short_name: 'potable_water', unified: '1F6B0' },
  { short_name: 'non-potable_water', unified: '1F6B1' },
  { short_name: 'bike', unified: '1F6B2' },
  { short_name: 'no_bicycles', unified: '1F6B3' },
  { short_name: 'bicyclist', unified: '1F6B4' },
  { short_name: 'mountain_bicyclist', unified: '1F6B5' },
  { short_name: 'walking', unified: '1F6B6' },
  { short_name: 'no_pedestrians', unified: '1F6B7' },
  { short_name: 'children_crossing', unified: '1F6B8' },
  { short_name: 'mens', unified: '1F6B9' },
  { short_name: 'womens', unified: '1F6BA' },
  { short_name: 'restroom', unified: '1F6BB' },
  { short_name: 'baby_symbol', unified: '1F6BC' },
  { short_name: 'toilet', unified: '1F6BD' },
  { short_name: 'wc', unified: '1F6BE' },
  { short_name: 'shower', unified: '1F6BF' },
  { short_name: 'bath', unified: '1F6C0' },
  { short_name: 'bathtub', unified: '1F6C1' },
  { short_name: 'passport_control', unified: '1F6C2' },
  { short_name: 'customs', unified: '1F6C3' },
  { short_name: 'baggage_claim', unified: '1F6C4' },
  { short_name: 'left_luggage', unified: '1F6C5' },
  { short_name: 'couch_and_lamp', unified: '1F6CB' },
  { short_name: 'sleeping_accommodation', unified: '1F6CC' },
  { short_name: 'shopping_bags', unified: '1F6CD' },
  { short_name: 'bellhop_bell', unified: '1F6CE' },
  { short_name: 'bed', unified: '1F6CF' },
  { short_name: 'place_of_worship', unified: '1F6D0' },
  { short_name: 'octagonal_sign', unified: '1F6D1' },
  { short_name: 'shopping_trolley', unified: '1F6D2' },
  { short_name: 'hammer_and_wrench', unified: '1F6E0' },
  { short_name: 'shield', unified: '1F6E1' },
  { short_name: 'oil_drum', unified: '1F6E2' },
  { short_name: 'motorway', unified: '1F6E3' },
  { short_name: 'railway_track', unified: '1F6E4' },
  { short_name: 'motor_boat', unified: '1F6E5' },
  { short_name: 'small_airplane', unified: '1F6E9' },
  { short_name: 'airplane_departure', unified: '1F6EB' },
  { short_name: 'airplane_arriving', unified: '1F6EC' },
  { short_name: 'satellite', unified: '1F6F0' },
  { short_name: 'passenger_ship', unified: '1F6F3' },
  { short_name: 'scooter', unified: '1F6F4' },
  { short_name: 'motor_scooter', unified: '1F6F5' },
  { short_name: 'canoe', unified: '1F6F6' },
  { short_name: 'zipper_mouth_face', unified: '1F910' },
  { short_name: 'money_mouth_face', unified: '1F911' },
  { short_name: 'face_with_thermometer', unified: '1F912' },
  { short_name: 'nerd_face', unified: '1F913' },
  { short_name: 'thinking_face', unified: '1F914' },
  { short_name: 'face_with_head_bandage', unified: '1F915' },
  { short_name: 'robot_face', unified: '1F916' },
  { short_name: 'hugging_face', unified: '1F917' },
  { short_name: 'the_horns', unified: '1F918' },
  { short_name: 'call_me_hand', unified: '1F919' },
  { short_name: 'raised_back_of_hand', unified: '1F91A' },
  { short_name: 'left-facing_fist', unified: '1F91B' },
  { short_name: 'right-facing_fist', unified: '1F91C' },
  { short_name: 'handshake', unified: '1F91D' },
  {
    short_name: 'hand_with_index_and_middle_fingers_crossed',
    unified: '1F91E',
  },
  { short_name: 'face_with_cowboy_hat', unified: '1F920' },
  { short_name: 'clown_face', unified: '1F921' },
  { short_name: 'nauseated_face', unified: '1F922' },
  { short_name: 'rolling_on_the_floor_laughing', unified: '1F923' },
  { short_name: 'drooling_face', unified: '1F924' },
  { short_name: 'lying_face', unified: '1F925' },
  { short_name: 'face_palm', unified: '1F926' },
  { short_name: 'sneezing_face', unified: '1F927' },
  { short_name: 'pregnant_woman', unified: '1F930' },
  { short_name: 'selfie', unified: '1F933' },
  { short_name: 'prince', unified: '1F934' },
  { short_name: 'man_in_tuxedo', unified: '1F935' },
  { short_name: 'mother_christmas', unified: '1F936' },
  { short_name: 'shrug', unified: '1F937' },
  { short_name: 'person_doing_cartwheel', unified: '1F938' },
  { short_name: 'juggling', unified: '1F939' },
  { short_name: 'fencer', unified: '1F93A' },
  { short_name: 'wrestlers', unified: '1F93C' },
  { short_name: 'water_polo', unified: '1F93D' },
  { short_name: 'handball', unified: '1F93E' },
  { short_name: 'wilted_flower', unified: '1F940' },
  { short_name: 'drum_with_drumsticks', unified: '1F941' },
  { short_name: 'clinking_glasses', unified: '1F942' },
  { short_name: 'tumbler_glass', unified: '1F943' },
  { short_name: 'spoon', unified: '1F944' },
  { short_name: 'goal_net', unified: '1F945' },
  { short_name: 'first_place_medal', unified: '1F947' },
  { short_name: 'second_place_medal', unified: '1F948' },
  { short_name: 'third_place_medal', unified: '1F949' },
  { short_name: 'boxing_glove', unified: '1F94A' },
  { short_name: 'martial_arts_uniform', unified: '1F94B' },
  { short_name: 'croissant', unified: '1F950' },
  { short_name: 'avocado', unified: '1F951' },
  { short_name: 'cucumber', unified: '1F952' },
  { short_name: 'bacon', unified: '1F953' },
  { short_name: 'potato', unified: '1F954' },
  { short_name: 'carrot', unified: '1F955' },
  { short_name: 'baguette_bread', unified: '1F956' },
  { short_name: 'green_salad', unified: '1F957' },
  { short_name: 'shallow_pan_of_food', unified: '1F958' },
  { short_name: 'stuffed_flatbread', unified: '1F959' },
  { short_name: 'egg', unified: '1F95A' },
  { short_name: 'glass_of_milk', unified: '1F95B' },
  { short_name: 'peanuts', unified: '1F95C' },
  { short_name: 'kiwifruit', unified: '1F95D' },
  { short_name: 'pancakes', unified: '1F95E' },
  { short_name: 'crab', unified: '1F980' },
  { short_name: 'lion_face', unified: '1F981' },
  { short_name: 'scorpion', unified: '1F982' },
  { short_name: 'turkey', unified: '1F983' },
  { short_name: 'unicorn_face', unified: '1F984' },
  { short_name: 'eagle', unified: '1F985' },
  { short_name: 'duck', unified: '1F986' },
  { short_name: 'bat', unified: '1F987' },
  { short_name: 'shark', unified: '1F988' },
  { short_name: 'owl', unified: '1F989' },
  { short_name: 'fox_face', unified: '1F98A' },
  { short_name: 'butterfly', unified: '1F98B' },
  { short_name: 'deer', unified: '1F98C' },
  { short_name: 'gorilla', unified: '1F98D' },
  { short_name: 'lizard', unified: '1F98E' },
  { short_name: 'rhinoceros', unified: '1F98F' },
  { short_name: 'shrimp', unified: '1F990' },
  { short_name: 'squid', unified: '1F991' },
  { short_name: 'cheese_wedge', unified: '1F9C0' },
  { short_name: 'hash', unified: '0023-20E3' },
  { short_name: 'keycap_star', unified: '002A-20E3' },
  { short_name: 'zero', unified: '0030-20E3' },
  { short_name: 'one', unified: '0031-20E3' },
  { short_name: 'two', unified: '0032-20E3' },
  { short_name: 'three', unified: '0033-20E3' },
  { short_name: 'four', unified: '0034-20E3' },
  { short_name: 'five', unified: '0035-20E3' },
  { short_name: 'six', unified: '0036-20E3' },
  { short_name: 'seven', unified: '0037-20E3' },
  { short_name: 'eight', unified: '0038-20E3' },
  { short_name: 'nine', unified: '0039-20E3' },
  { short_name: 'flag-ac', unified: '1F1E6-1F1E8' },
  { short_name: 'flag-ad', unified: '1F1E6-1F1E9' },
  { short_name: 'flag-ae', unified: '1F1E6-1F1EA' },
  { short_name: 'flag-af', unified: '1F1E6-1F1EB' },
  { short_name: 'flag-ag', unified: '1F1E6-1F1EC' },
  { short_name: 'flag-ai', unified: '1F1E6-1F1EE' },
  { short_name: 'flag-al', unified: '1F1E6-1F1F1' },
  { short_name: 'flag-am', unified: '1F1E6-1F1F2' },
  { short_name: 'flag-ao', unified: '1F1E6-1F1F4' },
  { short_name: 'flag-aq', unified: '1F1E6-1F1F6' },
  { short_name: 'flag-ar', unified: '1F1E6-1F1F7' },
  { short_name: 'flag-as', unified: '1F1E6-1F1F8' },
  { short_name: 'flag-at', unified: '1F1E6-1F1F9' },
  { short_name: 'flag-au', unified: '1F1E6-1F1FA' },
  { short_name: 'flag-aw', unified: '1F1E6-1F1FC' },
  { short_name: 'flag-ax', unified: '1F1E6-1F1FD' },
  { short_name: 'flag-az', unified: '1F1E6-1F1FF' },
  { short_name: 'flag-ba', unified: '1F1E7-1F1E6' },
  { short_name: 'flag-bb', unified: '1F1E7-1F1E7' },
  { short_name: 'flag-bd', unified: '1F1E7-1F1E9' },
  { short_name: 'flag-be', unified: '1F1E7-1F1EA' },
  { short_name: 'flag-bf', unified: '1F1E7-1F1EB' },
  { short_name: 'flag-bg', unified: '1F1E7-1F1EC' },
  { short_name: 'flag-bh', unified: '1F1E7-1F1ED' },
  { short_name: 'flag-bi', unified: '1F1E7-1F1EE' },
  { short_name: 'flag-bj', unified: '1F1E7-1F1EF' },
  { short_name: 'flag-bl', unified: '1F1E7-1F1F1' },
  { short_name: 'flag-bm', unified: '1F1E7-1F1F2' },
  { short_name: 'flag-bn', unified: '1F1E7-1F1F3' },
  { short_name: 'flag-bo', unified: '1F1E7-1F1F4' },
  { short_name: 'flag-bq', unified: '1F1E7-1F1F6' },
  { short_name: 'flag-br', unified: '1F1E7-1F1F7' },
  { short_name: 'flag-bs', unified: '1F1E7-1F1F8' },
  { short_name: 'flag-bt', unified: '1F1E7-1F1F9' },
  { short_name: 'flag-bv', unified: '1F1E7-1F1FB' },
  { short_name: 'flag-bw', unified: '1F1E7-1F1FC' },
  { short_name: 'flag-by', unified: '1F1E7-1F1FE' },
  { short_name: 'flag-bz', unified: '1F1E7-1F1FF' },
  { short_name: 'flag-ca', unified: '1F1E8-1F1E6' },
  { short_name: 'flag-cc', unified: '1F1E8-1F1E8' },
  { short_name: 'flag-cd', unified: '1F1E8-1F1E9' },
  { short_name: 'flag-cf', unified: '1F1E8-1F1EB' },
  { short_name: 'flag-cg', unified: '1F1E8-1F1EC' },
  { short_name: 'flag-ch', unified: '1F1E8-1F1ED' },
  { short_name: 'flag-ci', unified: '1F1E8-1F1EE' },
  { short_name: 'flag-ck', unified: '1F1E8-1F1F0' },
  { short_name: 'flag-cl', unified: '1F1E8-1F1F1' },
  { short_name: 'flag-cm', unified: '1F1E8-1F1F2' },
  { short_name: 'flag-cn', unified: '1F1E8-1F1F3' },
  { short_name: 'flag-co', unified: '1F1E8-1F1F4' },
  { short_name: 'flag-cp', unified: '1F1E8-1F1F5' },
  { short_name: 'flag-cr', unified: '1F1E8-1F1F7' },
  { short_name: 'flag-cu', unified: '1F1E8-1F1FA' },
  { short_name: 'flag-cv', unified: '1F1E8-1F1FB' },
  { short_name: 'flag-cw', unified: '1F1E8-1F1FC' },
  { short_name: 'flag-cx', unified: '1F1E8-1F1FD' },
  { short_name: 'flag-cy', unified: '1F1E8-1F1FE' },
  { short_name: 'flag-cz', unified: '1F1E8-1F1FF' },
  { short_name: 'flag-de', unified: '1F1E9-1F1EA' },
  { short_name: 'flag-dg', unified: '1F1E9-1F1EC' },
  { short_name: 'flag-dj', unified: '1F1E9-1F1EF' },
  { short_name: 'flag-dk', unified: '1F1E9-1F1F0' },
  { short_name: 'flag-dm', unified: '1F1E9-1F1F2' },
  { short_name: 'flag-do', unified: '1F1E9-1F1F4' },
  { short_name: 'flag-dz', unified: '1F1E9-1F1FF' },
  { short_name: 'flag-ea', unified: '1F1EA-1F1E6' },
  { short_name: 'flag-ec', unified: '1F1EA-1F1E8' },
  { short_name: 'flag-ee', unified: '1F1EA-1F1EA' },
  { short_name: 'flag-eg', unified: '1F1EA-1F1EC' },
  { short_name: 'flag-eh', unified: '1F1EA-1F1ED' },
  { short_name: 'flag-er', unified: '1F1EA-1F1F7' },
  { short_name: 'flag-es', unified: '1F1EA-1F1F8' },
  { short_name: 'flag-et', unified: '1F1EA-1F1F9' },
  { short_name: 'flag-eu', unified: '1F1EA-1F1FA' },
  { short_name: 'flag-fi', unified: '1F1EB-1F1EE' },
  { short_name: 'flag-fj', unified: '1F1EB-1F1EF' },
  { short_name: 'flag-fk', unified: '1F1EB-1F1F0' },
  { short_name: 'flag-fm', unified: '1F1EB-1F1F2' },
  { short_name: 'flag-fo', unified: '1F1EB-1F1F4' },
  { short_name: 'flag-fr', unified: '1F1EB-1F1F7' },
  { short_name: 'flag-ga', unified: '1F1EC-1F1E6' },
  { short_name: 'flag-gb', unified: '1F1EC-1F1E7' },
  { short_name: 'flag-gd', unified: '1F1EC-1F1E9' },
  { short_name: 'flag-ge', unified: '1F1EC-1F1EA' },
  { short_name: 'flag-gf', unified: '1F1EC-1F1EB' },
  { short_name: 'flag-gg', unified: '1F1EC-1F1EC' },
  { short_name: 'flag-gh', unified: '1F1EC-1F1ED' },
  { short_name: 'flag-gi', unified: '1F1EC-1F1EE' },
  { short_name: 'flag-gl', unified: '1F1EC-1F1F1' },
  { short_name: 'flag-gm', unified: '1F1EC-1F1F2' },
  { short_name: 'flag-gn', unified: '1F1EC-1F1F3' },
  { short_name: 'flag-gp', unified: '1F1EC-1F1F5' },
  { short_name: 'flag-gq', unified: '1F1EC-1F1F6' },
  { short_name: 'flag-gr', unified: '1F1EC-1F1F7' },
  { short_name: 'flag-gs', unified: '1F1EC-1F1F8' },
  { short_name: 'flag-gt', unified: '1F1EC-1F1F9' },
  { short_name: 'flag-gu', unified: '1F1EC-1F1FA' },
  { short_name: 'flag-gw', unified: '1F1EC-1F1FC' },
  { short_name: 'flag-gy', unified: '1F1EC-1F1FE' },
  { short_name: 'flag-hk', unified: '1F1ED-1F1F0' },
  { short_name: 'flag-hm', unified: '1F1ED-1F1F2' },
  { short_name: 'flag-hn', unified: '1F1ED-1F1F3' },
  { short_name: 'flag-hr', unified: '1F1ED-1F1F7' },
  { short_name: 'flag-ht', unified: '1F1ED-1F1F9' },
  { short_name: 'flag-hu', unified: '1F1ED-1F1FA' },
  { short_name: 'flag-ic', unified: '1F1EE-1F1E8' },
  { short_name: 'flag-id', unified: '1F1EE-1F1E9' },
  { short_name: 'flag-ie', unified: '1F1EE-1F1EA' },
  { short_name: 'flag-il', unified: '1F1EE-1F1F1' },
  { short_name: 'flag-im', unified: '1F1EE-1F1F2' },
  { short_name: 'flag-in', unified: '1F1EE-1F1F3' },
  { short_name: 'flag-io', unified: '1F1EE-1F1F4' },
  { short_name: 'flag-iq', unified: '1F1EE-1F1F6' },
  { short_name: 'flag-ir', unified: '1F1EE-1F1F7' },
  { short_name: 'flag-is', unified: '1F1EE-1F1F8' },
  { short_name: 'flag-it', unified: '1F1EE-1F1F9' },
  { short_name: 'flag-je', unified: '1F1EF-1F1EA' },
  { short_name: 'flag-jm', unified: '1F1EF-1F1F2' },
  { short_name: 'flag-jo', unified: '1F1EF-1F1F4' },
  { short_name: 'flag-jp', unified: '1F1EF-1F1F5' },
  { short_name: 'flag-ke', unified: '1F1F0-1F1EA' },
  { short_name: 'flag-kg', unified: '1F1F0-1F1EC' },
  { short_name: 'flag-kh', unified: '1F1F0-1F1ED' },
  { short_name: 'flag-ki', unified: '1F1F0-1F1EE' },
  { short_name: 'flag-km', unified: '1F1F0-1F1F2' },
  { short_name: 'flag-kn', unified: '1F1F0-1F1F3' },
  { short_name: 'flag-kp', unified: '1F1F0-1F1F5' },
  { short_name: 'flag-kr', unified: '1F1F0-1F1F7' },
  { short_name: 'flag-kw', unified: '1F1F0-1F1FC' },
  { short_name: 'flag-ky', unified: '1F1F0-1F1FE' },
  { short_name: 'flag-kz', unified: '1F1F0-1F1FF' },
  { short_name: 'flag-la', unified: '1F1F1-1F1E6' },
  { short_name: 'flag-lb', unified: '1F1F1-1F1E7' },
  { short_name: 'flag-lc', unified: '1F1F1-1F1E8' },
  { short_name: 'flag-li', unified: '1F1F1-1F1EE' },
  { short_name: 'flag-lk', unified: '1F1F1-1F1F0' },
  { short_name: 'flag-lr', unified: '1F1F1-1F1F7' },
  { short_name: 'flag-ls', unified: '1F1F1-1F1F8' },
  { short_name: 'flag-lt', unified: '1F1F1-1F1F9' },
  { short_name: 'flag-lu', unified: '1F1F1-1F1FA' },
  { short_name: 'flag-lv', unified: '1F1F1-1F1FB' },
  { short_name: 'flag-ly', unified: '1F1F1-1F1FE' },
  { short_name: 'flag-ma', unified: '1F1F2-1F1E6' },
  { short_name: 'flag-mc', unified: '1F1F2-1F1E8' },
  { short_name: 'flag-md', unified: '1F1F2-1F1E9' },
  { short_name: 'flag-me', unified: '1F1F2-1F1EA' },
  { short_name: 'flag-mf', unified: '1F1F2-1F1EB' },
  { short_name: 'flag-mg', unified: '1F1F2-1F1EC' },
  { short_name: 'flag-mh', unified: '1F1F2-1F1ED' },
  { short_name: 'flag-mk', unified: '1F1F2-1F1F0' },
  { short_name: 'flag-ml', unified: '1F1F2-1F1F1' },
  { short_name: 'flag-mm', unified: '1F1F2-1F1F2' },
  { short_name: 'flag-mn', unified: '1F1F2-1F1F3' },
  { short_name: 'flag-mo', unified: '1F1F2-1F1F4' },
  { short_name: 'flag-mp', unified: '1F1F2-1F1F5' },
  { short_name: 'flag-mq', unified: '1F1F2-1F1F6' },
  { short_name: 'flag-mr', unified: '1F1F2-1F1F7' },
  { short_name: 'flag-ms', unified: '1F1F2-1F1F8' },
  { short_name: 'flag-mt', unified: '1F1F2-1F1F9' },
  { short_name: 'flag-mu', unified: '1F1F2-1F1FA' },
  { short_name: 'flag-mv', unified: '1F1F2-1F1FB' },
  { short_name: 'flag-mw', unified: '1F1F2-1F1FC' },
  { short_name: 'flag-mx', unified: '1F1F2-1F1FD' },
  { short_name: 'flag-my', unified: '1F1F2-1F1FE' },
  { short_name: 'flag-mz', unified: '1F1F2-1F1FF' },
  { short_name: 'flag-na', unified: '1F1F3-1F1E6' },
  { short_name: 'flag-nc', unified: '1F1F3-1F1E8' },
  { short_name: 'flag-ne', unified: '1F1F3-1F1EA' },
  { short_name: 'flag-nf', unified: '1F1F3-1F1EB' },
  { short_name: 'flag-ng', unified: '1F1F3-1F1EC' },
  { short_name: 'flag-ni', unified: '1F1F3-1F1EE' },
  { short_name: 'flag-nl', unified: '1F1F3-1F1F1' },
  { short_name: 'flag-no', unified: '1F1F3-1F1F4' },
  { short_name: 'flag-np', unified: '1F1F3-1F1F5' },
  { short_name: 'flag-nr', unified: '1F1F3-1F1F7' },
  { short_name: 'flag-nu', unified: '1F1F3-1F1FA' },
  { short_name: 'flag-nz', unified: '1F1F3-1F1FF' },
  { short_name: 'flag-om', unified: '1F1F4-1F1F2' },
  { short_name: 'flag-pa', unified: '1F1F5-1F1E6' },
  { short_name: 'flag-pe', unified: '1F1F5-1F1EA' },
  { short_name: 'flag-pf', unified: '1F1F5-1F1EB' },
  { short_name: 'flag-pg', unified: '1F1F5-1F1EC' },
  { short_name: 'flag-ph', unified: '1F1F5-1F1ED' },
  { short_name: 'flag-pk', unified: '1F1F5-1F1F0' },
  { short_name: 'flag-pl', unified: '1F1F5-1F1F1' },
  { short_name: 'flag-pm', unified: '1F1F5-1F1F2' },
  { short_name: 'flag-pn', unified: '1F1F5-1F1F3' },
  { short_name: 'flag-pr', unified: '1F1F5-1F1F7' },
  { short_name: 'flag-ps', unified: '1F1F5-1F1F8' },
  { short_name: 'flag-pt', unified: '1F1F5-1F1F9' },
  { short_name: 'flag-pw', unified: '1F1F5-1F1FC' },
  { short_name: 'flag-py', unified: '1F1F5-1F1FE' },
  { short_name: 'flag-qa', unified: '1F1F6-1F1E6' },
  { short_name: 'flag-re', unified: '1F1F7-1F1EA' },
  { short_name: 'flag-ro', unified: '1F1F7-1F1F4' },
  { short_name: 'flag-rs', unified: '1F1F7-1F1F8' },
  { short_name: 'flag-ru', unified: '1F1F7-1F1FA' },
  { short_name: 'flag-rw', unified: '1F1F7-1F1FC' },
  { short_name: 'flag-sa', unified: '1F1F8-1F1E6' },
  { short_name: 'flag-sb', unified: '1F1F8-1F1E7' },
  { short_name: 'flag-sc', unified: '1F1F8-1F1E8' },
  { short_name: 'flag-sd', unified: '1F1F8-1F1E9' },
  { short_name: 'flag-se', unified: '1F1F8-1F1EA' },
  { short_name: 'flag-sg', unified: '1F1F8-1F1EC' },
  { short_name: 'flag-sh', unified: '1F1F8-1F1ED' },
  { short_name: 'flag-si', unified: '1F1F8-1F1EE' },
  { short_name: 'flag-sj', unified: '1F1F8-1F1EF' },
  { short_name: 'flag-sk', unified: '1F1F8-1F1F0' },
  { short_name: 'flag-sl', unified: '1F1F8-1F1F1' },
  { short_name: 'flag-sm', unified: '1F1F8-1F1F2' },
  { short_name: 'flag-sn', unified: '1F1F8-1F1F3' },
  { short_name: 'flag-so', unified: '1F1F8-1F1F4' },
  { short_name: 'flag-sr', unified: '1F1F8-1F1F7' },
  { short_name: 'flag-ss', unified: '1F1F8-1F1F8' },
  { short_name: 'flag-st', unified: '1F1F8-1F1F9' },
  { short_name: 'flag-sv', unified: '1F1F8-1F1FB' },
  { short_name: 'flag-sx', unified: '1F1F8-1F1FD' },
  { short_name: 'flag-sy', unified: '1F1F8-1F1FE' },
  { short_name: 'flag-sz', unified: '1F1F8-1F1FF' },
  { short_name: 'flag-ta', unified: '1F1F9-1F1E6' },
  { short_name: 'flag-tc', unified: '1F1F9-1F1E8' },
  { short_name: 'flag-td', unified: '1F1F9-1F1E9' },
  { short_name: 'flag-tf', unified: '1F1F9-1F1EB' },
  { short_name: 'flag-tg', unified: '1F1F9-1F1EC' },
  { short_name: 'flag-th', unified: '1F1F9-1F1ED' },
  { short_name: 'flag-tj', unified: '1F1F9-1F1EF' },
  { short_name: 'flag-tk', unified: '1F1F9-1F1F0' },
  { short_name: 'flag-tl', unified: '1F1F9-1F1F1' },
  { short_name: 'flag-tm', unified: '1F1F9-1F1F2' },
  { short_name: 'flag-tn', unified: '1F1F9-1F1F3' },
  { short_name: 'flag-to', unified: '1F1F9-1F1F4' },
  { short_name: 'flag-tr', unified: '1F1F9-1F1F7' },
  { short_name: 'flag-tt', unified: '1F1F9-1F1F9' },
  { short_name: 'flag-tv', unified: '1F1F9-1F1FB' },
  { short_name: 'flag-tw', unified: '1F1F9-1F1FC' },
  { short_name: 'flag-tz', unified: '1F1F9-1F1FF' },
  { short_name: 'flag-ua', unified: '1F1FA-1F1E6' },
  { short_name: 'flag-ug', unified: '1F1FA-1F1EC' },
  { short_name: 'flag-um', unified: '1F1FA-1F1F2' },
  { short_name: 'flag-un', unified: '1F1FA-1F1F3' },
  { short_name: 'flag-us', unified: '1F1FA-1F1F8' },
  { short_name: 'flag-uy', unified: '1F1FA-1F1FE' },
  { short_name: 'flag-uz', unified: '1F1FA-1F1FF' },
  { short_name: 'flag-va', unified: '1F1FB-1F1E6' },
  { short_name: 'flag-vc', unified: '1F1FB-1F1E8' },
  { short_name: 'flag-ve', unified: '1F1FB-1F1EA' },
  { short_name: 'flag-vg', unified: '1F1FB-1F1EC' },
  { short_name: 'flag-vi', unified: '1F1FB-1F1EE' },
  { short_name: 'flag-vn', unified: '1F1FB-1F1F3' },
  { short_name: 'flag-vu', unified: '1F1FB-1F1FA' },
  { short_name: 'flag-wf', unified: '1F1FC-1F1EB' },
  { short_name: 'flag-ws', unified: '1F1FC-1F1F8' },
  { short_name: 'flag-xk', unified: '1F1FD-1F1F0' },
  { short_name: 'flag-ye', unified: '1F1FE-1F1EA' },
  { short_name: 'flag-yt', unified: '1F1FE-1F1F9' },
  { short_name: 'flag-za', unified: '1F1FF-1F1E6' },
  { short_name: 'flag-zm', unified: '1F1FF-1F1F2' },
  { short_name: 'flag-zw', unified: '1F1FF-1F1FC' },
  { short_name: 'male-farmer', unified: '1F468-200D-1F33E' },
  { short_name: 'male-cook', unified: '1F468-200D-1F373' },
  { short_name: 'male-student', unified: '1F468-200D-1F393' },
  { short_name: 'male-singer', unified: '1F468-200D-1F3A4' },
  { short_name: 'male-artist', unified: '1F468-200D-1F3A8' },
  { short_name: 'male-teacher', unified: '1F468-200D-1F3EB' },
  { short_name: 'male-factory-worker', unified: '1F468-200D-1F3ED' },
  { short_name: 'man-boy', unified: '1F468-200D-1F466' },
  { short_name: 'man-girl', unified: '1F468-200D-1F467' },
  { short_name: 'male-technologist', unified: '1F468-200D-1F4BB' },
  { short_name: 'male-office-worker', unified: '1F468-200D-1F4BC' },
  { short_name: 'male-mechanic', unified: '1F468-200D-1F527' },
  { short_name: 'male-scientist', unified: '1F468-200D-1F52C' },
  { short_name: 'male-astronaut', unified: '1F468-200D-1F680' },
  { short_name: 'male-firefighter', unified: '1F468-200D-1F692' },
  { short_name: 'female-farmer', unified: '1F469-200D-1F33E' },
  { short_name: 'female-cook', unified: '1F469-200D-1F373' },
  { short_name: 'female-student', unified: '1F469-200D-1F393' },
  { short_name: 'female-singer', unified: '1F469-200D-1F3A4' },
  { short_name: 'female-artist', unified: '1F469-200D-1F3A8' },
  { short_name: 'female-teacher', unified: '1F469-200D-1F3EB' },
  { short_name: 'female-factory-worker', unified: '1F469-200D-1F3ED' },
  { short_name: 'woman-boy', unified: '1F469-200D-1F466' },
  { short_name: 'woman-girl', unified: '1F469-200D-1F467' },
  { short_name: 'female-technologist', unified: '1F469-200D-1F4BB' },
  { short_name: 'female-office-worker', unified: '1F469-200D-1F4BC' },
  { short_name: 'female-mechanic', unified: '1F469-200D-1F527' },
  { short_name: 'female-scientist', unified: '1F469-200D-1F52C' },
  { short_name: 'female-astronaut', unified: '1F469-200D-1F680' },
  { short_name: 'female-firefighter', unified: '1F469-200D-1F692' },
  { short_name: 'woman-running', unified: '1F3C3-200D-2640-FE0F' },
  { short_name: 'man-running', unified: '1F3C3-200D-2642-FE0F' },
  { short_name: 'woman-surfing', unified: '1F3C4-200D-2640-FE0F' },
  { short_name: 'man-surfing', unified: '1F3C4-200D-2642-FE0F' },
  { short_name: 'woman-swimming', unified: '1F3CA-200D-2640-FE0F' },
  { short_name: 'man-swimming', unified: '1F3CA-200D-2642-FE0F' },
  {
    short_name: 'woman-lifting-weights',
    unified: '1F3CB-FE0F-200D-2640-FE0F',
  },
  {
    short_name: 'man-lifting-weights',
    unified: '1F3CB-FE0F-200D-2642-FE0F',
  },
  { short_name: 'woman-golfing', unified: '1F3CC-FE0F-200D-2640-FE0F' },
  { short_name: 'man-golfing', unified: '1F3CC-FE0F-200D-2642-FE0F' },
  { short_name: 'rainbow-flag', unified: '1F3F3-FE0F-200D-1F308' },
  {
    short_name: 'eye-in-speech-bubble',
    unified: '1F441-FE0F-200D-1F5E8-FE0F',
  },
  { short_name: 'man-boy-boy', unified: '1F468-200D-1F466-200D-1F466' },
  { short_name: 'man-girl-boy', unified: '1F468-200D-1F467-200D-1F466' },
  { short_name: 'man-girl-girl', unified: '1F468-200D-1F467-200D-1F467' },
  { short_name: 'man-man-boy', unified: '1F468-200D-1F468-200D-1F466' },
  {
    short_name: 'man-man-boy-boy',
    unified: '1F468-200D-1F468-200D-1F466-200D-1F466',
  },
  { short_name: 'man-man-girl', unified: '1F468-200D-1F468-200D-1F467' },
  {
    short_name: 'man-man-girl-boy',
    unified: '1F468-200D-1F468-200D-1F467-200D-1F466',
  },
  {
    short_name: 'man-man-girl-girl',
    unified: '1F468-200D-1F468-200D-1F467-200D-1F467',
  },
  { short_name: 'man-woman-boy', unified: '1F468-200D-1F469-200D-1F466' },
  {
    short_name: 'man-woman-boy-boy',
    unified: '1F468-200D-1F469-200D-1F466-200D-1F466',
  },
  { short_name: 'man-woman-girl', unified: '1F468-200D-1F469-200D-1F467' },
  {
    short_name: 'man-woman-girl-boy',
    unified: '1F468-200D-1F469-200D-1F467-200D-1F466',
  },
  {
    short_name: 'man-woman-girl-girl',
    unified: '1F468-200D-1F469-200D-1F467-200D-1F467',
  },
  { short_name: 'male-doctor', unified: '1F468-200D-2695-FE0F' },
  { short_name: 'male-judge', unified: '1F468-200D-2696-FE0F' },
  { short_name: 'male-pilot', unified: '1F468-200D-2708-FE0F' },
  {
    short_name: 'man-heart-man',
    unified: '1F468-200D-2764-FE0F-200D-1F468',
  },
  {
    short_name: 'man-kiss-man',
    unified: '1F468-200D-2764-FE0F-200D-1F48B-200D-1F468',
  },
  { short_name: 'woman-boy-boy', unified: '1F469-200D-1F466-200D-1F466' },
  { short_name: 'woman-girl-boy', unified: '1F469-200D-1F467-200D-1F466' },
  { short_name: 'woman-girl-girl', unified: '1F469-200D-1F467-200D-1F467' },
  { short_name: 'woman-woman-boy', unified: '1F469-200D-1F469-200D-1F466' },
  {
    short_name: 'woman-woman-boy-boy',
    unified: '1F469-200D-1F469-200D-1F466-200D-1F466',
  },
  {
    short_name: 'woman-woman-girl',
    unified: '1F469-200D-1F469-200D-1F467',
  },
  {
    short_name: 'woman-woman-girl-boy',
    unified: '1F469-200D-1F469-200D-1F467-200D-1F466',
  },
  {
    short_name: 'woman-woman-girl-girl',
    unified: '1F469-200D-1F469-200D-1F467-200D-1F467',
  },
  { short_name: 'female-doctor', unified: '1F469-200D-2695-FE0F' },
  { short_name: 'female-judge', unified: '1F469-200D-2696-FE0F' },
  { short_name: 'female-pilot', unified: '1F469-200D-2708-FE0F' },
  {
    short_name: 'woman-heart-man',
    unified: '1F469-200D-2764-FE0F-200D-1F468',
  },
  {
    short_name: 'woman-heart-woman',
    unified: '1F469-200D-2764-FE0F-200D-1F469',
  },
  {
    short_name: 'woman-kiss-man',
    unified: '1F469-200D-2764-FE0F-200D-1F48B-200D-1F468',
  },
  {
    short_name: 'woman-kiss-woman',
    unified: '1F469-200D-2764-FE0F-200D-1F48B-200D-1F469',
  },
  { short_name: 'female-police-officer', unified: '1F46E-200D-2640-FE0F' },
  { short_name: 'male-police-officer', unified: '1F46E-200D-2642-FE0F' },
  {
    short_name: 'woman-with-bunny-ears-partying',
    unified: '1F46F-200D-2640-FE0F',
  },
  {
    short_name: 'man-with-bunny-ears-partying',
    unified: '1F46F-200D-2642-FE0F',
  },
  { short_name: 'blond-haired-woman', unified: '1F471-200D-2640-FE0F' },
  { short_name: 'blond-haired-man', unified: '1F471-200D-2642-FE0F' },
  { short_name: 'woman-wearing-turban', unified: '1F473-200D-2640-FE0F' },
  { short_name: 'man-wearing-turban', unified: '1F473-200D-2642-FE0F' },
  {
    short_name: 'female-construction-worker',
    unified: '1F477-200D-2640-FE0F',
  },
  {
    short_name: 'male-construction-worker',
    unified: '1F477-200D-2642-FE0F',
  },
  { short_name: 'woman-tipping-hand', unified: '1F481-200D-2640-FE0F' },
  { short_name: 'man-tipping-hand', unified: '1F481-200D-2642-FE0F' },
  { short_name: 'female-guard', unified: '1F482-200D-2640-FE0F' },
  { short_name: 'male-guard', unified: '1F482-200D-2642-FE0F' },
  { short_name: 'woman-getting-massage', unified: '1F486-200D-2640-FE0F' },
  { short_name: 'man-getting-massage', unified: '1F486-200D-2642-FE0F' },
  { short_name: 'woman-getting-haircut', unified: '1F487-200D-2640-FE0F' },
  { short_name: 'man-getting-haircut', unified: '1F487-200D-2642-FE0F' },
  { short_name: 'female-detective', unified: '1F575-FE0F-200D-2640-FE0F' },
  { short_name: 'male-detective', unified: '1F575-FE0F-200D-2642-FE0F' },
  { short_name: 'woman-gesturing-no', unified: '1F645-200D-2640-FE0F' },
  { short_name: 'man-gesturing-no', unified: '1F645-200D-2642-FE0F' },
  { short_name: 'woman-gesturing-ok', unified: '1F646-200D-2640-FE0F' },
  { short_name: 'man-gesturing-ok', unified: '1F646-200D-2642-FE0F' },
  { short_name: 'woman-bowing', unified: '1F647-200D-2640-FE0F' },
  { short_name: 'man-bowing', unified: '1F647-200D-2642-FE0F' },
  { short_name: 'woman-raising-hand', unified: '1F64B-200D-2640-FE0F' },
  { short_name: 'man-raising-hand', unified: '1F64B-200D-2642-FE0F' },
  { short_name: 'woman-frowning', unified: '1F64D-200D-2640-FE0F' },
  { short_name: 'man-frowning', unified: '1F64D-200D-2642-FE0F' },
  { short_name: 'woman-pouting', unified: '1F64E-200D-2640-FE0F' },
  { short_name: 'man-pouting', unified: '1F64E-200D-2642-FE0F' },
  { short_name: 'woman-rowing-boat', unified: '1F6A3-200D-2640-FE0F' },
  { short_name: 'man-rowing-boat', unified: '1F6A3-200D-2642-FE0F' },
  { short_name: 'woman-biking', unified: '1F6B4-200D-2640-FE0F' },
  { short_name: 'man-biking', unified: '1F6B4-200D-2642-FE0F' },
  { short_name: 'woman-mountain-biking', unified: '1F6B5-200D-2640-FE0F' },
  { short_name: 'man-mountain-biking', unified: '1F6B5-200D-2642-FE0F' },
  { short_name: 'woman-walking', unified: '1F6B6-200D-2640-FE0F' },
  { short_name: 'man-walking', unified: '1F6B6-200D-2642-FE0F' },
  { short_name: 'woman-facepalming', unified: '1F926-200D-2640-FE0F' },
  { short_name: 'man-facepalming', unified: '1F926-200D-2642-FE0F' },
  { short_name: 'woman-shrugging', unified: '1F937-200D-2640-FE0F' },
  { short_name: 'man-shrugging', unified: '1F937-200D-2642-FE0F' },
  { short_name: 'woman-cartwheeling', unified: '1F938-200D-2640-FE0F' },
  { short_name: 'man-cartwheeling', unified: '1F938-200D-2642-FE0F' },
  { short_name: 'woman-juggling', unified: '1F939-200D-2640-FE0F' },
  { short_name: 'man-juggling', unified: '1F939-200D-2642-FE0F' },
  { short_name: 'woman-wrestling', unified: '1F93C-200D-2640-FE0F' },
  { short_name: 'man-wrestling', unified: '1F93C-200D-2642-FE0F' },
  {
    short_name: 'woman-playing-water-polo',
    unified: '1F93D-200D-2640-FE0F',
  },
  { short_name: 'man-playing-water-polo', unified: '1F93D-200D-2642-FE0F' },
  { short_name: 'woman-playing-handball', unified: '1F93E-200D-2640-FE0F' },
  { short_name: 'man-playing-handball', unified: '1F93E-200D-2642-FE0F' },
  {
    short_name: 'woman-bouncing-ball',
    unified: '26F9-FE0F-200D-2640-FE0F',
  },
  { short_name: 'man-bouncing-ball', unified: '26F9-FE0F-200D-2642-FE0F' },
];
