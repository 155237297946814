import { useEffect, useRef, useState } from 'react';

export function useDelayedHover(delay: number, referenceId: string) {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const hover = () => {
      if (!delay) {
        setIsHovered(true);
        return;
      }
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => setIsHovered(true), delay);
    };

    const leave = () => {
      if (timeout.current) clearTimeout(timeout.current);
      setIsHovered(false);
    };

    const reference = document.getElementById(referenceId);
    reference?.addEventListener('mouseenter', hover);
    reference?.addEventListener('mouseleave', leave);
    return () => {
      reference?.removeEventListener('mouseenter', hover);
      reference?.removeEventListener('mouseleave', leave);
    };
  }, [delay, referenceId]);

  return isHovered;
}
