import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const selectItemIconVariants = cva(
  [
    'absolute',
    'top-1/2',
    'transform',
    '-translate-y-1/2',
    'pointer-events-none',
  ],
  {
    variants: {
      variant: {
        idle: ['fill-[theme(textColor.DEFAULT)]'],
        error: ['fill-[theme(textColor.on.negative.weakest)]'],
        success: ['fill-[theme(textColor.on.positive.weakest)]'],
        disabled: ['fill-[theme(textColor.on.disabled.strong)]'],
      },
      size: {
        default: ['right-2'],
        compact: ['right-0'],
      },
    },
    defaultVariants: {
      size: 'default',
      variant: 'idle',
    },
  },
);

export type SelectIconIconVariantProps = VariantProps<
  typeof selectItemIconVariants
>;
