import type { FieldProps } from 'formik';

export const getErrorMessage = ({
  field,
  form,
}: Pick<FieldProps, 'field' | 'form'>) => {
  const { errors } = form;
  const error = errors[field.name];
  if (typeof error === 'string') return error;
  if (Array.isArray(error)) return error.join(', ');

  return;
};

export const getShouldShowError = ({
  field,
  form,
}: Pick<FieldProps, 'field' | 'form'>) => {
  const { touched, submitCount } = form;
  const touchedOrSubmitted = Boolean(touched[field.name] || submitCount > 0);
  const fieldHasMessageDefined = getErrorMessage({ field, form }) !== undefined;
  return touchedOrSubmitted && fieldHasMessageDefined;
};
