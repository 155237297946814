import type { Theme, ThemeColor } from '@mentimeter/http-clients';
import chroma from 'chroma-js';

const themeColorKeys = [
  'bar_color',
  'bar_text_color',
  'background_color',
  'line_color',
  'text_color',
] as const;

type ThemeColorKey = (typeof themeColorKeys)[number];

const isThemeColorKey = (key: any): key is ThemeColorKey => {
  return themeColorKeys.includes(key);
};

/*
 * Color transform function that takes a color transform function and
 * transform all the original theme colors
 */
const themeColorTransform = (
  theme: Theme,
  colorTransformer: (arg0: ThemeColor) => ThemeColor,
): Theme => {
  const transformedTheme = {} as Record<
    ThemeColorKey,
    ThemeColor | ThemeColor[]
  >;

  for (const key of Object.keys(theme)) {
    if (isThemeColorKey(key)) {
      const color = theme[key];
      if (Array.isArray(color)) {
        transformedTheme[key] = color.map(colorTransformer);
      } else {
        transformedTheme[key] = colorTransformer(color);
      }
    }
  }
  return { ...theme, ...(transformedTheme as Partial<Theme>) };
};

/*
 * Opaque theme
 * Remove transparency from theme colors
 */
const transformToOpaque = (color: ThemeColor) => {
  if (color === 'transparent') {
    return '#000000';
  }
  const rgbaColor = chroma(color).rgba();
  rgbaColor[3] = 1;
  return chroma(rgbaColor).css();
};

export const getOpaqueTheme = (theme: Theme): Theme => {
  return themeColorTransform(theme, transformToOpaque);
};
