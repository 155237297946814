import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import type { BoxT } from '../Box/Box';
import { Box } from '../Box/Box';

export const ModalFooter = ({ children, className, ...rest }: BoxT) => {
  const defaultClasses = [
    'w-full',
    'flex-col-reverse',
    'sm:flex-row',
    'items-stretch',
    'sm:items-center',
    'justify-end',
    'flex-wrap',
    'px-8',
    'pb-8',
    'pt-1',
    'gap-y-2',
    'gap-x-4',
  ];
  return (
    <Box {...rest} className={clsx(defaultClasses, className)}>
      {children}
    </Box>
  );
};
