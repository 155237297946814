import type { PictogramVariants } from './types';

export const COLOR_MAP: PictogramVariants = {
  blue: {
    pictogramPrimary: 'var(--palette-blue-500)',
    pictogramSecondary: 'var(--palette-blue-800)',
    pictogramTertiary: 'var(--palette-blue-200)',
  },
  green: {
    pictogramPrimary: 'var(--palette-green-500)',
    pictogramSecondary: 'var(--palette-green-800)',
    pictogramTertiary: 'var(--palette-green-200)',
  },
  purple: {
    pictogramPrimary: 'var(--palette-purple-500)',
    pictogramSecondary: 'var(--palette-purple-dark-600)',
    pictogramTertiary: 'var(--palette-purple-200)',
  },
  red: {
    pictogramPrimary: 'var(--palette-pink-dark-500)',
    pictogramSecondary: 'var(--palette-pink-dark-700)',
    pictogramTertiary: 'var(--palette-pink-light-500)',
  },
  yellow: {
    pictogramPrimary: 'var(--palette-yellow-500)',
    pictogramSecondary: 'var(--palette-yellow-dark-500)',
    pictogramTertiary: 'var(--palette-yellow-200)',
  },
};
