import {
  DEPRECATED_MODULE_ID,
  VALID_MODULE_IDS,
  type ValidModuleId,
} from '@mentimeter/http-clients';
import { MentiError } from '@mentimeter/errors/sentry';

export const getValidModuleIdFromString = (moduleId: string): ValidModuleId => {
  const validId = VALID_MODULE_IDS.find((id) => id === moduleId);
  if (validId) {
    return validId as ValidModuleId;
  }
  const deprecatedId = DEPRECATED_MODULE_ID.find((id) => id === moduleId);
  if (deprecatedId) {
    return 'deprecated';
  }

  throw new MentiError(`Unknown module id '${moduleId}'!`, {
    feature: 'voting-slide-type-importer',
  });
};
