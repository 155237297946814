import { create } from 'zustand';

type Status = 'idle' | 'connected' | 'audienceLimitReached';

export const useAudienceConnectStore = create<{
  status: Status;
  setStatus: (status: Status) => void;
  desktopExperienceLimitReached: boolean;
  setDesktopExperienceLimitReached: (
    desktopExperienceLimitReached: boolean,
  ) => void;
}>((set) => {
  return {
    status: 'idle',
    desktopExperienceLimitReached: false,
    setStatus: async (status) => {
      set({ status });
    },
    setDesktopExperienceLimitReached(desktopExperienceLimitReached) {
      set({ desktopExperienceLimitReached });
    },
  };
});
