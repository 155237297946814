import emojiRegexGenerator from 'emoji-regex';

export const emojiRegex = emojiRegexGenerator();

export const hasEmoji = (str: string) => {
  return Boolean(str.match(emojiRegex)) && str.match(emojiRegex)!.length > 0;
};

export const removeEmojisFromString = (str: string) => {
  return str.replace(emojiRegex, '');
};
