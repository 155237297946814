import * as React from 'react';
import type { ReactMarkdownProps } from 'react-markdown';
import ReactMarkdown from 'react-markdown';
import remarkDisableTokenizers from 'remark-disable-tokenizers';

// Specifies which reactMarkdown nodes translate to which remark tokenizers (not all of them should disable a tokenizer or even have one)
// excluded tokenizers: autoLink, url, setextHeading, indentedCode, escape, paragraph, text, html (block+inline)
const nodesTokenizersMap = {
  break: [{ name: 'break', type: 'inline' }],
  emphasis: [{ name: 'emphasis', type: 'inline' }],
  strong: [{ name: 'strong', type: 'inline' }],
  thematicBreak: [{ name: 'thematicBreak', type: 'block' }],
  blockquote: [{ name: 'blockquote', type: 'block' }],
  delete: [{ name: 'deletion', type: 'inline' }],
  link: [
    { name: 'link', type: 'inline' },
    { name: 'url', type: 'inline' },
  ],
  linkReference: [{ name: 'reference', type: 'inline' }],
  table: [{ name: 'table', type: 'block' }],
  list: [{ name: 'list', type: 'block' }],
  definition: [{ name: 'definition', type: 'block' }],
  heading: [{ name: 'atxHeading', type: 'block' }],
  inlineCode: [{ name: 'code', type: 'inline' }],
  code: [{ name: 'fencedCode', type: 'block' }],
  tableHead: null,
  tableBody: null,
  tableRow: null,
  tableCell: null,
  listItem: null,
  image: null,
  imageReference: null,
  root: null,
  paragraph: null,
  text: null,
  html: null,
  virtualHtml: null,
  parsedHtml: null,
  math: null,
  inlineMath: null,
};

// all block and inline tokenizers according to https://github.com/remarkjs/remark/tree/master/packages/remark-parse#parserblockmethods
const blockTokenizers: string[] = [
  'blankLine',
  'indentedCode',
  'fencedCode',
  'blockquote',
  'atxHeading',
  'thematicBreak',
  'list',
  'setextHeading',
  'html',
  'definition',
  'table',
  // 'paragraph' omitted as disabling it would cause error
];
const inlineTokenizers: string[] = [
  'escape',
  'autoLink',
  'url',
  'email',
  'html',
  'link',
  'reference',
  'strong',
  'emphasis',
  'deletion',
  'code',
  'break',
  // 'text' omitted as disabling it would cause error
];

const RagnarMarkdown = ({
  allowedTypes = [],
  plugins = [],
  ...props
}: ReactMarkdownProps) => {
  const memoizedPlugins = React.useMemo(() => {
    const inline = [...inlineTokenizers];
    const block = [...blockTokenizers];
    if (allowedTypes.length > 0) {
      // remove all tokenizers of allowed Nodes from removal lists (enables allowed Nodes)
      allowedTypes.forEach((value) => {
        const tokenizers = nodesTokenizersMap[value];
        if (tokenizers) {
          tokenizers.forEach((tokenizer) => {
            if (tokenizer.type === 'inline') {
              inline.splice(inline.indexOf(tokenizer.name), 1);
            } else if (tokenizer && tokenizer.type === 'block') {
              block.splice(block.indexOf(tokenizer.name), 1);
            }
          });
        }
      });
    } else {
      // if no allowedTypes specified, do not disable any tokenizers
      inline.length = block.length = 0;
    }

    const pluginsNew = [
      [remarkDisableTokenizers, { block, inline }],
      ...(plugins as any[]),
    ];
    return pluginsNew;
  }, [allowedTypes, plugins]);

  return (
    <ReactMarkdown
      {...props}
      allowedTypes={allowedTypes}
      plugins={memoizedPlugins}
    />
  );
};
export default RagnarMarkdown;
