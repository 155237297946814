import { useSearchParams } from '@mentimeter/next-navigation';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';

const QUERY_PARAM_NAME = 'questionIndex';

const parseQuestionIndex = (questionIndex: string | null) => {
  if (!questionIndex) return 0;
  return parseInt(questionIndex, 10) || 0;
};

export const useAudiencePace = () => {
  const searchParams = useSearchParams();
  const questionIndex = parseQuestionIndex(searchParams.get(QUERY_PARAM_NAME));

  const router = useRouter();

  const navigateToNextSlide = useCallback(() => {
    const nextSearchParams = new URLSearchParams(searchParams);
    nextSearchParams.set(QUERY_PARAM_NAME, `${questionIndex + 1}`);
    router.push(`?${nextSearchParams.toString()}`);
  }, [questionIndex, router, searchParams]);

  return { questionIndex, navigateToNextSlide };
};
