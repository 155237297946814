/* eslint no-console: 0 */
import * as React from 'react';
import ReactDOM from 'react-dom';
import type { PositionProperty as PositionPropertyT } from '@mentimeter/ragnar-styled';

/**
 * Portal for rendering components in the body.
 */
interface Props {
  children: React.ReactNode;
  domId: string;
  zIndex?: number | undefined;
  position?: PositionPropertyT | undefined;
}

interface State {
  element: Element | null;
}

const createPortalElement = ({
  domId,
  zIndex,
  position = 'absolute',
}: Omit<Props, 'children'>) => {
  // Create or use existing Portal container
  let element = document.getElementById(domId);
  if (element) {
    // Note: For some additional props (mainly position) the portal is created before the
    // component is mounted hence teh default position of absolute is used. This make
    // dynamically sized tooltips very narrow and we need to overwrite the position as
    // soon as we get it.
    if (position !== 'absolute') {
      element.style.position = position;
    }
    return element;
  }

  // Create it!
  element = document.createElement('div');
  element.setAttribute('id', domId);
  if (zIndex !== undefined) {
    element.setAttribute(
      'style',
      `position: ${position}; top: 0; left: 0; z-index: ${zIndex}`,
    );
  }
  if (document.body) {
    document.body.appendChild(element);
  }
  return element;
};

/**
 * @deprecated Use the new Portal component instead. [Link to Storybook](https://storybook.mentimeter.org/?path=/docs/ragnar-ui-portal--docs)
 */
export class PortalOld extends React.Component<Props, State> {
  override state = {
    element: null,
  };

  override componentDidMount() {
    const { domId, position, zIndex } = this.props;
    const element = domId
      ? createPortalElement({ domId, position, zIndex })
      : document.body;
    this.setState({ element });
  }

  override render(): React.ReactPortal | null {
    const { children } = this.props;
    const { element } = this.state;
    return element ? ReactDOM.createPortal(children, element) : null;
  }
}
