import * as React from 'react';
import { sendMetric } from '@mentimeter/errors/sendMetric';
import type { VotingSDKT } from '@mentimeter/question-modules-types';
import {
  type Question,
  type VoterResultsResponse,
} from '@mentimeter/http-clients';
import useSWR from 'swr';
import * as SeriesActions from '../../actions/series';
import { NO_VOTE } from '../../constants/errorHandling';
import { trackEvent, useTrack, useTrackVote } from '../../utils/tracker';
import { useAppDispatch, useAppSelector } from '../../redux-hooks';
import {
  getDSCWithTheme,
  getEmojiFilterEnabled,
  getNumberOfQuizQuestions,
  getQuizQuestionPositionById,
} from '../../selectors';
import StringParserWrapper from '../../ui/Components/StringParserWrapper';
import { identifierClient } from '../../utils/identifier';
import { usePresentationState } from '../../presentation-state';
import { useTranslate } from '../localization/Translate';
import { fetchGroupedResults } from '../../api/series';
import { skipQuestion } from '../../reducers/votingSlice';
import { submitVote } from '../../actions/series';

const useTheme = () => useAppSelector(getDSCWithTheme).visualizationColors;

const usePresentation = () => {
  const emojiFilterEnabled = useAppSelector(getEmojiFilterEnabled);
  const pace = useAppSelector((state) => state.series.pace.mode);
  const voteId = useAppSelector((state) => state.series.vote_id?.toString());
  const voteKey = useAppSelector((state) => state.series.vote_key);
  return {
    emojiFilterEnabled: Boolean(emojiFilterEnabled),
    isAudiencePace: pace === 'audience',
    voteId,
    voteKey,
  };
};

const getIdentifier = () =>
  identifierClient.getLocalIdentifier().identifier ?? '';

const useActions = (question: Question) => {
  const failure = useAppSelector((state) => state.voting.failure);
  const isLoading = useAppSelector(
    (state) => state.voting.loading === 'pending',
  );
  const dispatch = useAppDispatch();

  const trackVote = useTrackVote();
  const [error, setError] = React.useState<string>();

  const vote = async (voteData: any, partial = true) => {
    setError(undefined);
    if (!question) {
      throw new Error('No question defined');
    }
    if (!voteData) {
      setError(NO_VOTE);
      return Promise.resolve({ type: 'submitVote/rejected', payload: NO_VOTE });
    }

    const data = {
      questionPublicKey: question.public_key,
      payload: {
        vote: voteData,
        type: question.type,
      },
      partial,
    };
    trackVote();

    return dispatch(submitVote(data)).then((res) => ({
      type: res.type,
      payload: res.payload,
    }));
  };

  const submitUpvote = async (voteId: string) => {
    setError(undefined);
    if (!question) {
      throw new Error('No question defined');
    }
    if (!voteId) return setError(NO_VOTE);
    const data = {
      questionPublicKey: question.public_key,
      payload: {
        type: question.type,
      },
      voteId,
    };
    await dispatch(SeriesActions.submitUpvote(data)).unwrap();
  };

  return {
    vote,
    skip: () => {
      if (!question) {
        throw new Error('No question defined');
      }
      dispatch(skipQuestion());
    },
    error: error || failure,
    submitUpvote,
    isLoading,
  };
};

const useResults = <T extends VoterResultsResponse>(
  questionPublicKey: string,
  shouldFetch = true,
): {
  data: T;
  error: any;
} => {
  const { data, error } = useSWR(
    shouldFetch ? questionPublicKey : null,
    async () => {
      const { data } = await SeriesActions.fetchVoterResults(questionPublicKey);
      return data;
    },
  );

  const EMPTY_DATA = {
    results: [] as T['results'],
    votes: [] as T['votes'],
    respondents: 0,
  };

  const cleanedData = (data ?? EMPTY_DATA) as T;
  return {
    data: {
      ...cleanedData,
      results: cleanedData.results ?? EMPTY_DATA.results,
      votes: cleanedData.votes ?? EMPTY_DATA.votes,
    },
    error,
  };
};

const useQuiz = (question: Question) => {
  const totalCount = useAppSelector(getNumberOfQuizQuestions);
  const currentIndex =
    useAppSelector(getQuizQuestionPositionById)(question.id) - 1;
  return {
    currentIndex,
    totalCount,
  };
};

const usePresentationStore = () => {
  const {
    step,
    hasResetVoting,
    disableResetVoting,
    currentSlideState,
    participantIdentity,
  } = usePresentationState();
  return {
    step,
    hasResetVoting,
    disableResetVoting,
    currentSlideState,
    participantIdentity,
  };
};

const useBullets = (question: Question) => {
  const { step: presenterStep } = usePresentationState();
  const paceMode = useAppSelector((state) => state.series.pace.mode);
  const choices = question.choices;
  const step =
    paceMode === 'audience' || question.show_all_bullets
      ? choices.length
      : presenterStep;
  return React.useMemo(() => choices.slice(0, step), [choices, step]);
};

const useGroupedResults = (questionPublicKey: string, shouldFetch: boolean) => {
  const { data, error } = useSWR(
    shouldFetch
      ? `/response-clusterings/for-question/${questionPublicKey}`
      : null,
    async () => {
      const { data } = await fetchGroupedResults(questionPublicKey);
      return data;
    },
  );

  return {
    data,
    error,
  };
};

export const createSDK = (question: Question): VotingSDKT => ({
  useTheme,
  useBullets: () => useBullets(question),
  usePresentationStore,
  useActions: () => useActions(question),
  useResults,
  usePresentation,
  useTranslate,
  getIdentifier,
  gaTrackEvent: trackEvent,
  useTrack,
  sendDdMetric: sendMetric,
  useQuestion: () => question,
  useQuiz: () => useQuiz(question),
  useGroupedResults,
  ThemeStyled: {
    StringParser: StringParserWrapper,
  },
});
