import invariant from 'tiny-invariant';
import type { Question, SeriesForPresentation } from '@mentimeter/http-clients';
import { v4 as uuidv4 } from 'uuid';
import { addBreadcrumb } from '@mentimeter/errors/sentry';
import type { InternalState, QRCodePlacement, SlideState } from './types';

const getCurrentSlide = (
  state: Pick<InternalState, 'slidePublicKey'>,
  series: SeriesForPresentation,
) => series.questions.find((s) => s.public_key === state.slidePublicKey);

const calculateState = (
  {
    id,
    slidePublicKey,
    step,
    hasReachedEnd,
    slideStates,
    blankScreenVisible,
    qrCodePlacement,
  }: Pick<
    InternalState,
    | 'id'
    | 'slidePublicKey'
    | 'step'
    | 'hasReachedEnd'
    | 'slideStates'
    | 'blankScreenVisible'
    | 'qrCodePlacement'
  >,
  series: SeriesForPresentation,
): InternalState => {
  const currentSlide = getCurrentSlide({ slidePublicKey }, series);
  if (!currentSlide) {
    throw new Error(
      `Could not find slide with public key ${slidePublicKey} in series`,
    );
  }

  const slideIndex = series.questions.indexOf(currentSlide);
  const hasNextStep = !hasReachedEnd && step < currentSlide.steps;
  const hasNextSlide = !hasReachedEnd;
  const hasPreviousStep = !hasReachedEnd && step > 0;
  const hasPreviousSlide = hasReachedEnd || slideIndex > 0;

  return {
    // From input
    id,
    slidePublicKey,
    step,
    hasReachedEnd,
    slideStates,
    blankScreenVisible,
    qrCodePlacement,

    // Calculated values
    slideIndex,
    totalSlides: series.questions.length,
    hasNext: hasNextStep || hasNextSlide,
    hasNextSlide,
    hasNextStep,
    hasPrevious: hasPreviousStep || hasPreviousSlide,
    hasPreviousSlide,
    hasPreviousStep,
  };
};

export const createInitialState = (
  series: SeriesForPresentation,
  slidePublicKey?: Question['public_key'],
): InternalState => {
  if (series.questions.length === 0) {
    throw new Error('Series has no questions');
  }

  const currentSlidePublicKey =
    slidePublicKey || series.questions[0]!.public_key;

  return calculateState(
    {
      id: uuidv4(),
      step: 0,
      slidePublicKey: currentSlidePublicKey,
      hasReachedEnd: false,
      slideStates: {},
      blankScreenVisible: false,
      qrCodePlacement: { vertical: 'bottom', horizontal: 'left' },
    },
    series,
  );
};

export const getSeriesUpdateState = (
  state: InternalState,
  series: SeriesForPresentation,
): InternalState => {
  if (series.questions.length === 0) {
    throw new Error('series has no questions');
  }

  const oldSlideIndex = state.slideIndex;

  let newSlideIndex = series.questions.findIndex(
    (s) => s.public_key === state.slidePublicKey,
  );

  const newStep = newSlideIndex === -1 ? 0 : state.step;

  if (newSlideIndex === -1) {
    // Slide has been removed from presentation
    newSlideIndex = Math.min(
      series.questions.length - 1,
      Math.max(0, oldSlideIndex - 1),
    );
  }

  const slide = series.questions[newSlideIndex];
  if (!slide) {
    throw new Error(`No slide found at new index ${newSlideIndex}`);
  }

  return calculateState(
    {
      ...state,
      slidePublicKey: slide.public_key,
      step: state.hasReachedEnd
        ? 0
        : Math.max(0, Math.min(newStep, slide.steps)),
    },
    series,
  );
};

export const getResetStepState = (
  state: InternalState | undefined,
  internalSeries: SeriesForPresentation | undefined,
): InternalState => {
  invariant(state, 'state missing on resetStep');
  invariant(internalSeries, 'series missing on resetStep');

  return calculateState(
    {
      ...state,
      step: 0,
    },
    internalSeries,
  );
};

export const getNextState = (
  state: InternalState | undefined,
  internalSeries: SeriesForPresentation | undefined,
) => {
  invariant(state, 'state missing on next');

  if (state.hasNextStep) {
    return getNextStepState(state, internalSeries);
  }
  if (state.hasNextSlide) {
    return getNextSlideState(state, internalSeries);
  }
  throw new Error('Tried to pace to next when there is none');
};

export const getNextSlideState = (
  state: InternalState | undefined,
  internalSeries: SeriesForPresentation | undefined,
): InternalState => {
  invariant(state, 'state missing on nextSlide');
  invariant(internalSeries, 'internalSeries missing on nextSlide');
  if (!state.hasNextSlide) {
    throw new Error('tried to pace to next slide when there is none');
  }

  if (state.slideIndex === internalSeries.questions.length - 1) {
    // Pacing next on last slide
    return calculateState(
      {
        ...state,
        hasReachedEnd: true,
      },
      internalSeries,
    );
  }
  const newSlideIndex = state.slideIndex + 1;
  const slidePublicKey = internalSeries.questions[newSlideIndex]!.public_key;

  return calculateState(
    {
      ...state,
      step: 0,
      slidePublicKey,
    },
    internalSeries,
  );
};

export const getNextStepState = (
  state: InternalState | undefined,
  internalSeries: SeriesForPresentation | undefined,
): InternalState => {
  invariant(state, 'state missing on nextStep');
  invariant(internalSeries, 'internalSeries missing on nextStep');

  if (!state.hasNextStep) {
    throw new Error('tried to pace to next step when there is none');
  }

  const slideSteps = getCurrentSlide(state, internalSeries)?.steps ?? 0;

  return calculateState(
    {
      ...state,
      step: Math.max(0, Math.min(state.step + 1, slideSteps)),
    },
    internalSeries,
  );
};

export const getPreviousState = (
  state: InternalState | undefined,
  internalSeries: SeriesForPresentation | undefined,
) => {
  invariant(state, 'state missing on previous');

  if (state.hasPreviousStep) {
    return getPreviousStepState(state, internalSeries);
  }
  if (state.hasPreviousSlide) {
    return getPreviousSlideState(state, internalSeries);
  }
  throw new Error('tried to pace to previous when there is none');
};

export const getPreviousSlideState = (
  state: InternalState | undefined,
  internalSeries: SeriesForPresentation | undefined,
): InternalState => {
  invariant(state, 'state missing on previousSlide');
  invariant(internalSeries, 'internalSeries missing on previousSlide');
  if (!state.hasPreviousSlide) {
    throw new Error('tried to pace to previous slide when there is none');
  }

  const newSlideIndex = state.hasReachedEnd
    ? state.slideIndex
    : state.slideIndex - 1;
  const newSlide = internalSeries.questions[newSlideIndex];

  if (!newSlide) {
    throw new Error('No slide found at new index');
  }

  return calculateState(
    {
      ...state,
      hasReachedEnd: false,
      slidePublicKey: newSlide.public_key,
      step: newSlide.steps,
    },
    internalSeries,
  );
};

export const getPreviousStepState = (
  state: InternalState | undefined,
  internalSeries: SeriesForPresentation | undefined,
): InternalState => {
  invariant(state, 'state missing on previousStep');
  invariant(internalSeries, 'internalSeries missing on previousStep');

  if (!state.hasPreviousStep) {
    throw new Error('tried to pace to previous step when there is none');
  }

  return calculateState(
    {
      ...state,
      step: Math.max(state.step - 1, 0),
    },
    internalSeries,
  );
};

export const getSetActiveSlideState = (
  state: InternalState | undefined,
  internalSeries: SeriesForPresentation | undefined,
  newSlidePublicKey: InternalState['slidePublicKey'],
): InternalState => {
  invariant(state, 'state missing on setActiveSlide');
  invariant(internalSeries, 'internalSeries missing on setActiveSlide');

  const newSlide = internalSeries.questions.some(
    (q) => q.public_key === newSlidePublicKey,
  );

  if (!newSlide) {
    throw new Error('Tried to activate a slide that does not exist');
  }

  return calculateState(
    {
      ...state,
      slidePublicKey: newSlidePublicKey,
      step: 0,
      hasReachedEnd: false,
    },
    internalSeries,
  );
};

export const getSetSlideState = (
  state: InternalState | undefined,
  publicKey: Question['public_key'],
  slideState: SlideState,
): InternalState => {
  invariant(state, 'state missing on setSlideState');

  addBreadcrumb({
    category: 'usePresentation',
    message: 'setSlideState action, keys to update:',
    data: { publicKey, slideState },
  });

  return {
    ...state,
    slideStates: {
      ...state.slideStates,
      [publicKey]: slideState,
    },
  };
};

export const getResetAllSlideStates = (
  state: InternalState | undefined,
): InternalState => {
  invariant(state, 'state missing on resetAllSlideStates');

  return {
    ...state,
    slideStates: {},
  };
};

export const getSetBlankScreenVisibleState = (
  state: InternalState | undefined,
  blankScreenVisible: boolean,
): InternalState => {
  invariant(state, 'state missing on setBlankScreenVisibleState');
  return {
    ...state,
    blankScreenVisible,
  };
};
export const getSetQrCodePlacementState = (
  state: InternalState | undefined,
  qrCodePlacement: QRCodePlacement,
): InternalState => {
  invariant(state, 'state missing on getSetQrCodePlacementState');
  return {
    ...state,
    qrCodePlacement,
  };
};
