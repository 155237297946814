import type { Reaction } from '@mentimeter/http-clients';
import { create } from 'zustand';

interface ReactionVotesState {
  reactionVotes: Record<string, Reaction>;
  clearReactionVotes: () => void;
  setReactionVote: (questionPublicKey: string, reaction: Reaction) => void;
}

export const useReactionVotes = create<ReactionVotesState>((set) => ({
  reactionVotes: {},
  clearReactionVotes() {
    set({ reactionVotes: {} });
  },
  setReactionVote(questionPublicKey, reaction) {
    set((state) => ({
      reactionVotes: {
        ...state.reactionVotes,
        [questionPublicKey]: reaction,
      },
    }));
  },
}));
