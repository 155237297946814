import * as React from 'react';
import axios from 'axios';
import { captureException } from '@sentry/nextjs';
import { MentiError } from './MentiError';
import type { Feature } from './features';

interface PropsT {
  children: React.ReactNode;
  feature: Feature;
  errorMessage?: string;
  fallback?: React.ReactElement | React.FC<{ error: Error }> | undefined;
}

interface StateT {
  hasError: boolean;
  error?: Error;
}

/**
 * Send errors that occur during rendering to sentry. It executes the 'captureException' function under the hood.
 *
 * @param {Feature} Feature - indicates feature boundary.
 * This detail will be sent to sentry as tag and is searchable. For example: `feature:sso`
 *
 * @param {React.ReactElement} fallback - component to display whenever a component/service fails.
 * It is highly recommended to have a fallback.
 */
export class ErrorBoundary extends React.Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  /* the error is not necessarily a MentiError, it can be anything. But in order to be able to set metadata further down,
  we need to type it as such. */
  override componentDidCatch(error: MentiError) {
    if (axios.isAxiosError(error)) {
      /* these are expected and don't need to be reported */
      return;
    }

    if (this.props.errorMessage) {
      captureException(
        new MentiError(this.props.errorMessage, {
          cause: error,
          feature: this.props.feature,
        }),
      );
    } else {
      if (error instanceof Error) {
        error.feature = this.props.feature;
      }
      captureException(error);
    }
  }

  override render(): React.ReactNode {
    if (this.state.hasError) {
      if (this.props.fallback instanceof Function) {
        const Fallback = this.props.fallback;
        return <Fallback error={this.state.error as Error} />;
      }
      return this.props.fallback || null;
    }
    const { children } = this.props;
    return children;
  }
}
