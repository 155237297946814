import { emojiData } from '@mentimeter/emojis';

const MAX_BULLET_DEPTH = 1;

interface Indentations {
  string: string;
  indentations: number;
  space: string;
}

/*
 * Calculate the number of indentations from a string, ex. "-- Bullet" => 2.
 */
export const getIndentations = (input: string): Indentations => {
  let output = input;
  const match = input.match(/^(-|\*)+\s/g);
  let indentations =
    match !== null && match[0] !== undefined ? match[0].length - 1 : 0;
  if (indentations > 0) {
    output = output.substr(indentations);
  }
  indentations = Math.min(indentations, MAX_BULLET_DEPTH);
  return {
    string: output,
    indentations,
    space: `${indentations < 0 ? 0 : indentations}em`,
  };
};

export function detectEmojis(input: string): string {
  const emojiRegexp = /:([a-zA-Z0-9-_+]+):(?::([a-zA-Z0-9-_+]+):)?/g;
  let emojiInput = input;
  let match: RegExpMatchArray | null;

  while ((match = emojiRegexp.exec(input))) {
    const emojiMatch = emojiData.find(
      (el) => match !== null && el.short_name === match[1],
    );

    if (match[0] !== undefined && emojiMatch) {
      const points = emojiMatch.unified.split('-').map((p) => parseInt(p, 16));
      const emoji = String.fromCodePoint(...points);
      emojiInput = emojiInput.replace(match[0], emoji);
    }
  }

  return emojiInput;
}
