import React from 'react';
import { addListener } from '@reduxjs/toolkit';
import { useAppDispatch } from '../redux-hooks';
import { skipQuestion } from '../reducers/votingSlice';

export const useOnSkipQuestion = (onSkipQuestion: () => void) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        actionCreator: skipQuestion,
        effect: onSkipQuestion,
      }),
    );
    return unsubscribe;
  }, [dispatch, onSkipQuestion]);
};
