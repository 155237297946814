import * as React from 'react';

export /**
 *Timer to control user feedback via Controlled Tooltiå
 *
 * @param {number} [time=1500]
 * @return {*}
 */
const useTimedUserFeedback = (time = 1500) => {
  const [userFeedback, setUserFeedback] = React.useState({
    show: false,
    referenceId: '',
  });
  React.useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (userFeedback.show) {
      timer = setTimeout(() => {
        setUserFeedback({
          ...userFeedback,
          show: false,
        });
      }, time);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [time, userFeedback]);

  return { userFeedback, setUserFeedback };
};
