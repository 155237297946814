import * as React from 'react';
import { Box, MotionContainer } from '@mentimeter/ragnar-ui';
import { type SpringValues, useTransition, animated } from '@react-spring/web';
import { PaperPlane } from '@mentimeter/ragnar-visuals';

export const Feedback = ({ children }: { children: React.ReactNode }) => {
  const transitions = useTransition(Boolean(children), {
    from: { opacity: 0, transform: 'translateX(20%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0 },
    delay: 100,
  });

  return (
    <>
      {transitions((styles) => (
        <Box
          as={animated.div}
          width="100%"
          zIndex={2}
          style={styles as SpringValues}
        >
          {children}
        </Box>
      ))}
      <Box alignItems="center" width="100%" my="space12">
        <MotionContainer animation="fade">
          <Box height="112px">
            <PaperPlane />
          </Box>
        </MotionContainer>
      </Box>
    </>
  );
};
