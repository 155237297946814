import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type {
  AudienceQfa,
  AudienceQfaQuestion,
} from '@mentimeter/http-clients';
import { trackEvent } from '../../utils/tracker';
import { getAudienceQuestions, submitQuestion } from '../../api/qfa';
import type { RootState } from '../../redux-store';

const INITIAL_PAGE_SIZE = 30;

// To prevent spam
const SUBMIT_TRIES = 3;
const SUBMIT_DELAY = 30 * 1000;
const PUNISHMENT = 60 * 1 * 1000;

export interface QfaState {
  error: string | null;
  loading: boolean;
  hasNextPage?: boolean;
  pageSize: number;
  questions: AudienceQfaQuestion[] | undefined;
  shouldDoLiveUpdate: boolean;
  sortByUpvote: boolean;
  userQuestions: number[];
  isQfaPanelOpen: boolean;

  // Spam
  punishment: number;
  submissions: number;
  submitTimestamp: number;
}

const initialState: QfaState = {
  error: null,
  loading: false,
  hasNextPage: false,
  pageSize: INITIAL_PAGE_SIZE,
  questions: undefined,
  shouldDoLiveUpdate: true,
  sortByUpvote: false,
  userQuestions: [],
  isQfaPanelOpen: false,

  // Spam
  punishment: 0,
  submissions: 0,
  submitTimestamp: 0,
};

interface SubmitQuestionBody {
  voteKey: string;
  question: string;
  askedOnQuestionKey: string | undefined;
}

export const submitAudienceQuestion = createAsyncThunk<
  AudienceQfaQuestion,
  SubmitQuestionBody,
  { rejectValue: string }
>(
  'qfa/submitAudienceQuestion',
  async ({ voteKey, question, askedOnQuestionKey }, { rejectWithValue }) => {
    try {
      const response = await submitQuestion(voteKey, {
        question,
        ...(askedOnQuestionKey && {
          asked_on_question_key: askedOnQuestionKey,
        }),
      });
      if (response.status === 201) {
        trackEvent('QFA', 'Entered QFA');
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

export const fetchAudienceQuestions = createAsyncThunk<
  AudienceQfa,
  undefined,
  { rejectValue: string }
>('qfa/fetchAudienceQuestions', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState;
  const { sortByUpvote } = state.qfa;
  const voteKey = state.series.vote_key;
  try {
    const { data } = await getAudienceQuestions(voteKey, sortByUpvote);
    return data;
  } catch (err: any) {
    return rejectWithValue(err.message);
  }
});

export const fetchNextAudienceQuestionsPage = createAsyncThunk<
  AudienceQfa,
  undefined,
  { rejectValue: string }
>(
  'qfa/fetchNextAudienceQuestionsPage',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const voteKey = state.series.vote_key;
    const { pageSize, questions = [] } = state.qfa;
    const page = Math.ceil(questions.length / pageSize + 1);
    try {
      const { data } = await getAudienceQuestions(voteKey, false, page);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

export const qfaSlice = createSlice({
  name: 'qfa',
  initialState,
  reducers: {
    updateUpvotes(state, action) {
      const { id, upvotes } = action.payload;
      const question = state.questions?.find((q) => q.id === id);
      if (question) question.upvotes = upvotes;
    },
    updateUpvote(state, action) {
      const { id, isUpvoted } = action.payload;
      const question = state.questions?.find((q) => q.id === id);
      if (question) question.upvotes += isUpvoted ? 1 : -1;
    },
    setSortByUpvote(state, action) {
      state.sortByUpvote = action.payload;
    },
    openQfaPanel(state) {
      state.isQfaPanelOpen = true;
    },
    closeQfaPanel(state) {
      state.isQfaPanelOpen = false;
    },
  },
  extraReducers: (builder) => {
    // Submit audience question
    builder.addCase(submitAudienceQuestion.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(submitAudienceQuestion.rejected, (state, action) => {
      state.error = action.payload ?? null;
      state.loading = false;
    });
    builder.addCase(
      submitAudienceQuestion.fulfilled,
      (state, { payload: question }) => {
        if (state.questions === undefined) state.questions = [];
        state.questions.push(question);
        state.userQuestions.push(question.id);

        // Change the order to be able to see your new post
        const currentTime = Date.now();
        let punishment = 0;
        let submissions = 0;

        // If no punishment or expired
        if (state.punishment === 0 || state.punishment < currentTime) {
          const diff = currentTime - state.submitTimestamp;
          const shouldReset =
            state.submitTimestamp !== 0 && diff > SUBMIT_DELAY;
          const isSpamming =
            diff <= SUBMIT_DELAY && state.submissions >= SUBMIT_TRIES - 1;

          // If was not spamming but now are => set punishment
          punishment = isSpamming ? currentTime + PUNISHMENT : 0;
          submissions = shouldReset ? 1 : state.submissions + 1;

          // Update timestamp to now if not within the spam threshold
          if (diff > SUBMIT_DELAY) state.submitTimestamp = currentTime;
        }

        state.punishment = punishment;
        state.submissions = submissions;

        // Only live if we are not paged
        state.shouldDoLiveUpdate = state.questions.length <= state.pageSize;
        state.loading = false;
      },
    );

    // Fetch audience questions
    builder.addCase(fetchAudienceQuestions.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(fetchAudienceQuestions.rejected, (state, action) => {
      state.error = action.payload ?? null;
      state.loading = false;
    });
    builder.addCase(fetchAudienceQuestions.fulfilled, (state, { payload }) => {
      const { data, page_size, next_page } = payload;

      // Filter out the profanities when filter is on
      const filteredData = data.filter((question) => !question.is_profanity);

      state.questions = filteredData;
      state.pageSize = page_size || state.pageSize;
      state.hasNextPage = Boolean(next_page) || false;
      state.error = null;
      state.loading = false;
      state.shouldDoLiveUpdate = true;
    });

    // Fetch next audience questions page
    builder.addCase(fetchNextAudienceQuestionsPage.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(
      fetchNextAudienceQuestionsPage.rejected,
      (state, action) => {
        state.error = action.payload ?? null;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchNextAudienceQuestionsPage.fulfilled,
      (state, action) => {
        const { data, next_page } = action.payload;

        // Filter out the profanities when filter is on
        const filteredData = data.filter((question) => !question.is_profanity);

        state.questions = state.questions
          ? [...state.questions, ...filteredData]
          : filteredData;

        state.hasNextPage = Boolean(next_page);
        state.error = null;
        state.loading = false;
        state.shouldDoLiveUpdate = false;
      },
    );
  },
});

export const {
  updateUpvotes,
  updateUpvote,
  setSortByUpvote,
  openQfaPanel,
  closeQfaPanel,
} = qfaSlice.actions;
