export function getNameInitials(name: string, length: number = 2) {
  return name
    .split(' ')
    .filter((word) => word.length > 0)
    .slice(0, length)
    .map((word) =>
      String.fromCodePoint(word.codePointAt(0)!).toLocaleUpperCase(),
    )
    .join('');
}
