import { usePresentation } from './state';
import type { PresentationStore } from './types';

export function usePresentationReadOnly<T>(
  selector: (state: {
    state: PresentationStore['state'];
    selectors: PresentationStore['selectors'];
  }) => T,
) {
  return usePresentation(({ state, selectors }) =>
    selector({ state, selectors }),
  );
}
