import React, { type ComponentProps } from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';

const FelaBackdrop = styled('div', {
  displayName: 'Backdrop',
})();

type BackdropT = ComponentProps<typeof FelaBackdrop>;

export const Backdrop = React.forwardRef((props: BackdropT, ref) => {
  const { className, ...rest } = props;
  const classes = 'bg-overlay fixed top-0 right-0 bottom-0 left-0';

  return (
    <FelaBackdrop ref={ref} {...rest} className={cn(classes, className)} />
  );
});
