import { Box } from '@mentimeter/ragnar-ui';
import * as React from 'react';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { IdentificationScreen } from './IdentificationScreen';
import { IdentificationForm } from './IdentificationForm';
import { useIdentity } from './useIdentity';
import { Error } from './Error';
import { WelcomeBack } from './WelcomeBack';

export const IdentificationContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { identity, submitName } = useIdentity();

  if (identity.status === 'loading') {
    return (
      <Box
        flex="1"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <LoaderIcon />
      </Box>
    );
  }

  if (identity.status === 'error') {
    return <Error />;
  }

  if (identity.status === 'needs_identification') {
    return (
      <IdentificationScreen>
        <IdentificationForm onSubmit={submitName} />
      </IdentificationScreen>
    );
  }

  if (identity.status === 'needs_to_see_welcome_back_screen') {
    return (
      <WelcomeBack name={identity.name} onContinue={identity.onContinue} />
    );
  }

  return children;
};
