import React, { useCallback, useEffect, useState } from 'react';
import { Button, Text } from '@mentimeter/ragnar-ui';
import Cookies, { cookiesAreEnabled } from '@mentimeter/cookies';
import type { AudienceQfaQuestion } from '@mentimeter/http-clients';
import { useTranslate } from '../localization/Translate';
import { trackEvent } from '../../utils/tracker';
import * as qfaApi from '../../api/qfa';
import { QFA_COOKIE_NAME } from '../../constants/cookieConstants';
import { QfaItem } from './QfaItem';

type Upvotes = Record<number, boolean>;

export const QfaQuestions = ({
  questions,
  hasNextPage,
  sortByUpvote,
  userQuestions,
  qfaIntercomEnabled,
  onLoadMore,
  updateUpvoteForQuestion,
}: {
  questions: AudienceQfaQuestion[];
  hasNextPage: boolean;
  sortByUpvote: boolean;
  userQuestions: number[];
  qfaIntercomEnabled: boolean;
  onLoadMore: () => void;
  updateUpvoteForQuestion: (id: number, isUpvoted: boolean) => void;
}) => {
  const translate = useTranslate();

  const [userUpvotedQuestionIds, setUserUpvotedQuestionIds] = useState(
    (cookiesAreEnabled() && Cookies.getJSON<Upvotes>(QFA_COOKIE_NAME)) || {},
  );

  useEffect(() => {
    if (cookiesAreEnabled())
      Cookies.setJSON<Upvotes>({
        type: 'functional',
        name: QFA_COOKIE_NAME,
        value: userUpvotedQuestionIds,
      });
  }, [userUpvotedQuestionIds]);

  const toggleUpvote = useCallback(
    async (id: number) => {
      const wasUpvoted = userUpvotedQuestionIds[id];
      const isUpvote = !wasUpvoted;

      try {
        updateUpvoteForQuestion(id, isUpvote);
        setUserUpvotedQuestionIds((current) => ({
          ...current,
          [id]: isUpvote,
        }));

        if (isUpvote) {
          await qfaApi.submitUpvote(id);
          trackEvent('QFA', 'Upvoted on QFA');
        } else {
          await qfaApi.undoUpvote(id);
          trackEvent('QFA', 'Undid upvote on QFA');
        }
      } catch {
        // rollback optimistic updates
        updateUpvoteForQuestion(id, !isUpvote);
        setUserUpvotedQuestionIds((current) => {
          if (wasUpvoted === undefined) {
            const next = { ...current };
            delete next[id];
            return next;
          }
          return { ...current, [id]: wasUpvoted };
        });
      }
    },
    [updateUpvoteForQuestion, userUpvotedQuestionIds],
  );

  const onlyUserQuestions = questions.filter((element) =>
    userQuestions.includes(element.id),
  );
  const visibleQuestions = qfaIntercomEnabled ? questions : onlyUserQuestions;

  if (visibleQuestions.length === 0) {
    return <EmptyState qfaIntercomEnabled={qfaIntercomEnabled} />;
  }

  return (
    <>
      {visibleQuestions.map((q, index) => {
        return (
          <QfaItem
            key={q.id}
            index={index}
            question={q}
            onToggleUpvote={toggleUpvote}
            hasUpvoted={Boolean(userUpvotedQuestionIds[q.id])}
            isUserQuestion={userQuestions.includes(q.id)}
          />
        );
      })}

      {hasNextPage &&
        (sortByUpvote ? (
          <Text>{translate('qfa.only_top_questions')}</Text>
        ) : (
          <Button
            size="large"
            aria-label="Load more questions"
            onClick={onLoadMore}
          >
            Load more
          </Button>
        ))}
    </>
  );
};

const EmptyState = ({
  qfaIntercomEnabled,
}: {
  qfaIntercomEnabled: boolean;
}) => {
  const translate = useTranslate();

  return (
    <Text lineHeight="relaxed" px="space4">
      {!qfaIntercomEnabled
        ? translate('qfa.your_questions_helptext')
        : translate('qfa.do_have_question')}
    </Text>
  );
};
