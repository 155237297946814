import type {
  JoinedPresentationProperties,
  Series,
} from '@mentimeter/http-clients';

interface Props {
  source: string | null | undefined;
  voteKey: string;
  participationIdentityMode: Series['participation_identity_mode'];
}

const QR_JOIN_CONTEXT: Record<string, string> = {
  'qr-instructions-overlay': 'instructions overlay',
  'qr-instructions-widget': 'instructions widget',
  'qr-instructions-slide': 'instructions slide',
  'qr-page': 'qr code page',
};

const SOURCE_TO_JOIN_MODE: Record<
  string,
  JoinedPresentationProperties['join mode']
> = {
  'qr-instructions-overlay': 'qrcode',
  'qr-instructions-widget': 'qrcode',
  'qr-instructions-slide': 'qrcode',
  'qr-page': 'qrcode',
  voteCode: 'votecode',
};

export const getJoinedPresTrackProps = ({
  source,
  voteKey,
  participationIdentityMode,
}: Props): JoinedPresentationProperties => {
  const joinMode = SOURCE_TO_JOIN_MODE[source ?? ''] || 'link';

  if (joinMode === 'qrcode') {
    return {
      'join mode': 'qrcode',
      'vote key': voteKey,
      'participation identity mode': participationIdentityMode,
      context: QR_JOIN_CONTEXT[source!] ?? 'unknown',
    };
  }

  return {
    'join mode': joinMode,
    'vote key': voteKey,
    'participation identity mode': participationIdentityMode,
  };
};
