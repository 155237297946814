import * as React from 'react';
import { BottomPanel } from '@mentimeter/voting-ui';
import { withTheme, type ThemeProps } from '@mentimeter/ragnar-react';
import { CfaButton } from './CfaButton';
import { CfaForm } from './CfaForm';

export interface CfaProps extends ThemeProps {
  absolute?: boolean;
  activePublicKey: string;
  Trigger?: React.ComponentType<{ onClick: () => void }>;
}

const CfaWithTheme = ({
  absolute = false,
  activePublicKey,
  Trigger = CfaButton,
  theme,
}: CfaProps) => {
  const [show, setShow] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setShow(true);
  }, [setShow]);

  const handleClose = React.useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <>
      <Trigger onClick={handleOpen} />
      <BottomPanel
        absolute={absolute}
        show={show}
        onDismiss={handleClose}
        themeBg={theme.colors.bg}
      >
        <CfaForm resetState={handleClose} activePublicKey={activePublicKey} />
      </BottomPanel>
    </>
  );
};

export const Cfa = withTheme(CfaWithTheme);
