import React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';

export const LoadingPlaceholder = () => {
  return (
    <Box
      width="100%"
      height="100%"
      flex="1 1 auto"
      justifyContent="center"
      alignItems="center"
    >
      <LoaderIcon />
    </Box>
  );
};
