export type SimpleInlineNodesT =
  | 'emphasis'
  | 'strong'
  | 'code'
  | 'delete'
  | 'link';

export const getInlineNodeSyntax = (
  node: SimpleInlineNodesT,
): [string, string] => {
  switch (node) {
    case 'emphasis':
      return ['*', '*'];
    case 'strong':
      return ['**', '**'];
    case 'code':
      return ['`', '`'];
    case 'delete':
      return ['~~', '~~'];
    case 'link':
      return ['[', '](link)'];
    default:
      return ['', ''];
  }
};
