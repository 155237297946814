import * as React from 'react';
import { alpha } from '@mentimeter/ragnar-colors';
import { Svg } from '../../shared-components';
import { COLOR_MAP } from '../colorMap';
import type { Pictogram } from '../types';

export const Qa = ({ userColor, variant = 'blue' }: Pictogram) => {
  return (
    <Svg viewBox="0 0 112 120" aria-hidden="true" className="light">
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M51.013 93.31c3.947 12.849 15.91 22.19 30.053 22.19H112.5V84.066c0-14.144-9.341-26.105-22.191-30.053-2.563 20.492-18.804 36.733-39.296 39.296Z"
          fill={
            userColor
              ? userColor && alpha(userColor, 0.5)
              : COLOR_MAP[variant].pictogramTertiary
          }
        />
        <path
          d="M49.632 84.066c0-17.36 14.074-31.434 31.434-31.434 3.217 0 6.32.484 9.243 1.381a45.089 45.089 0 0 1-8.156 20.763c-7.185 9.987-18.332 16.931-31.14 18.533a31.481 31.481 0 0 1-1.38-9.243Z"
          fill={userColor ? userColor : COLOR_MAP[variant].pictogramSecondary}
        />
        <path
          d="M90.662 48.33c0 1.925-.12 3.822-.353 5.683a31.429 31.429 0 0 0-9.243-1.38c-13.02 0-24.192 7.916-28.963 19.198a31.334 31.334 0 0 0-2.47 12.235 31.397 31.397 0 0 0 1.38 9.243 45.773 45.773 0 0 1-5.682.353H0V48.33C0 23.295 20.295 3 45.33 3c25.036 0 45.332 20.294 45.332 45.33Z"
          fill={
            userColor
              ? userColor && alpha(userColor, 0.75)
              : COLOR_MAP[variant].pictogramPrimary
          }
        />
      </g>
    </Svg>
  );
};
