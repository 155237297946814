import React from 'react';
import { addListener } from '@reduxjs/toolkit';
import { useAppDispatch } from '../redux-hooks';
import { submitVote } from './series';

export const useOnHasFinishedVotingOnSlide = (
  onHasFinishedVoting: (questionId: string, partial: boolean) => void,
) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        actionCreator: submitVote.fulfilled,
        effect: ({ payload }) => {
          onHasFinishedVoting(payload.questionId, payload.partial);
        },
      }),
    );
    return unsubscribe;
  }, [dispatch, onHasFinishedVoting]);
};
