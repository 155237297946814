import * as React from 'react';
import { alpha } from '@mentimeter/ragnar-colors';
import { Svg } from '../../shared-components';
import { COLOR_MAP } from '../colorMap';
import type { Pictogram } from '../types';

export const SpeechBubblePen = ({ userColor, variant = 'red' }: Pictogram) => {
  return (
    <Svg viewBox="0 0 112 120" aria-hidden="true" className="light">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.16 13.68C22.907 13.68 0 36.585 0 64.84v51.161h51.16c28.256 0 51.162-22.905 51.162-51.16 0-28.256-22.906-51.161-51.161-51.161ZM73.4 18.754A51.372 51.372 0 0 1 97.25 42.608L63.956 75.905H40.102V52.053l33.297-33.3Z"
        fill={
          userColor
            ? userColor && alpha(userColor, 0.5)
            : COLOR_MAP[variant].pictogramTertiary
        }
      />
      <path
        d="M97.251 42.608a51.371 51.371 0 0 0-23.852-23.854L40.102 52.053v23.852h23.854l33.295-33.297Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramSecondary}
      />
      <path
        d="M73.398 18.752 88.148 4l23.855 23.852L97.25 42.606a51.371 51.371 0 0 0-23.852-23.854Z"
        fill={
          userColor
            ? userColor && alpha(userColor, 0.75)
            : COLOR_MAP[variant].pictogramPrimary
        }
      />
    </Svg>
  );
};
