import type { DeepPartial } from '@mentimeter/ts-utils';
import type Ably from 'ably';
import type {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

export type Regions = 'eu' | 'us';

export function isRegion(region: string | undefined): region is Regions {
  return region === 'eu' || region === 'us';
}

export interface ServiceUrls {
  core: string;
  quiz: string;
  blab: string;
}

/**
 * Response interface received when requesting what region a user is tied to.
 */
export interface RegionResponse {
  /**
   * The region tied to the email sent along with the request body.
   */
  region: Regions;
  /**
   * @param exists - determines if a user exists with the given email or not.
   */
  exists: boolean;
  /**
   * Base url for the server of the returned region
   */
  service_urls: ServiceUrls;
}

export interface ImportPreflightResponse {
  url: string;
  fields: Record<string, string>;
  host_url: string;
}

export interface ImportPostResponse {
  id: string;
}

export interface ConversionInfoT {
  id: string;
  input: { ext: any };
  output: { files: string[]; dir: string; filename: string };
}

export interface MediaParams {
  natural_height: number;
  natural_width: number;
  height: number;
  width: number;
  x: number;
  y: number;
}

export interface QuizPlayerPostData {
  marked_inappropriate?: boolean;
  marked_correct?: boolean;
  identifiers?: string[];
}

export interface ImageResourcePreset {
  url: string;
  width?: number;
  height?: number;
}

type ImagePresetKeys =
  | 'source'
  | 'original'
  | 'preview'
  | 'small'
  | 'medium'
  | 'large'
  | 'huge';

type ImageResourcePresets = Record<ImagePresetKeys, ImageResourcePreset>;

export interface ImageResource {
  _object: string;
  _url: string;
  id: string;
  url: string;
  alt_description: string | null;
  presets: ImageResourcePresets;
  crop: MediaParams | null;
  original_height?: number;
  original_width?: number;
  s3_key?: string;
}

/*
 * Reaction
 */
export type Reaction =
  | 'heart1'
  | 'cat'
  | 'thumbsup'
  | 'thumbsdown'
  | 'question';

export type CampaignResponseT = ApiObjectT<{
  id: string;
  name: string;
  plan: string;
  data: { licenses: number; days: number };
}>;

export type CampaignErrorT = AxiosError<{
  code: string;
  data: Record<string, unknown>;
  message: string;
  status: number;
}>;

export interface DuplicateSeriesPayload {
  copy: string;
  with_folder?: boolean;
  tags?: Array<string>;
  region?: Regions | undefined;
  track_context?: string | undefined;
  client?: string;
}

export type DuplicateSeries = (
  data: DuplicateSeriesPayload,
  config?: AxiosRequestConfig<DuplicateSeriesPayload>,
) => AxiosPromise<Series>;

export interface CopyQuestion {
  copy: string;
  copy_to_index?: number;
}

export interface DuplicateQuestionsPayload {
  questions: CopyQuestion[];
  region?: Regions | undefined;
  track_context?: string | undefined;
  client?: string | undefined;
  device_id?: string | undefined;
}

export type DuplicateQuestions = (
  id: string,
  data: DuplicateQuestionsPayload,
  config?: AxiosRequestConfig<DuplicateQuestionsPayload>,
) => AxiosPromise<Question[]>;

export type FolderId = number | null;

export interface ModuleT {
  id: ModuleId;
  name: string;
  type: QuestionType;
  description: {
    full: string;
    short: string;
  };
  developer?: {
    name: string;
    urls: {
      website: string;
      terms_of_use: string;
      privacy: string;
    };
  };
  assets?: {
    icon?: string;
    preview?: string;
    screenshots?: Array<string>;
  };
}

/*
 * Theme Types
 */
export type ThemeColor = string;

export interface ThemeFont {
  name: string;
  key: string;
  body: string;
  heading?: string;
}

export type ConfigIdT =
  | 'menti-brand'
  | 'city'
  | 'monochrome'
  | 'particle-blue'
  | 'explore'
  | 'waves'
  | 'colorjoy'
  | 'barbershop'
  | 'greenhouse'
  | 'space'
  | 'nature'
  | 'gradient'
  | 'spark44'
  | 'waves-blue'
  | 'waves-purple'
  | 'business-light'
  | 'business-dark';

export interface Theme {
  logo?: ImageResource;
  logo_url: string;
  logo_cropped_image_url?: string | undefined;
  background_url: string;
  background_cropped_image_url?: string | undefined;
  background_escaped_path?: string;
  logo_escaped_path?: string;
  background_media_params?: MediaParams;
  logo_media_params?: MediaParams;
  bar_color: Array<ThemeColor>;
  bar_text_color: Array<ThemeColor>;
  background_color: ThemeColor;
  line_color: ThemeColor;
  text_color: ThemeColor;
  font: ThemeFont;
  backdrop_alpha: number;
  config_id: ConfigIdT;
  is_public: boolean;
}

/*
 * Question
 */
export type QuestionType =
  | 'choices'
  | 'choices_images'
  | 'eurovision'
  | 'free-text'
  | 'open'
  | 'slide'
  | 'pin_on_image'
  | 'prioritisation'
  | 'qfa'
  | 'quiz'
  | 'quiz_open'
  | 'quiz_leaderboard'
  | 'ranking'
  | 'rating'
  | 'scales'
  | 'winner'
  | 'wordcloud'
  | 'metadata'
  | 'instructions'
  | 'miro';

export type QuestionSubType =
  | null
  | 'bullets'
  | 'cards'
  | 'choices'
  | 'cloud'
  | 'donut'
  | 'dots'
  | 'flow'
  | 'four-values'
  | 'horizontal'
  | 'onebyone'
  | 'open'
  | 'pie'
  | 'scales'
  | 'image'
  | 'spider'
  | 'two-values';

export interface QuestionChoiceImage {
  image?: ImageResource | null;
  cropped_image_url?: string | undefined;
  image_path?: string | undefined;
  image_url?: string | undefined;
  image_alt_description?: string;
  image_escaped_path?: string | undefined;
  media_params?: MediaParams | undefined;
}

export interface LanguageT {
  common: Record<string, string>;
  content: Record<string, string>;
  presentation: Record<string, string>;
  voting: Record<string, string>;
  controls: Record<string, string>;
}

export enum QuestionModuleCode {
  Presentation = 'presentation',
  Content = 'content',
  Voting = 'voting',
  Controls = 'controls',
}

interface Properties {
  [key: string]: {
    description?: string;
    type?: string;
    items?: Record<string, unknown>;
    properties?: Properties;
    default?: string | Array<any> | Record<string, unknown> | number | boolean;
    'x-reset-to-default'?: boolean;
    required?: string[];
    additionalProperties?: boolean;
  };
}

export interface QuestionModuleManifestT {
  deployment: string;
  description: {
    full: string;
    short: string;
  };
  id: string;
  name: string;
  properties: Properties;
  type: QuestionType;
}

export interface BadRequest {
  message: string;
  status: number;
  code: string;
  extra: unknown;
}

export interface Unauthorized {
  message: string;
  status: number;
  code: string;
  extra: unknown;
}

export interface AxiosErrorToJSON {
  config: Pick<
    AxiosRequestConfig,
    | 'adapter'
    | 'baseURL'
    | 'data'
    | 'headers'
    | 'maxBodyLength'
    | 'maxContentLength'
    | 'method'
    | 'timeout'
    | 'transformRequest'
    | 'transformResponse'
    | 'transitional'
    | 'url'
    | 'validateStatus'
    | 'xsrfCookieName'
    | 'xsrfHeaderName'
  >;
  message: string;
  name: string;
  stack: string;
  status: number;
  response?: AxiosResponse;
}

export interface FontT {
  body: string;
  key: string;
  name: string;
}

/*
 * Series
 */
export type PaceMode = 'audience' | 'presenter';

export interface Pace {
  state: 'idle' | 'finished';
  mode: PaceMode;
  /**
   * @deprecated use value returned from new presentation state
   */
  active: string;
  counter: number;
}

export type ParticipationIdentityMode = 'anonymous' | 'identified';

export interface TemplateData {
  published?: boolean;
  action_title?: string;
  description?: string;
  tags?: string[];
  category?: string;
  created_at?: string;
  id?: number;
  series_id?: string;
  updated_at?: string;
  questions?: Question[];
  use_for_add_slide_popover?: boolean;
}

export interface QuestionExample {
  dummy_quiz_result: QuizPlayerScore[];
  dummy_results: SessionResult;
  dummy_qfa_result: Qfa;
  id: string;
  question: Question;
  title: string;
}

export interface ThemeSettingsUpdateHashT {
  background_color: string;
  background_url?: string | null | undefined;
  background_media_params?: MediaParams | null | undefined;
  background_escaped_path?: string | null | undefined;
  background_path?: string | null | undefined;
  backdrop_alpha: number;
  bar_color: Array<string>;
  bar_text_color: Array<string>;
  is_public?: boolean | null | undefined;
  line_color: string;
  logo_url?: string | null | undefined;
  logo_media_params?: MediaParams | null | undefined;
  logo_escaped_path?: string | null | undefined;
  logo_path?: string | null | undefined;
  text_color: string;
  config_id: string;
  font?: FontT | null | undefined;
}

export interface ThemeUpdateHashT {
  id?: string | null | undefined;
  name?: string | null | undefined;
  editable?: boolean | null | undefined;
  public?: boolean | null | undefined;
  owner?: boolean | null | undefined;
  owner_name?: string | null | undefined;
  shared_with_workspace?: boolean | null | undefined;
  pro_theme?: boolean | null | undefined;
  settings?: ThemeSettingsUpdateHashT | null | undefined;
  shared_with_groups?: Array<string>;
}

export interface ThemeSettingsT {
  background_color: string;
  background_url?: string;
  background_media_params?: MediaParams | null | undefined;
  background_escaped_path?: string;
  background_cropped_image_url?: string | null;
  background_path?: string;
  backdrop_alpha: number;
  bar_color: Array<string>;
  bar_text_color: Array<string>;
  is_public?: boolean;
  line_color: string;
  logo_url?: string;
  logo_media_params?: MediaParams | null | undefined;
  logo_escaped_path?: string;
  logo_cropped_image_url?: string;
  logo_path?: string;
  text_color: string;
  config_id: string;
  font?: FontT;
}

export interface ThemeResponseT {
  id: number;
  name: string;
  unwantedProp: string;
  disabled: boolean;
  editable: boolean;
  owner: boolean;
  owner_name: string;
  pro_theme: boolean;
  public: boolean;
  requires_features: boolean;
  settings: ThemeSettingsT;
  shared_with_workspace: boolean;
  user_is_admin_of_workspace: boolean;
  shared_with_groups: Array<string>;
}

export type ThemesResponseT = Array<ThemeResponseT>;

export interface FolderResponseSnakeCase {
  id: number;
  name: string;
  parent_id: number | null;
  created_at: string;
  updated_at: string;
  owner_id: number;
  owner_display_name: string;
  workspace_id: number;
  presentations_count: number;
  resource_sharing_id: string | null;
  shared: boolean;
}

export interface FolderResponse {
  id: number;
  name: string;
  parentId: number | null;
  createdAt: string;
  updatedAt: string;
  ownerId: number;
  ownerDisplayName: string;
  workspaceId: number | null;
  presentationsCount: number;
  resourceSharingId: string | null;
  shared: boolean;
}

export interface BlacklistedAnswersResponse {
  results: Array<{
    question: string;
    createdAt: number;
  }>;
}

/*
 * Questions from Audience
 */
export interface Qfa {
  sort_by_upvote: boolean;
  total_count: number;
  current_page: number;
  page_size: number;
  next_page: null | string;
  data: Array<QfaQuestion>;
}

export interface QfaQuestion {
  id: number;
  question: string;
  session: number;
  created_at: string;
  archived_at: null | string;
  accepted_at: null | string;
  rejected_at: null | string;
  bucket?: 'archived' | 'accepted' | 'rejected' | 'pending';
  upvotes: number;
  is_profanity: boolean;
}

export interface QuestionChoice extends QuestionChoiceImage {
  id: number;
  label: string;
  position: number;
  correct_answer: boolean;
  delete?: true | undefined;
}

export interface QuestionGrid {
  id: string | number;
  bright: string;
  dark: string;
  name: string;
  type: 'default' | 'custom';
  editable: boolean;
  rotation?: 0 | 90 | 180 | 270 | 360;
  url?: string;
  cropped_image_url?: string | null;
  media_params?: MediaParams;
  altText?: string;
  image_alt_description?: string;
}

export interface QuestionRange {
  min: number;
  max: number;
}

export interface QuestionDimension {
  label?: string | null;
  min: string;
  max: string;
  mid_values?: Array<string>;
}

export type QuestionFieldType =
  | 'text'
  | 'email'
  | 'checkbox'
  | 'dropdown'
  | 'date';

export interface QuestionField {
  id: number;
  label: string;
  type: QuestionFieldType;
  options: Array<string>;
  position: number;
  is_required: boolean;
  delete?: true | undefined;
}

export type QuestionSlideImageType = 'image-frame' | 'image-background';

export type QuestionResult = Record<string, any> | string | number;

export interface QuizPlayerScore {
  player: {
    identifier: string;
    name: string | null;
    emojiShortname: string;
    emojiName: string;
    index: number;
  };
  score: {
    question?: {
      choiceId?: number;
      correctAnswer?: boolean;
      markedCorrect?: boolean;
      markedInappropriate?: boolean;
      score: number;
      answer: string;
    };
    total: {
      totalScore: number;
      position: number;
    };
    leaderboard?: {
      score: number;
    };
  };
}

export interface QuizResult {
  question_id: string;
  choice_id: number;
  responses: number;
}

export type QuestionSlideType =
  | 'big'
  | 'bullets'
  | 'bullets-image' // legacy
  | 'document'
  | 'free-text'
  | 'google-slides'
  | 'heading'
  | 'image'
  | 'image-background'
  | 'image-frame'
  | 'images'
  | 'instructions'
  | 'message' // legacy
  | 'miro'
  | 'number'
  | 'paragraph'
  | 'powerpoint'
  | 'quote'
  | 'slide'
  | 'slide-image'
  | 'video';

export type QuestionDate = string;

export interface Layout {
  alignment_x: 0 | 1 | 2;
  alignment_y: 0 | 1 | 2;
  font_size_offset: -2 | -1 | 0 | 1 | 2;
  size: '0%' | '10%' | '25%' | '33%' | '50%' | '66%';
  type:
    | 'default'
    | 'full-bg'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'side-by-side'
    | 'side-by-side-right';
}

export const VALID_MODULE_IDS = [
  '100-points',
  'big',
  'bullets',
  'choices',
  'deprecated',
  'document',
  'free-text',
  'google-slides',
  'heading',
  'image',
  'instructions',
  'metadata',
  'miro',
  'number',
  'numerical-question',
  'open',
  'paragraph',
  'pin-on-image',
  'powerpoint',
  'qfa',
  'quiz-choices',
  'quiz-leaderboard',
  'quiz-open',
  'quote',
  'ranking',
  'rating',
  'scales',
  'video',
  'winner',
  'wordcloud',
] as const;

export type ValidModuleId = (typeof VALID_MODULE_IDS)[number];

export const DEPRECATED_MODULE_ID = [
  'arrows',
  'breathe',
  'budget',
  'code',
  'drum-roll',
  'eurovision',
  'figma',
  'funnel',
  'hub',
  'line-chart',
  'loop',
  'match-the-pairs',
  'nps',
  'slide',
  'spin-the-wheel',
  'truth-or-lie',
  'the-team',
  'this-or-that',
  'time-keep',
  'timeline',
  'trafficlights',
  'ui-samples',
  'venn',
] as const;

export type DeprecatedModuleId = (typeof DEPRECATED_MODULE_ID)[number];

export type ModuleId = 'deprecated' | ValidModuleId | DeprecatedModuleId;

export interface Question {
  id: string;
  series_id?: string;
  /**
   * @deprecated This is a multi-mapped property depending on slide type/module id. All `*type` attributes have been aggregated into `StaticContent.type` and/or `InteractiveContent.styling.type`
   * together with `InteractiveContent.styling.visualization` depending on if there is any interactive content or not.
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent.type} for more information.
   */
  type: QuestionType;
  slug: string;
  active: boolean;
  /**
   * @deprecated This is a multi-mapped property depending on slide type/module id. Most commonly mapped to `InteractiveContent.title` or `*StaticContent.heading|message` but usage may vary.
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent} or {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent} for more information.
   */
  question: string;
  /**
   * @deprecated For `free-text`, use `StaticContent.formattedBody` instead. For other types on interactive slides, use `InteractiveContent.styling.styledAttributes.[property]`.
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent} or {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent} for more information.
   */
  question_styled: Record<string, unknown>;
  /**
   * @deprecated Use `InteractiveContent.choices` instead.
   *
   * -----
   * Refer to the  {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.choices} for more information.
   */
  choices: Array<QuestionChoice>;
  /**
   * @deprecated Use `InteractiveContent.choices` instead.
   *
   * -----
   * Refer to the  {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.choices} for more information.
   */
  fields: Array<QuestionField>;
  /**
   * @deprecated This is a multi-mapped property depending on slide type/module id. All `*type` attributes have been aggregated into `StaticContent.type` and/or `InteractiveContent.styling.type`
   * together with `InteractiveContent.styling.visualization` depending on if there is any interactive content or not.
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.styling.visualization} for more information.
   */
  sub_type: QuestionSubType;
  /**
   * @deprecated This is a multi-mapped property depending on slide type/module id. All `*type` attributes have been aggregated into `StaticContent.type` and/or `InteractiveContent.styling.type`
   * together with `InteractiveContent.styling.visualization` depending on if there is any interactive content or not.
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide} for more information.
   */
  slide_type: QuestionSlideType;
  updated_at: QuestionDate;
  empty_result: null;
  result: SessionResult;
  do_not_use_id: number;
  /**
   * @deprecated Use `InteractiveContent.countdown` instead.
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.countdown} for more information.
   */
  countdown_to_vote: number;
  /**
   * @deprecated Not used anymore.
   *
   * -----
   * Refer to the {@link https://tinyurl.com/menti-domain-model-2024 migration sheet} for more information.
   */
  update_vote_active: boolean;
  /**
   * @deprecated This is a multi-mapped property depending on slide type/module id. Most commonly mapped to `InteractiveContent.description` or `*StaticContent.body|subheading` but usage may vary.
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent} or {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent} for more information.
   */
  question_description: string;
  /**
   * @deprecated Use `InteractiveContent.styling.styledAttributes.[property]` instead. `property` refers to the unstyled attribute name, e.g. `title` or similar
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent} for more information.
   */
  question_description_styled: Record<string, unknown>;
  title_meta: string;
  /**
   * @deprecated Use `Slide.speakerNotes` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide} for more information.
   */
  speaker_notes: string | null | undefined;
  /**
   * @deprecated Use `InteractiveContent.styling.xAxisLabels|yAxisLabels` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.styling} for more information.
   */
  dimensions: [QuestionDimension, QuestionDimension];
  /**
   * @deprecated Use `Slide.legacyQuestionAdminKey` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide} for more information.
   */
  admin_key: string;
  /**
   * @deprecated Use `Slide.legacyQuestionPublicKey` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide} for more information.
   */
  public_key: string;
  /**
   * @deprecated Use `StaticContent.styling.listDisplayMode` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent.styling} for more information.
   */
  show_all_bullets: boolean;
  /**
   * @deprecated Use `InteractiveContent.voteSettings.voteOnResponsePolicy` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.voteSettings} for more information.
   */
  voting_on_answers: boolean;
  /**
   * @deprecated Use `InteractiveContent.voteSettings.maxEntriesPerResponse` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.voteSettings} for more information.
   */
  multiple_votes_on_answers: boolean;
  steps: number;
  /**
   * @deprecated Use `StaticContent.styling.listStyle` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent.styling} for more information.
   */
  numbered_list: boolean;
  duration_vote: number;
  duration_upvote: number;
  /**
   * @deprecated Use `InteractiveContent.correctArea.correctAreaMode` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.correctArea} for more information.
   */
  show_correct: boolean;
  image_as_background: boolean;
  /**
   * @deprecated Use `InteractiveContent.styling.responseCountDisplayMode` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.styling} for more information.
   */
  percent_default: boolean;
  /**
   * @deprecated Use `InteractiveContent.maxEntries` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.maxEntries} for more information.
   */
  max_votes: number;
  /**
   * @deprecated Use `InteractiveContent.styling.gridImageKey|gridImageRotation` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.styling} for more information.
   */
  grid: QuestionGrid;
  /**
   * @deprecated Use `InteractiveContent.responseRange` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.responseRange} for more information.
   */
  range: QuestionRange;
  /**
   * @deprecated Use `InteractiveContent.styling.answersPerResponse` instead
   *
   * NOTE: Not fully supported at the time of this writing
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent} for more information.
   */
  max_nb_words: number;
  /**
   * @deprecated Use `StaticContent.imagePosition` instead
   *
   * -----
   * Refer to t{@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent} for more information.
   */
  slide_image_type: QuestionSlideImageType;
  /**
   * @deprecated Use `InteractiveContent.image.presets.original.url` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.image} for more information.
   */
  question_image_url: string | null;
  /**
   * @deprecated Use `StaticContent.url` instead (only when `StaticContent.type` is `video`)
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent} for more information.
   */
  question_video_embed_url?: string | undefined;
  /**
   * @deprecated Use `StaticContent.layout.text*` attributes instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent.layout} for more information.
   */
  layout?: Layout | undefined;
  /**
   * @deprecated Use `InteractiveContent.styling.showAverage` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.styling} for more information.
   */
  scales_total_average: boolean;
  /**
   * @deprecated Use `InteractiveContent.responsePolicy` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.responsePolicy} for more information.
   */
  hide_skip: boolean;
  reactions: Array<Reaction> | null;
  /**
   * @deprecated Use `Slide.instructionsBarVisibility` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide}} for more information.
   */
  hide_voting_instructions: boolean;
  /**
   * @deprecated Use `Slide.qrCodeVisibility` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide} for more information.
   */
  hide_join_instructions: boolean;
  hide_leaderboard: boolean;
  /**
   * @deprecated Use `Slide.responseVisibility` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide} for more information.
   */
  hide_results: boolean;
  hide_logo: boolean;
  media_params: MediaParams;
  /**
   * @deprecated Use `StaticContent.image` or `InteractiveContent.image` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent} or {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent} for more information.
   */
  image_alt_description?: string | undefined;
  segment_by: string;
  /**
   * @deprecated Use `Slide.design.colors.custom` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide.design} for more information.
   */
  override_theme: boolean;
  /**
   * @deprecated Use `Slide.design` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide.design} for more information.
   */
  theme_settings?: Theme;
  /**
   * @deprecated This is a multi-mapped property depending on slide type/module id. All `*type` attributes have been aggregated into `StaticContent.type` and/or `InteractiveContent.styling.type`
   * together with `InteractiveContent.styling.visualization` depending on if there is any interactive content or not.
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide} for more information.
   */
  module_id: ModuleId;
  /**
   * @deprecated Use `Slide.visibility` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide} for more information.
   */
  skip_slide: boolean;
  /**
   * @deprecated Use `InteractiveContent.scoring` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.scoring} for more information.
   */
  time_based_scoring: boolean;
  module_custom_data: Record<string, any> | null;
  /**
   * @deprecated Use `InteractiveContent.responsePolicy` instead. Will be either `no-restriction` or `single-response-limited-choices`
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.responsePolicy} for more information.
   */
  multiple_votes: boolean | null;
  /**
   * @deprecated Use `InteractiveContent.image` or `StaticContent.image` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent} or {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent} for more information.
   */
  image?: ImageResource | null;
  /**
   * @deprecated Use `InteractiveContent.image.presets.original.url` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent} or {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1747814709#gid=1747814709 StaticContent} for more information.
   */
  cropped_image_url?: string | null | undefined;
  /**
   * @deprecated Use `InteractiveContent.correctArea.area` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=1641506766#gid=1641506766 InteractiveContent.correctArea} for more information.
   */
  correct_area?: number[][] | undefined | null;
  /**
   * @deprecated Use `Slide.hasComments` instead
   *
   * -----
   * Refer to {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=999677221#gid=999677221 Slide} for more information.
   */
  has_comments: boolean;
}

export type PaceT = Pace;

export interface Collaborator {
  user_id: number;
  user_email: string;
  user_name: string;
  profile_picture_url: string | null;
  type: CollaboratorType;
}

export type CollaboratorType =
  | 'user'
  | 'invitee'
  | 'folder-collaborator'
  | 'folder-invitee';

export type AccessType = 'edit' | 'comment' | 'view' | 'owner';

export interface FolderCollaborator extends Collaborator {
  id: string;
  access: AccessType;
  resource_id: string;
}

export interface PresentationCollaboratorMe extends Collaborator {
  id: number;
  series_id: string;
  access: AccessType;
}

export interface CollaboratorsResponseMe {
  type: string;
  data: Array<PresentationCollaboratorMe>;
}

export interface UserEntity {
  id: string;
  name: string;
  email: string;
  profilePictureUrl: string | null;
}

export interface ResourceSharing {
  id: string;
  resourceId: string;
  resourceType: string;
  entityId: string;
  entityType: 'user' | 'invitee';
  access: AccessType;
  entity: UserEntity;
}

export interface ResourceSharingsResponse {
  data: Array<ResourceSharing>;
}

export interface SeriesPost {
  name?: string;
  pace?: PaceMode;
  copy?: string;
  voter_results_enabled?: boolean;
  questions?: Partial<Question>[] | undefined;
  with_folder?: boolean;
  folder_id?: FolderId | undefined;
  theme_id?: number;
  public_theme_name?: string;
  reactions?: Array<Reaction> | undefined;
  workspace_series?: boolean;
  track_context?: string;
  qfa_active?: boolean;
  comments_enabled?: boolean;
  vote_again_enabled?: boolean;
  closed_for_voting?: boolean;
  qfa_moderation_enabled?: boolean;
}

/**
 * @deprecated This type is deprecated. It is being replaced
 * with an application-specific `SeriesWithSlideDeck` type.
 */
export interface Series {
  created_at: string;
  folder_id: number | null;
  /**
   * @deprecated Use `SlideDeck.slideDeckPublicKey` instead.
   *
   * Note: Take care when using the new model IDs as some operations/functions still require public key.
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  id: string;
  /**
   * @deprecated Use.. * drum roll * `SlideDeck.name` instead.
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  name: string;
  owner_display_name: string;
  /**
   * @deprecated Use `SlideDeck.ownershipSettings.ownerId` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  owner_id: number;
  owner_name: string;
  /**
   * @deprecated Use `SlideDeck.slides` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  questions: Array<Question>;
  tags: Array<string> | null;
  template_data: TemplateData;
  updated_at: string;
  /**
   * @deprecated Use `SlideDeck.participationSettings.participationKey` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  vote_key: string;
  /**
   * @deprecated Use `SlideDeck.ownershipSettings.workspaceId ` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  workspace_id: number | null;
  /**
   * @deprecated Use `SlideDeck.participationSettings.participationExpiredResponsesDeletedAt` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  expired_responses_deleted_at: string | null;
  has_results: boolean;
  /**
   * @deprecated Use `SlideDeck.participationSettings.participationResponseMode` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  closed_for_voting: boolean;
  /**
   * @deprecated Use `SlideDeck.liveChatSettings.liveChatMode` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  comments_enabled: boolean;
  dev_user: boolean;
  disable_share_results: boolean;
  hide_leaderboard: boolean;
  /**
   * @deprecated Use `SlideDeck.presentationSettings.presentationLanguage` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  language: string;
  manual_start: boolean;
  /**
   * @deprecated Use `SlideDeck.musicSettings.url` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  music_url: string;
  /**
   * @deprecated
   *
   * `pace.mode` - Use `SlideDeck.participationSettings.participationMode` instead
   * `pace.active` - Not migrated yet
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  pace: PaceT;
  /**
   * @deprecated Use `SlideDeck.participationSettings.participationIdentityMode` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  participation_identity_mode: ParticipationIdentityMode | null;
  /**
   * @deprecated Use `SlideDeck.participationSettings.participationIdentityModePolicy` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  participation_identity_mode_policy:
    | 'enabled'
    | 'disabled_by_workspace_setting';
  /**
   * @deprecated Use `SlideDeck.presentationTimer.presentationStartedAt` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  presentation_time: number;
  /**
   * @deprecated Use `SlideDeck.profanitySettings.profanityFilters` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  profanity_lang: string;
  public_theme_name?: string;
  /**
   * @deprecated Use `SlideDeck.qaSettings.enablementScope` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  qfa_active: boolean;
  /**
   * @deprecated Use `SlideDeck.qaSettings.questionVisibility` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  qfa_intercom_enabled: boolean;
  /**
   * @deprecated Use `SlideDeck.qaSettings.moderationPolicy` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  qfa_moderation_enabled?: boolean;
  /**
   * @deprecated Use `SlideDeck.qaSettings.moderationAccessKey` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  qfa_moderation_access_key?: string;
  results_sharing?: ResultsSharingT;
  /**
   * @deprecated Use `SlideDeck.slideDeckSegmentations` instead
   *
   * - `segmentations[n].key` - `SlideDeck.slideDeckSegmentations[n].sourceInteractiveContentId`
   * - `segmentations[n].value` - `SlideDeck.slideDeckSegmentations[n].targetInteractiveContentId`
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  segmentations: Record<string, string>;
  /**
   * @deprecated Use `SlideDeck.slideDeckPublicKey` instead.
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  series_id: string;
  /**
   * @deprecated Use `SlideDeck.legacyThemeSettings` instead.
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  theme: Theme;
  /**
   * @deprecated Use `SlideDeck.themeId` instead.
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  theme_id: number;
  /**
   * @deprecated Use `SlideDeck.participationSettings.participationPolicy` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  vote_again_enabled: boolean;
  vote_id: string | number | null;
  quiz_leaderboard: boolean;
  hide_results: boolean;
  /**
   * @deprecated Use `SlideDeck.reactionSettings.reactionsAllowed` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  reactions: Array<Reaction> | null;
  enable_voter_join_workspace: boolean;
  /**
   * @deprecated Use `SlideDeck.ownershipSettings.collaborationMode` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  co_edited: boolean;
  /**
   * @deprecated Use `SlideDeck.ownershipSettings.folderName` instead
   *
   * -----
   * Refer to the {@link https://docs.google.com/spreadsheets/d/13HclNq2xtJmoLT3vN4YTKDkh50dlG1ZG7w-5hmWbgyY/edit?gid=0#gid=0 migration sheet} for more information.
   */
  folder_name: string | null;
}

export interface SeriesForPresentation {
  questions: Array<Pick<Question, 'public_key' | 'steps'>>;
}

export interface VotingSeries
  extends Omit<
    Series,
    | 'owner_display_name'
    | 'owner_name'
    | 'series_id'
    | 'participation_identity_mode_policy'
  > {
  vote_id: number;
}

export interface PresentationResponse {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  voteKey: string | null;
  ownerId: number;
  ownerName?: string; // Optional for Backward compatibility
  ownerDisplayName: string;
  ownerProfilePictureUrl?: string; // Optional for Backward compatibility
  shared?: boolean; // Optional for Backward compatibility
  inUserSharedFolder: boolean;
  sharedOutsideWorkspace?: boolean; // Optional for Backward compatibility
  workspaceId: number | null;
  hasResults: boolean;
  templateId: string | null;
  isSharedWithOrganization: boolean;
  sharedWithGroups?: Array<string>; // Optional for Backward compatibility
  /**
   * @deprecated use the lenght of the questions property instead
   */
  questionsCount: number; // TODO will be moved to theq
  folderId?: FolderId;
  questions?: PresentationQuestions[];
  resourceSharingId?: string | null;
}

interface PresentationQuestions {
  id: number;
  question: string;
  adminKey: string;
}

export interface PresentationsResponse {
  data: [PresentationResponse];
  page: number;
  pageCount: number;
  totalCount: number;
}

export interface Session {
  id: number;
  csat_done: boolean;
  current?: boolean;
}

export interface SeriesHasResultsResponse {
  has_results: boolean;
}

export interface ParticipationMetricsDataT {
  id: string;
  title: string;
  module_id: ModuleId;
  participants: number;
  participation_rate: number;
}

export interface SegmentationDataT {
  source_question_id: string;
  target_question_id: string;
}

export interface SeriesParticipantT {
  id: string;
  display_name: string | null;
}

export interface ResultsPageResponse {
  id: string;
  state: 'created' | 'processing' | 'completed' | 'refreshing' | 'failed';
  responses: {
    segmentations: SegmentationDataT[];
    data: ResponseData[];
  } | null;
  participation: {
    total: {
      participants: number;
      avg_participation_rate: number;
      series_participants: SeriesParticipantT[];
    };
    metrics: {
      data: ParticipationMetricsDataT[];
    };
  } | null;
  qfa: {
    enabled: boolean;
    moderated: boolean;
    data_count: number;
    participant_count: number;
    by_time: {
      data: ResultsPageQFAData[];
    };
    by_upvotes: {
      data: ResultsPageQFAData[];
    };
  } | null;
  quiz: {
    average_correct_responses: number;
    leaderboard: {
      data: ResultsPageQuizLeaderboardEntry[];
    };
  } | null;
  meta: {
    user_role: UserPresentationRole;
    name: string;
  };
  flags: {
    hidden_responses: 'present' | 'none';
    paywall: 'enabled' | 'disabled';
    quiz_presence: 'present' | 'none';
    excel_export: 'enabled' | 'disabled' | 'limited';
    pdf_export: 'enabled' | 'disabled';
    results: 'present' | 'none';
    participation_identity_mode: ParticipationIdentityMode;
  };
  results: {
    questions: {
      id: string;
      title: string;
      admin_key: string;
      module_id: string;
      results: {
        quickform_responses: [
          {
            id: string;
            participant_identity: SeriesParticipantT | null;
            field_responses: [
              {
                field_id: string;
                field_type: 'text' | 'email' | 'date' | 'checkbox' | 'dropdown';
                field_label: string;
                field_status: 'required' | 'optional';
                responses: string[];
              },
            ];
          },
        ];
      };
    }[];
  };
}

export interface ResultsPageQFAData {
  id: string;
  question: string;
  upvotes: number;
  answered: boolean;
  answered_at: string | null;
  moderator_approved: boolean;
  asked_on: {
    question: string;
    question_admin_key: string;
    module_id: ModuleId;
  };
  participant_identity: SeriesParticipantT | null;
}

export interface ResultsPageQuizLeaderboardEntry {
  name: string | null;
  emoji_name: string;
  emoji_shortname: string;
  total_score: number;
}

export interface ChoicesData {
  id: string;
  title: string;
  responses: number;
  percentage: number;
  image: ChoicesDataImage | null;
  is_correct: boolean;
  segments?: ChoicesData[];
  participant_identities: SeriesParticipantT[];
}

export interface QuizChoicesData {
  id: string;
  title: string;
  responses: number;
  percentage: number;
  is_correct: boolean;
}

export interface ChoicesDataImage {
  src: string;
  alt: string;
}

export interface OpenEndedData {
  id: string;
  group_id: string;
  response: string;
  votes: number;
  moderated: boolean;
  contains_profanity: boolean;
  segment_id?: string | null | undefined;
  participant_identity: SeriesParticipantT | null;
}

export interface QuizOpenData {
  id: string;
  is_correct: boolean;
  response: string;
  count: number;
}

export interface OpenEndedGroup {
  id: string;
  label: string;
  votes: number;
}

export const WordcloudCategory = {
  MostPopular: 'most_popular',
  AlsoProminent: 'prominent',
  Others: 'other',
} as const;

export type WordcloudCategory =
  (typeof WordcloudCategory)[keyof typeof WordcloudCategory];

export interface WordcloudData {
  response: string;
  category: WordcloudCategory;
  count: number;
  moderated: boolean;
  contains_profanity: boolean;
  participant_identities: SeriesParticipantT[];
}

export interface ScalesRangeValue {
  value: number;
  title: string;
  count: number;
  percentage: number;
  participant_identities: SeriesParticipantT[];
}

export interface ScalesData {
  id: string;
  title: string;
  skips: number;
  average_value: number | null;
  scaled_average_value: number | null;
  range_values: ScalesRangeValue[];
}

export interface RankingValue {
  rank: number;
  count: number;
  percentage: number;
  participant_identities: SeriesParticipantT[];
}

export interface RankingData {
  id: string;
  title: string;
  skips: number;
  average_value: number | null;
  rank_values: RankingValue[];
  rank: number;
}

export interface CorrectArea {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export interface PinData {
  x: number;
  y: number;
  count: number;
  is_correct: boolean;
  participant_identities: SeriesParticipantT[];
}

export type SegmentationState =
  | 'segmented'
  | 'selected'
  | 'available'
  | 'disabled'
  | 'loading'
  | 'locked';

export interface SegmentationSource {
  id: string;
  module_id: string;
  title: string;
  state: SegmentationState;
  choices: SegmentationChoiceSource[];
}

export interface SegmentationChoiceSource {
  id: string;
  title: string;
  image: ChoicesDataImage | null;
}

export interface SegmentationResult {
  state: SegmentationState;
  source_question_id: string | null;
  sources: SegmentationSource[];
}

export interface AssessmentMetrics {
  correct_responses: number | null;
  incorrect_responses: number | null;
  percentage_correct: number | null;
  percentage_incorrect: number | null;
}

export interface ResponseDataBase {
  id: string;
  admin_key: string;
  title: string;
  participants: number;
  total_participants: number;
  segmentation: SegmentationResult;
  assessment_metrics?: AssessmentMetrics | undefined;
}

export interface ScalesResponseData extends ResponseDataBase {
  module_id: 'scales';
  range: {
    min: number;
    max: number;
    min_label: string;
    max_label: string;
  };
  average_value: number;
  data: ScalesData[];
}

export interface RankingResponseData extends ResponseDataBase {
  module_id: 'ranking';
  average_value: number;
  data: RankingData[];
}

export interface ChoicesResponseData extends ResponseDataBase {
  data: ChoicesData[];
  module_id: 'choices';
  responses: number;
}

export interface QuizChoicesResponseData extends ResponseDataBase {
  data: QuizChoicesData[];
  module_id: 'quiz-choices';
  responses: number;
}

export interface OpenEndedResponseData extends ResponseDataBase {
  data: OpenEndedData[];
  groups: OpenEndedGroup[];
  module_id: 'open';
  responses: number;
}

export interface WordcloudResponseData extends ResponseDataBase {
  data: WordcloudData[];
  module_id: 'wordcloud';
  responses: number;
}

export interface QuizOpenResponseData extends ResponseDataBase {
  data: QuizOpenData[];
  module_id: 'quiz-open';
  responses: number;
}

export interface PinOnImageResponseData extends ResponseDataBase {
  data: PinData[];
  module_id: 'pin-on-image';
  image: {
    src: string;
    alt: string;
  };
  correct_area: CorrectArea;
  percentage_correct: number;
}

export interface FieldData {
  field_id: string;
  field_type: 'text' | 'email' | 'date' | 'checkbox' | 'dropdown';
  field_label: string;
  field_status: 'required' | 'optional';
  responses: string[];
}

export interface QuickFormResponseData extends ResponseDataBase {
  module_id: 'metadata';
  data: Array<{
    id: string;
    participant_identity: SeriesParticipantT | null;
    field_responses: FieldData[];
  }>;
}

export interface UnsupportedResponseData extends ResponseDataBase {
  module_id: Exclude<ModuleId, SupportedResponseData['module_id']>;
}

export type SupportedResponseData =
  | ChoicesResponseData
  | OpenEndedResponseData
  | WordcloudResponseData
  | QuizChoicesResponseData
  | ScalesResponseData
  | QuizOpenResponseData
  | RankingResponseData
  | PinOnImageResponseData
  | QuickFormResponseData;

export type ResponseData = SupportedResponseData | UnsupportedResponseData;

export type GetSeriesByVoteId = (id: string) => AxiosPromise<Series>;

export interface TemplateResponse {
  description: string;
  shared_with_organization: boolean;
  shared_with_groups: string[];
  series: Series;
}

export interface TemplatePutResponse
  extends Omit<TemplateResponse, 'shared_with_groups'> {
  id: string;
  shared_with_groups?: { id: string }[];
}

export interface GetTemplatesResponse {
  description: string;
  id: string;
  owner_id: number;
  owner_name: string;
  series: {
    id: string;
    num_questions: number;
    questions: {
      id: string;
    };
    theme: ThemeSettingsT;
  };
}

export interface PublicTemplatePutResponse {
  id: string;
  series_id: string;
  preview_question_admin_key: string;
  published: boolean;
}

export type PublicTemplateWithSeriesT = PublicTemplateT & { series: Series };

export interface PublicTemplateT {
  id: number;
  series_id: string;
  series?: Series;
  preview_question_admin_key: string;
  published: boolean;
  category_id?: number | null;
  name: string;
  description: string;
  slide_count: number;
}

export type PostPublicTemplate = Pick<
  PublicTemplateT,
  | 'series_id'
  | 'preview_question_admin_key'
  | 'published'
  | 'category_id'
  | 'description'
>;

export interface PublicTemplatesCategoryT {
  id: number;
  main_usage: UserProfileT['main_usage'];
  name: string;
  description: string;
}

export type PostPublicTemplatesCategory = Pick<
  PublicTemplatesCategoryT,
  'name' | 'description' | 'main_usage'
>;

interface AddPublicTemplateResponse {
  questions: Question[];
}

export interface QfaDataT {
  asked_on_question_key?: string | null;
  accepted_at?: string;
  id: number;
  question?: string;
  created_at?: string;
  archived_at?: string;
  deleted_at?: string;
  rejected_at?: string;
  upvotes?: number;
  bucket: BucketsT;
  is_profanity: boolean;
  display_name: string | null;
}

export type BucketsT = 'rejected' | 'archived' | 'pending' | 'accepted';

export interface QfaGetResponse {
  sort_by_upvote: boolean;
  total_count: number;
  current_page: number;
  page_size: number;
  next_page: number;
  data: Array<QfaDataT>;
}

export type UserPresentationRole = 'Owner' | 'Collaborator';

export type ResultsSharingT = `${ResultsSharingEnum}`;

export enum ResultsSharingEnum {
  ON = 'on',
  OFF = 'off',
  OPEN = 'open',
}

export enum WorkspaceRoleEnum {
  OWNER = 'owner',
  ADMIN = 'admin',
  USER = 'user',
  MEMBER_LITE = 'member_lite',
  /** 'non-member' is not a user workspace role in the database
   *  but added here for convenience throughout frontend code */
  NON_MEMBER = 'non-member',
}

export type WorkspaceRole = WorkspaceRoleEnum | undefined | null;

export enum WorkspaceMemberStatusEnum {
  SUSPENDED = 'suspended',
  ACTIVE = 'active',
  PENDING = 'pending',
  PAYING = 'paying',
  FREE = 'free',
}

export type GroupMemberStatus = 'active' | 'pending' | 'suspended';

export enum SignupModeEnum {
  EMAIL = 'email',
  INVITE = 'invite',
  SSO = 'sso',
  STRICT_SSO = 'strict_sso',
  SOFT_SSO = 'soft_sso',
}

export enum InvitationTypeEnum {
  INVITED = 'invited',
  CLAIMED = 'claimed',
}

export interface WorkspaceMemberT {
  id: string;
  email: string;
  name?: string;
  role?: WorkspaceRole;
  status: WorkspaceMemberStatusEnum;
  joined_at?: string;
}

export interface MemberStatsT {
  active: number;
  suspended: number;
  pending: number;
  member_lite: number;
  pending_member_lite: number;
  all: number;
}

export interface WorkspaceResponseT {
  id: number;
  themes_control?: 'everyone' | 'admins';
  default_theme_id?: number;
  groups_controlled_by_role?: 'member' | 'admin';
  hide_users_from_members?: boolean;
  disable_share_results: boolean;
  join_page_active: boolean;
  role: WorkspaceRole;
  active_subscription?: {
    created_at: string;
    licenses: number;
    plan: {
      key: string;
      category: string;
      features: string;
    };
  };
  name?: string | undefined;
  url?: string | undefined;
  join_url: string;
  saml_login_url?: string;
  email_domains?: string | undefined;
  signup_mode: SignupModeEnum;
  available_licenses: number;
  occupied_licenses: number;
  available_free_seats: number;
  member_stats: MemberStatsT;
  collaboration_rights: string;
  domain_restriction?: boolean;
  discoverable: boolean;
  join_link_restriction_enabled: boolean;
  request_to_join_enabled: boolean;
  request_role_upgrade_enabled: boolean;
  shared_series_access: 'anyone' | 'no_one';
  ai_features_enabled: boolean;
  owner_name?: string;
  export_restriction_enabled?: boolean;
  identified_responses_mode?: 'enabled' | 'disabled';
}

export interface PublicWorkspaceResponseT {
  id: number;
  name: string;
  url?: string;
  join_url?: string;
  join_page_active: boolean;
  email_domains?: string;
  saml_login_url?: string;
  signup_mode: SignupModeEnum;
  is_active: boolean;
  active_subscription?: {
    created_at: string;
    licenses: number;
    plan: {
      key: string;
      category: string;
      features: SubscriptionPlanFeaturesT;
    };
  };
  available_licenses: boolean;
  owner_email?: string;
  owner_name?: string;
  members_count: number;
  join_link_restriction_enabled: boolean;
  request_to_join_enabled: boolean;
}

export interface SendInvitationsResponseT {
  new_invites: string[];
  resent_invites: string[];
  existing_members: string[];
}

export type ControlledDomainStateT = 'created' | 'aged' | 'verified';

export interface ControlledDomain {
  id: string;
  name: string;
  state: ControlledDomainStateT;
  txt_record: string;
  created_at: string;
  updated_at?: string;
  number_of_users: number;
}

export type ControlledDomains = ControlledDomain[];
export type AddControlledDomainsResponseT = ApiCollectionT<ControlledDomain>;
export type GetControlledDomainsResponseT = ApiObjectT<{
  verified_domains: number;
  data: ControlledDomain[];
}>;

export type GetTemplatesResponseT = GetTemplatesResponse[];

export interface WorkspaceInviteRequestsResponseT {
  id: string;
  user_email: string;
  user_name: string;
  expires_at: string;
  message: string;
}

export interface WorkspacesByDomainResponse {
  id: number;
  name: string;
  join_url: string;
  members_count: number;
  request_to_join_enabled: boolean;
}

export type GetPublicTemplateResponseT = PublicTemplateT;

export interface GetPublicTemplatesResponseT {
  data: PublicTemplateT[];
}

export interface GetPublicTemplateCategoriesResponseT {
  data: PublicTemplatesCategoryT[];
}

export type GetActiveWorkspaceT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<WorkspaceResponseT>;

export type GetWorkspaceByDomainT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<WorkspacesByDomainResponse[]>;

export type GetWorkspaceByUrlT = (
  url: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<PublicWorkspaceResponseT>;

export type ClaimInviteTokenT = (
  payload: {
    url: string;
    email: string;
  },
  config?: AxiosRequestConfig,
) => AxiosPromise<PublicWorkspaceResponseT>;

export type GetVerifiedControlledDomainsT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<GetControlledDomainsResponseT>;

export type AddControlledDomainsT = (
  payload: {
    domains: string[];
  },
  config?: AxiosRequestConfig,
) => AxiosPromise<AddControlledDomainsResponseT>;

export type DeleteControlledDomainT = (
  domainId: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<void>;

export type GetWorkspaceMembersSummaryT = (
  queryString: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<WorkspaceMembersSummaryResponseT>;

export type SuspendWorkspaceMember = (
  memberId: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<void>;

export type ReinviteWorkspaceMember = (
  memberId: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<void>;

export type DowngradeWorkspaceMember = (
  memberId: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<void>;

export type UpdateRoleWorkspaceMember = (
  memberId: string,
  payload: UpdateRolePayload,
  config?: AxiosRequestConfig,
) => AxiosPromise<void>;

export type PostWorkspaceMembersExportT = (
  workspaceId: number,
  queryString: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<PostWorkspaceMembersExportResponseT>;

export type GetWorkspaceMembersExportT = (
  exportId: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<GetWorkspaceMembersExportResponseT>;

export type PostWorkspaceNonMembersExportT = (
  queryString: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<PostWorkspaceNonMembersExportResponseT>;

export type GetWorkspaceNonMembersExportT = (
  exportId: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<GetWorkspaceNonMembersExportResponseT>;

export type UpdateWorkspaceT = (
  id: number,
  payload: Partial<WorkspaceResponseT>,
  config?: AxiosRequestConfig,
) => AxiosPromise<WorkspaceResponseT>;

export type CreateWorkspaceT = (
  payload: Partial<WorkspaceResponseT> | undefined,
  config?: AxiosRequestConfig,
) => AxiosPromise<WorkspaceResponseT>;

export interface GetWorkspaceSettingsResponse {
  anyoneCanInvite: boolean;
  memberLiteLimit: number;
}

export type GetWorkspaceSettings = (
  config?: AxiosRequestConfig,
) => AxiosPromise<GetWorkspaceSettingsResponse>;

export type PutAnyoneCanInvite = (
  payload: { anyoneCanInvite: boolean },
  config?: AxiosRequestConfig,
) => AxiosPromise<void>;

export type UpdateDiscoverableT = (
  id: number,
  payload:
    | { discoverable?: boolean; email_domains?: string[] }
    | Record<string, unknown>,
  config?: AxiosRequestConfig,
) => AxiosPromise<void>;

export interface UpdateRolePayload {
  role: WorkspaceRoleEnum;
}

export type InviteContinue = 'editor' | 'feedback' | 'review' | undefined;
export type InviteLanding = 'teaminvite' | undefined;
export type PostWorkspacePendingMembershipsT = (
  workspaceId: number | undefined,
  payload: {
    type: InvitationTypeEnum;
    emails: string[];
    group_ids?: string[];
    role: WorkspaceRole;
    series_id?: string | null;
    continue?: InviteContinue | null;
    landing?: InviteLanding | null;
  },
  config?: AxiosRequestConfig,
) => AxiosPromise<SendInvitationsResponseT>;

export type ConsumeWorkspacePendingMembershipsT = (
  token: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<any>;

type DeleteWorkspacePendingMembershipsT = (
  token: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<WorkspaceResponseT>;

export interface WorkspacePendingMembershipT {
  email: string;
  invited_role: WorkspaceRole;
  workspace: PublicWorkspaceResponseT;
}

export type GetWorkspacePendingMembershipsT = (
  token: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<WorkspacePendingMembershipT>;

export type GetWorkspaceInviteRequestsT = (
  id: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<WorkspaceInviteRequestsResponseT[]>;

export type RequestUpgradeT = (
  message: string,
  config?: AxiosRequestConfig,
) => AxiosPromise;

export enum WorkspaceInsightsXIntervalEnum {
  ALL_TIME = 'all-time',
  SIX_MONTHS = '6-months',
  THIRTY_DAYS = '30-days',
}

/**
 * Standard shape of all objects returned from the API.
 */
type ApiObjectT<Entity> = Entity & {
  resource: string;
  url: string;
};

/**
 * Standard shape of all collections returned from the API.
 */
type ApiCollectionT<Entity> = ApiObjectT<{
  data: Array<ApiObjectT<Entity>>;
}>;

/*****************************************/
/** Endpoint specific response types 👇 **/

/*****************************************/
export interface GroupT {
  id: string;
  name: string;
  description: string | null;
  creator?: string;
  memberCount: number;
  themeCount?: number;
  templateCount?: number;
}

export type GroupsT = GroupT[];

export interface GroupMemberT {
  id: string | number;
  name?: string;
  email: string;
  status: string;
  profile_picture_url: string | null;
}

export interface MembershipRoleT {
  name: WorkspaceRole;
  displayName: string;
  description: string;
  isDefault: boolean;
  dropdownName: string;
}

export type MembershipRolesT = MembershipRoleT[];

export type MemberT = ApiObjectT<{
  id: string;
  name: string | null;
  email: string;
  user_name: string;
  role: WorkspaceRole;
  access_level: number;
  status: WorkspaceMemberStatusEnum;
  presentations_count: number | null;
  last_active: string | null;
  joined_at: string | null;
  updated_at: string | null;
  profile_picture_url: string | null;
  groups: {
    memberships: { name: string }[];
  };
}>;

export type WorkspaceMembersResponseT = ApiObjectT<{
  data: MemberT[];
}>;

export type WorkspaceMembersSummaryResponseT = ApiObjectT<{
  user_count: number;
}>;

export type WorkspaceNonMembersSummaryResponseT = ApiObjectT<{
  total: number;
}>;

type GetWorkspaceMembersExportResponseT = ApiObjectT<{
  file_url: string;
}>;

type PostWorkspaceMembersExportResponseT = ApiObjectT<{
  id: string;
}>;

type GetWorkspaceNonMembersExportResponseT = ApiObjectT<{
  file_url: string;
}>;

type PostWorkspaceNonMembersExportResponseT = ApiObjectT<{
  id: string;
}>;

export interface GroupResponseT {
  id: string;
  name: string;
  description: string | null;
  creator?: string;
  member_count: number;
  theme_count: number;
  template_count: number;
}

type FetchGroupsResponseT = ApiCollectionT<GroupResponseT>;

type FetchGroupDetailsResponseT = ApiObjectT<GroupResponseT>;

type FetchGroupMembersResponseT = ApiCollectionT<{
  user: {
    id: string;
    name: string;
    email: string;
    profile_picture_url: string;
    status: GroupMemberStatus;
  };
}>;

export type {
  FetchGroupDetailsResponseT,
  FetchGroupMembersResponseT,
  FetchGroupsResponseT,
};

type GetAllT = (
  args: { isAdmin: boolean },
  config?: AxiosRequestConfig,
) => AxiosPromise<FetchGroupsResponseT>;

type GetGroupT = (
  args: { id: string },
  config?: AxiosRequestConfig,
) => AxiosPromise<FetchGroupDetailsResponseT>;

type CreateGroupT = (
  args: { name: string; description?: string },
  config?: AxiosRequestConfig,
) => AxiosPromise<FetchGroupDetailsResponseT>;

export type UpdateGroupT = (
  args: Partial<GroupResponseT>,
  config?: AxiosRequestConfig,
) => AxiosPromise<UpdateGroupT>;

type DeleteGroupT = (
  args: { id: string },
  config?: AxiosRequestConfig,
) => AxiosPromise<null>;

type GetGroupMembersT = (
  args: { id: string },
  config?: AxiosRequestConfig,
) => AxiosPromise<FetchGroupMembersResponseT>;

type RemoveGroupMembersT = (payload: {
  id: string;
  members: Array<{ id: string | number; status: string }>;
}) => AxiosPromise<null>;

type AddMembersT = (args: {
  id: string;
  members: Array<{ id: string; status: WorkspaceMemberStatusEnum }>;
}) => AxiosPromise<null>;

interface ApiTokenT {
  created_at: string;
  id: number;
  token: string;
}

type GetApiTokensResponseT = ApiTokenT[];

type GenerateApiTokensResponseT = ApiTokenT;

type GetApiTokensT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<GetApiTokensResponseT>;

type GenerateApiTokenT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<GenerateApiTokensResponseT>;

type RevokeApiTokenT = (
  token: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<null>;

export type ReactionResult = { [key in Reaction]?: number };

export interface ReactionsResults {
  respondents: number;
  results: ReactionResult;
}

export interface WorkspaceInviteRequestResponseT {
  id: string;
  user_id: number;
  workspace_id: number;
  expires_at: string;
}

export interface SegmentT {
  id: number | null;
  label: string;
  position: number;
}

export interface QuestionSession {
  respondents: number;
  results: Array<QuestionResult> | ReactionResult;
}

export type QuestionSessionResponse = Record<string, QuestionSession>;

type QuickFormResult = number;

export interface SessionResult {
  type: QuestionType;
  timestamp: number;
  results: Array<QuestionResult> | ReactionResult | QuickFormResult | string;
  respondents: number;
  segments?: Array<SegmentT>;
  votes?: any;
}

export interface QuestionResultParams {
  segment_by?: string;
  session?: number;
  isPolling?: boolean;
  isPublic?: boolean | null;
  isScreenshot?: boolean;
  transportDown?: boolean;
  countdown?: number;
}

export interface SeriesHasResultsParams {
  questionIds?: string[] | undefined;
  minRespondents?: number | undefined;
}

export type FetchUserT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<UserResponseT>;

export type GetNotificationT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<NotificationResponseT>;

export type GetUserProfileT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<UserProfileT>;

export type UpdateUserProfileT = (
  payload: Partial<Omit<UserProfileT, 'id'>>,
  config?: AxiosRequestConfig,
) => AxiosPromise<UserProfileT>;

export type RevokeSessionsT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<WorkspaceResponseT>;

export interface Config {
  baseURL: string | null | undefined;
}

export type SaveUtmFlagsT = (
  payload: {
    flags: Partial<UserFlagsT>;
  },
  config?: AxiosRequestConfig,
) => AxiosPromise<UserResponseT>;

export type UpdateFlagsT = (
  flags: UserFlagsT,
  config?: AxiosRequestConfig,
) => AxiosPromise<UserResponseT>;

export type UpdateSettingsT = (
  settings: Partial<UserSettingsT>,
  config?: AxiosRequestConfig,
) => AxiosPromise<UserResponseT>;

export interface NotificationResponseT {
  type: 'announcement' | 'success' | 'warning';
  content: {
    message: string;
    title: string;
    meta?: {
      is_session_notification?: boolean;
      group_id?: string;
    };
    id?: string;
  };
}

type UserProfileProperty<T> = T | 'skipped' | undefined;

export interface UserProfileT {
  main_usage: Exclude<MainUsageT, LegacyMainUsageT> | null;
  use_cases: string[];
  user_id: number;
  organization_type: UserProfileProperty<InitialMainUsageId>;
  organization_size: UserProfileProperty<OrganizationSize>;
  department: UserProfileProperty<IMUDepartmentId>;
  work_role: UserProfileProperty<CompanyRoles | EducationRoles>;
  user_initial_goal: UserProfileProperty<UserInitialGoal>;
}

export const ORG_TYPE_LIST = [
  'business',
  'education',
  'public-sector',
  'non-profit',
  'student',
  'other',
] as const;

export type InitialMainUsageId = (typeof ORG_TYPE_LIST)[number];

type LegacyBusiness = 'work';

export type LegacyMainUsageT =
  | LegacyBusiness
  | 'workshops'
  | 'events'
  | 'training_sessions'
  | 'meetings'
  | 'conferences'
  | 'skipped';

export type MainUsageT = 'business' | 'education' | 'other' | LegacyMainUsageT;

export const IMU_COMPANY_ROLE_LIST = [
  'freelancer',
  'team-member',
  'team-manager',
  'director',
  'executive-assistant',
  'executive',
  'other',
] as const;

export type CompanyRoles = (typeof IMU_COMPANY_ROLE_LIST)[number];

export const IMU_EDUCATION_ROLE_LIST = [
  'tl-library-staff',
  'dean-head-of',
  'director',
  'provost',
  'president-chancellor',
  'other',
] as const;

export type EducationRoles = (typeof IMU_EDUCATION_ROLE_LIST)[number];

export const ORG_SIZE_LIST = [
  '1-10',
  '11-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10000',
  'Over 10001',
] as const;

export type OrganizationSize = (typeof ORG_SIZE_LIST)[number];

export const IMU_BUSINESS_DEPARTMENTS_LIST = [
  'consulting-services',
  'executive-leadership-strategy',
  'finance-legal',
  'human-resources',
  'it-infrastructure',
  'internal-communications',
  'learning-and-development',
  'marketing',
  'operations-logistics',
  'research-product-development',
  'sales',
  'business-other',
] as const;

export type IMUBusinessDepartments =
  (typeof IMU_BUSINESS_DEPARTMENTS_LIST)[number];

const imuEducationDepartments = [
  'k12-teacher-or-administrator',
  'educator-higher-education',
  'administration-and-support-higher-education',
  'education-other',
];

type IMUEducationDepartments = (typeof imuEducationDepartments)[number];

const imuPublicSectorDepartments = [
  'executive-leadership-strategy',
  'finance-legal',
  'human-resources',
  'it-infrastructure',
  'operations-logistics',
  'research-product-development',
  'policy-program-public-affairs',
  'public-sector-other',
];

type IMUPublicSectorDepartments = (typeof imuPublicSectorDepartments)[number];

const imuNonProfitDepartments = [
  'communications-advocacy',
  'executive-leadership-strategy',
  'finance-legal',
  'fundraising-development',
  'human-resources',
  'it-infrastructure',
  'operations-logistics',
  'program-management',
  'non-profit-other',
] as const;

type IMUNonProfitDepartments = (typeof imuNonProfitDepartments)[number];

export type IMUDepartmentId =
  | IMUBusinessDepartments
  | IMUEducationDepartments
  | IMUPublicSectorDepartments
  | IMUNonProfitDepartments;

export const USER_INITIAL_GOAL = [
  'learning',
  'leadership-communication',
  'collaborative-meetings',
  'feedback',
  'not-sure',
] as const;

export type UserInitialGoal = (typeof USER_INITIAL_GOAL)[number];

export type UseCaseT =
  | 'engagement'
  | 'training-educating'
  | 'insights-feedback'
  | 'not-sure'
  | 'skipped';

export interface UserFlagsT {
  opt_out_csat?: boolean;
  requires_3ds_action?: boolean | string;
  onboarding?: MainUsageT;
  onboarding_use_cases?: Array<UseCaseT>;
  utm_first?:
    | {
        [key: string]: string;
      }
    | undefined;
  utm_last?:
    | {
        [key: string]: string;
      }
    | undefined;
  receive_results_email?: boolean;
  ai_features_enabled?: boolean;
  dismissed_sharedpage_groups_notification?: boolean;
  collaboration_feedback_notification?: boolean;
  free_import_experiment?: string;
  dismissed_embed_paywall?: boolean;
  mentimote_intro?: boolean;
  announcement_slide_library?: boolean;
  announcement_slide_library_add_slide?: boolean;
  role_changed?: boolean;
  previous_role?: WorkspaceRole;
  notifications_technical_test_treatment?: string;
}

type ExternalIdentityT = 'google' | 'facebook';

export enum UserFeaturesTypeEnum {
  REGISTERED = 'registered',
  BASIC = 'basic',
  PRO = 'pro',
  ENTERPRISE = 'enterprise',
  ENTERPRISE_TRIAL = 'enterprise_trial',
}

export enum PlanKeyEnum {
  INVOICE = 'invoice',
  ENTERPRISE = 'enterprise_2021_06',
  ENTERPRISE_TRIAL = 'trial_enterprise_2020_11',
}

export enum PlanFeaturesEnum {
  PRO = 'pro',
  ENTERPRISE = 'enterprise',
}

export type SubscriptionPlanFeaturesT =
  | 'registered'
  | 'basic'
  | 'pro'
  | 'enterprise'
  | 'enterprise_trial';

export type UserFeaturesType = SubscriptionPlanFeaturesT;

export interface UserFeaturesT {
  custom_themes_limit_user: number;
  template_editor?: boolean;
  type: UserFeaturesType;
  engaged_participants_limit?: number;
  custom_rating_grids?: boolean;
  manage_groups: boolean;
  custom_themes: boolean;
  custom_themes_limit: number;
  custom_colors: boolean;
  quick_form: boolean;
  several_answers: boolean;
  private_results: boolean;
  moderation_enabled: boolean;
  org_template?: boolean;
  groups: boolean;
  dev_user: boolean;
  export: boolean;
  series_in_workspace_limit: number;
  user_capturing: boolean;
  import_document?: boolean;
  downgrade?: boolean;
  ai_enabled: boolean;
  ai_domain_enabled: boolean;
  collaboration?: {
    enabled?: boolean;
    entitlement?: string;
  };
}

interface UserSettingsT {
  language: string | null;
  profanity_lang: string | null;
}

export interface UserResponseT {
  active_workspace_id: number;
  address?: string | null;
  beta_status: 'active' | 'standard' | 'inactive';
  created_at: string;
  collaboration_rights: 'no_one' | 'anyone' | 'workspace_members';
  email: string;
  external_identities?: ExternalIdentityT[] | null;
  features: UserFeaturesT;
  flags: UserFlagsT;
  fromCache?: true;
  has_password?: boolean;
  id: number;
  identifier?: string | null;
  intercom_hash?: string | null;
  name: string;
  otp: { otp: string; expires_in: number };
  role?: WorkspaceRole;
  saml: boolean;
  settings: null | UserSettingsT;
  session_token?: string;
  payments_provider?: 'stripe' | 'unknown';
  token: string;
  user_id: number;
  service_urls: ServiceUrls;
  region: Regions;
  profile_picture: ImageResource | null;
  is_verified: boolean;
  requires_email_verification: boolean;
}

export interface GridT {
  id: string | number;
  bright: string;
  dark: string;
  name: string;
  type: 'default' | 'custom';
  rotation?: 0 | 90 | 180 | 270 | 360;
  url?: string;
  cropped_image_url?: string | null;
  media_params?: MediaParams;
  mediaParams?: MediaParams;
  altText?: string;
  image_alt_description?: string;
  editable: boolean;
}

interface GridPostDataT {
  name: string;
  key?: string;
  media_params: MediaParams;
  image_path?: string;
  escaped_path: string;
  image_alt_description: string;
  device_id: string;
}

export type TransactionsResponseT = Array<{
  amount: string;
  created_at: string;
  currency: string;
  id: string;
  status: string; // transaction status
  type: string; // transaction type
  payment_method_type: 'paypal_account' | 'credit_card';
  // payment method-specific fields
  paypal_email?: string;
  creditcard?: string;
  creditcard_type?: string;
}>;

export type BillingHistoryResponseT = Array<{
  amount: number;
  currency: CurrencyT;
  finalized_at: number | null;
  due_at: number | null;
  id: string;
  invoice_url: string;
  paid_at: number | null;
  status?: string;
  type: string;
}>;

export interface CurrencyT {
  decimal_mark: string;
  iso_code: string;
  subunit_to_unit: number;
  symbol: string;
  symbol_first: boolean;
  thousands_separator: string;
}

export interface StripeAddressT {
  city: string;
  country: string;
  postal_code: string;
  line1: string;
  line2?: string;
  state?: string;
}

export interface StripeInvoiceDetailsT {
  po_number?: string;
  other_info?: string;
}

export enum TAXStatusTypes {
  Verified = 'verified',
  Error = 'error',
  Pending = 'pending',
}

export interface StripeCustomerTaxIdT {
  country?: string;
  type?: string;
  value?: string;
  verification_status?: TAXStatusTypes;
}

export interface StripeCustomerResponseT {
  customer: {
    name: string;
    currency?: string;
    address: StripeAddressT;
    tax_id?: StripeCustomerTaxIdT;
    invoice_details?: StripeInvoiceDetailsT;
  };
}

export enum PlanCategory {
  FREE = 'free',
  TEAMS = 'teams',
  BASIC = 'basic',
  BASIC_MONTHLY = 'basic-monthly',
  PRO = 'pro',
  EDUCATIONAL_BASIC = 'educational-basic',
  EDUCATIONAL_BASIC_MONTHLY = 'educational-basic-monthly',
  EDUCATIONAL_PRO = 'educational-pro',
  INVOICE = 'teams',
  STUDENT_PRO = 'student-pro',
  CONFERENCE_SMALL = 'conference-small',
  CONFERENCE_LARGE = 'conference-large',
  ENTERPRISE = 'enterprise',
  PRO_TRIAL = 'pro-trial',
  ENTERPRISE_TRIAL = 'enterprise-trial',
}

export enum PaymentMethodType {
  Card = 'card',
  PayPal = 'paypal',
  Link = 'link',
  BankAccount = 'bank_account',
  VirtualAccount = 'virtual_account',
}

interface CardPaymentResponseT {
  type: PaymentMethodType.Card;
  brand?: string;
  exp_month?: number;
  exp_year?: string | number;
  last4?: string;
  expired?: boolean;
}

interface PayPalPaymentResponseT {
  type: PaymentMethodType.PayPal;
  email: string;
}

interface LinkPaymentResponseT {
  type: PaymentMethodType.Link;
  email: string;
}

interface VirtualAccountPaymentResponseT {
  type: PaymentMethodType.VirtualAccount;
  reference: string;
  iban: string;
  bic: string;
  country: string;
}

interface BankAccountPaymentResponseT {
  type: PaymentMethodType.BankAccount;
  reference: string;
  account_name: string;
  bank: string;
  iban: string;
  swift: string;
  address: string;
}

export type PaymentMethodResponseT = {
  type: PaymentMethodType;
} & (
  | CardPaymentResponseT
  | PayPalPaymentResponseT
  | LinkPaymentResponseT
  | VirtualAccountPaymentResponseT
  | BankAccountPaymentResponseT
);

export interface SubscriptionPlanT {
  key: string;
  features: string;
  name: string;
  recurring: { years: number } | 'none';
  category: PlanCategory;
  included_licenses: number;
  multiple_licenses: number | null;
  recurring_unit: string;
}

export interface PlanPriceT {
  total: number;
  per_license: number;
  currency: string;
  tax_rate?: number;
  tax?: number;
  total_including_tax?: number;
  total_excluding_tax?: number;
  license_discount?: number;
  total_discount_amount?: number;
  total_discount_percentage?: number;
}

export enum SubscriptionStatus {
  CANCELED = 'Canceled',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  PAST_DUE = 'Past Due',
  EXPIRED = 'Expired',
}

export enum StripeSubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  UNKNOWN = 'unknown',
}

export interface PaymentT {
  billing_day_of_month: number;
  billing_period_end_date: string;
  billing_period_start_date: string;
  current_billing_cycle: number;
  first_billing_date: string;
  next_billing_date: string;
  next_billing_period_amount: number;
  paid_through_date: string;
  price: number;
  status: SubscriptionStatus;
}

export interface SubscriptionT {
  cancel_renewal?: boolean;
  created_at: string;
  expired_at: string;
  end_at?: string;
  id: number;
  is_admin_or_owner?: boolean;
  licenses: number;
  payment: PaymentT | Record<string, never>;
  plan: SubscriptionPlanT;
  renewed_at?: string;
  role: string;
  provider_status: StripeSubscriptionStatus;
  status: SubscriptionStatus;
  workspace: WorkspaceResponseT;
}

export interface FoldersParams {
  parent_id?: FolderId;
  name?: string;
  workspace_id?: number | null;
}

export interface PresentationQueryParamsBase {
  page?: number;
  sort_by?: string;
  sort_order?: string;
  page_size?: string;
}

export interface PrivatePresentationsQueryParams
  extends PresentationQueryParamsBase {
  page?: number;
  folder_id?: number | null;
  name?: string;
  shared?: boolean | null;
}

export interface SubscriptionsResponseT {
  current_subscription?: SubscriptionT;
  queued_subscriptions?: SubscriptionT[];
}

export interface RedirectToStripeCheckoutResponseT {
  checkout_url: string;
}

export interface StripeSetupCheckoutResponseT {
  setup_url: string;
}

interface TierT {
  unit_amount: number;
  up_to: number;
}

export interface PricesResponseT {
  key: string;
  legacy_plan_category: PlanCategory;
  product: 'pro' | 'basic';
  recurring: RecurringT | null;
  amount: number;
  currency: CurrencyT;
  tiers: TierT[];
}

interface RecurringT {
  unit: 'year' | 'month';
  count: number;
}

export interface ReceiptResponseT {
  address: string;
  amount: string;
  amount_excluding_vat: string;
  currency: string;
  due_date: string;
  invoice_date: string;
  invoice_number: string;
  product_name: string;
  quantity: number;
  status: 'settled';
  type: 'sale' | 'credit';
  vat: string;
}

export type GetTransactionsT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<TransactionsResponseT>;

export interface MemberUpgradeT {
  id: string;
  status: WorkspaceMemberStatusEnum;
  role: WorkspaceRole;
}

export type generateCheckoutSessionT = (
  payload: {
    plan_category: string;
    licenses: number;
    members?: MemberUpgradeT[];
    success_url: string;
    cancel_url: string;
    trial?: boolean;
  },
  config?: AxiosRequestConfig,
) => AxiosPromise<RedirectToStripeCheckoutResponseT>;

export type GetSubscriptionsT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<SubscriptionsResponseT>;

export type GetPricesT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<Array<PricesResponseT>>;

export type GetPaymentMethodsT = (
  config?: AxiosRequestConfig,
) => AxiosPromise<Array<PaymentMethodResponseT>>;

export type GetReceiptT = (
  id: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<ReceiptResponseT>;

export interface VoteCodeResponseT {
  id: number;
  renew_after: number;
  expires_in: number;
}

type RegionWorkspaceLookupT = (
  id: string,
  config?: AxiosRequestConfig,
) => AxiosPromise<RegionResponse>;

type RegionUrlsT = (
  region: Regions,
  config?: AxiosRequestConfig,
) => AxiosPromise<ServiceUrls>;

type SamlConfirmT = (
  payload: {
    token: string;
  },
  config?: AxiosRequestConfig,
) => AxiosPromise<UserResponseT>;

type PostProfilePictureT = (
  data: {
    file_id: string;
    crop: MediaParams | null;
    alt_description: string | null;
  },
  config?: AxiosRequestConfig,
) => AxiosPromise<ImageResource>;

type DeleteProfilePictureT = (config?: AxiosRequestConfig) => void;

type DeleteUserT = (config?: AxiosRequestConfig) => void;

interface UserOtpResponse {
  otp: string;
  expires_in: number;
}

export interface PresignedPostInterface {
  url: string;
  fields: Record<string, string>;
}

export interface FilePreflightResponse {
  id: string;
  s3_key: string;
  presigned_post: PresignedPostInterface;
}

export interface SessionsPostDataT {
  question_id?: string | string[];
  context:
    | 'Edit view'
    | 'Presentation view'
    | 'Mentimote'
    | 'Homeview'
    | 'Powerpoint'
    | 'Results page';
  full_reset?: boolean;
}

interface SessionsPutDataT {
  csat_done: boolean;
}

interface SeriesImportQuestionsConfig {
  device_id: string;
  questions: Question[];
}

export interface QuestionWithIndex extends Question {
  insert_at_index: number;
}

export interface SeriesQuestionsPostPayload {
  questions: Array<Partial<QuestionWithIndex>>;
  device_id?: string;
  track_context?: string;
}

export interface EditorQuestionsPostPayload {
  insert_at_index: number;
  questions: DeepPartial<Question>[];
  region?: Regions | undefined;
  client?: string | undefined;
  device_id?: string | undefined;
  track_context?: string | undefined;
}

interface SeriesQuestionsDeletePayload {
  device_id: string;
  questions: string[];
}

export interface SeriesQuestionsPutPayload extends Partial<Question> {
  device_id?: string;
}

export interface SeriesQuestionImagePutPayload {
  cropped_image_url: string;
  question_image_path: string;
  question_image_escaped_path: string;
  question_image_url: string;
}

export type UpdateSeriesPayload = DeepPartial<Series> & {
  device_id?: string;
  question_keys?: Array<string>;
  workspace_id?: number | null;
};

interface TemplatePutData {
  description: string;
  shared_with_organization: boolean;
  shared_with_groups: { id: string }[];
}

export interface DiagnosticsResponse {
  series: [{ name: 'My awesome presentation' }];
}

export interface TrashSeriesT {
  id: string;
  name: string;
  owner_display_name: string;
  owner_name: string;
  slide_count: number;
  deleted_at: string;
  created_at: string;
  days_left: number;
  workspace_id: number;
}

export interface TrashResponse {
  series: TrashSeriesT[];
}

export interface AcademyResponse {
  redirect: string;
}

export interface ResponseText {
  id: string;
  text: string;
  upvotes: number;
  cluster_id: string;
}

export interface ResponseCluster {
  id: string;
  responses: ResponseText[];
  label: string;
  clustering_id: string;
  total_upvotes: number;
}

export interface ResponseClustering {
  id: string;
  clusters: ResponseCluster[];
  score: number;
  status: 'pending' | 'complete' | 'error';
  unclustered_responses: ResponseText[];
}

export interface AdminDevLoginResponse {
  jwt: string;
}

export interface AzureADBody {
  /**
   * Microsoft Teams auth token
   */
  token: string;
  /**
   * Tenant ID
   */
  tid: string;
}

export interface ZoomIntegrationResponse {
  id: string;
  series_id: string;
  user_id: number;
  series_name: string;
  vote_key: string;
  created_at: string;
}

export interface GetAdminUserResponse {
  data: {
    attributes: {
      email: string;
      role: string;
      created_at: string;
      updated_at: string;
    };
    id: string;
    type: string;
  };
}

export interface AiGeneratorResponse {
  id: string;
}

export type AiJobTypes = 'generate_series' | 'generate_titles';

export interface AiCreateProcessPayload {
  job_type: AiJobTypes;
}

export interface AiCreatedProcessResponse {
  id: string;
}

export type AiRunJobPayload = object;

export interface AiRunProcessPayload {
  id: string;
  method: AiJobTypes;
  use_case: string;
  topic: string;
}

export interface ImageCrop {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface EditorCoreClient {
  editor: {
    slideDeck: {
      get: (
        slideDeckId: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<Series>;
      migrate: (
        seriesId: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<unknown>;
      rollbackMigration: (
        slideDeckId: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<unknown>;
      addImage: (
        slideDeckId: string,
        path: string,
        altText: string,
        crop: ImageCrop | undefined | null,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<unknown>;
      updateImage: (
        slideDeckId: string,
        imageId: string,
        altText: string,
        crop: ImageCrop,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<unknown>;
    };

    slide: {
      updateStaticContent: (
        slideId: string,
        data: unknown,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<unknown>;
    };
    interactiveContent: {
      updateInteractiveContent: (
        interactiveContentId: string,
        data: unknown,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<unknown>;
    };
  };
}

export interface CoreClient extends EditorCoreClient {
  campaigns: {
    get: (config?: AxiosRequestConfig) => AxiosPromise<CampaignResponseT>;
    post: (config?: AxiosRequestConfig) => AxiosPromise<CampaignResponseT>;
  };
  convert: {
    get: (
      id: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ConversionInfoT>;
    post: (
      data: { file_url: string },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ImportPostResponse>;
    importPreflight: (
      data: { content_type: string },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ImportPreflightResponse>;
  };
  duplicate: {
    questions: DuplicateQuestions;
    series: DuplicateSeries;
  };
  getSeriesByVoteId: GetSeriesByVoteId;
  folders: {
    get: (
      params: { dynamicDashboard: 'me' | 'workspace' | 'shared-with-me' },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<FolderResponse[]>;
    post: (
      arg0: {
        name: string;
        parent_id: number | null;
      },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<void>;
    put: (
      id: FolderId,
      arg1: { name?: string; parent_id?: number | null },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<void>;
    delete: (id: number, config?: AxiosRequestConfig) => AxiosPromise<void>;
  };
  files: {
    post: (
      content_type: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<FilePreflightResponse>;
  };
  integrations: {
    zoom: {
      get: (
        meetingId: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<ZoomIntegrationResponse>;
      put: (
        meetingId: string,
        body: { series_id: string },
        config?: AxiosRequestConfig,
      ) => AxiosPromise<ZoomIntegrationResponse>;
      delete: (
        meetingId: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<void>;
    };
    msteams: {
      connect: (
        body: AzureADBody,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<RegionResponse>;
      region: (
        body: AzureADBody,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<RegionResponse>;
      authenticate: (
        body: AzureADBody,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<UserResponseT>;
    };
  };
  images: {
    put: (
      id: string,
      data: {
        crop: MediaParams | null;
        alt_description: string | null;
      },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ImageResource>;
  };
  themes: {
    getAll: (config?: AxiosRequestConfig) => AxiosPromise<ThemesResponseT>;
    get: (
      id: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ThemeResponseT>;
    post: (
      data?: Partial<ThemeResponseT>,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ThemeResponseT>;
    put: (
      id: string,
      data: Partial<ThemeUpdateHashT>,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ThemeResponseT>;
    delete: (
      id: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ThemesResponseT>;
  };
  questions: {
    get: (id: string, config?: AxiosRequestConfig) => AxiosPromise<Question>;
    result: {
      get: (
        id: string,
        params?: QuestionResultParams,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<SessionResult>;
    };
    quizPlayers: {
      get: (
        id: string,
        clearCache: boolean,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<QuizPlayerScore[]>;
      put: (
        id: string,
        data: Partial<QuizPlayerPostData>,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<QuizPlayerScore[]>;
    };
    quizResult: {
      get: (
        id: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<QuizResult[]>;
    };
    sessions: {
      get: (
        id: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<QuestionSessionResponse>;
    };
  };
  resourceSharings: {
    getAll: (
      resourceType: 'folder',
      resourceId: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ResourceSharingsResponse>;
    delete: (id: string, config?: AxiosRequestConfig) => AxiosPromise<void>;
    deleteInvite: (
      id: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<void>;
  };
  series: {
    tryModes: {
      post: (
        seriesId: string,
        data: { active_question_index?: number },
        config?: AxiosRequestConfig,
      ) => AxiosPromise<any>;
    };
    realtime: (
      seriesId: string,
      accessKey?: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<Ably.TokenRequest | Ably.TokenDetails>;
    get: (id: string, config?: AxiosRequestConfig) => AxiosPromise<Series>;

    getFormattedForPresentation: (
      id: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<SeriesForPresentation>;

    getSeriesHasResults: (
      seriesId: string,
      params?: SeriesHasResultsParams,
    ) => AxiosPromise<SeriesHasResultsResponse>;
    post: (
      data: SeriesPost,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<Series>;
    put: (
      id: string,
      data: UpdateSeriesPayload,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<Series>;
    delete: (id: string, config?: AxiosRequestConfig) => AxiosPromise<void>;
    importQuestions: (
      seriesId: string,
      data: SeriesImportQuestionsConfig,
      config?: AxiosRequestConfig<SeriesImportQuestionsConfig>,
    ) => AxiosPromise<Series>;
    questions: {
      delete: (
        seriesId: string,
        data: SeriesQuestionsDeletePayload,
        config?: AxiosRequestConfig<SeriesQuestionsDeletePayload>,
      ) => AxiosPromise<Series>;
      post: (
        seriesId: string,
        data: SeriesQuestionsPostPayload,
        config?: AxiosRequestConfig<SeriesQuestionsPostPayload>,
      ) => AxiosPromise<Question[]>;
      put: (
        seriesId: string,
        questionId: string,
        data: SeriesQuestionsPutPayload,
        config?: AxiosRequestConfig<SeriesQuestionsPutPayload>,
      ) => AxiosPromise<Question>;
      image: {
        put: (
          seriesId: string,
          questionId: string,
          data: SeriesQuestionImagePutPayload,
          config?: AxiosRequestConfig<SeriesQuestionImagePutPayload>,
        ) => AxiosPromise<Question>;
      };
    };
    editor: {
      questions: {
        post: (
          seriesId: string,
          data: EditorQuestionsPostPayload,
          config?: AxiosRequestConfig<EditorQuestionsPostPayload>,
        ) => AxiosPromise<{ questions: Question[] }>;
      };
    };
    collaborators: {
      getMe: (
        seriesId: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<CollaboratorsResponseMe>;
      delete: (
        seriesId: string,
        userId?: number,
        userEmail?: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<void>;
    };
    voteCode: {
      get: (
        id: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<VoteCodeResponseT>;
      post: (
        id: string,
        data: Partial<VoteCodeResponseT>,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<VoteCodeResponseT>;
    };
    sessions: {
      get: (
        seriesId: string,
        accessKey?: string | null,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<Session[]>;
      post: (
        seriesId: string,
        data: SessionsPostDataT,
        config?: AxiosRequestConfig<SessionsPostDataT>,
      ) => AxiosPromise<Session[]>;
      put: (
        seriesId: string,
        sessionId: number,
        data: SessionsPutDataT,
        config?: AxiosRequestConfig<SessionsPutDataT>,
      ) => AxiosPromise<Session[]>;
      delete: (
        seriesId: string,
        sessionId: number,
        config?: AxiosRequestConfig<number>,
      ) => AxiosPromise<Session[]>;
    };
    template: {
      get: (
        seriesId: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<TemplateResponse>;
      put: (
        seriesId: string,
        data: TemplatePutData,
        config?: AxiosRequestConfig<TemplatePutData>,
      ) => AxiosPromise<TemplatePutResponse>;
    };
  };
  export: {
    post: (
      seriesId: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<{ message: string }>;
    get: (
      seriesId: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<{ url: string }>;
  };
  ratingGrids: {
    get: (config?: AxiosRequestConfig) => AxiosPromise<GridT[]>;
    post: (
      data: GridPostDataT,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<GridT[]>;
    put: (
      id: GridT['id'],
      data: GridPostDataT,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<GridT[]>;
    delete: (
      id: GridT['id'],
      deviceId: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<GridT[]>;
  };
  templates: {
    post: (
      data: {
        shared_with_organization: boolean;
        series: string;
        description: string;
      },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<TemplatePutResponse>;
    put: (
      id: string,
      arg1: {
        shared_with_organization?: boolean;
        series?: string;
        description?: string;
      },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<TemplatePutResponse>;
    get: (
      queryString: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<GetTemplatesResponseT>;
  };
  templateEditor: {
    publicTemplates: {
      post: (
        data: PostPublicTemplate,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<PublicTemplateT>;
      put: (
        id: number,
        arg1: PostPublicTemplate,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<PublicTemplateT>;
      get: (
        seriesId: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<GetPublicTemplatesResponseT>;
    };
  };
  publicTemplates: {
    get: (
      queryString?: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<GetPublicTemplatesResponseT>;
    template: {
      get: (
        id: number,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<GetPublicTemplateResponseT>;
    };
    categories: {
      post: (
        data: PostPublicTemplatesCategory,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<PublicTemplatesCategoryT>;
      put: (
        id: number,
        arg1: PostPublicTemplatesCategory,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<PublicTemplatesCategoryT>;
      get: (
        queryString?: string,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<GetPublicTemplateCategoriesResponseT>;
    };
    insert: {
      post: (
        seriesId: string,
        data: {
          public_template_id: number | undefined;
          insert_at_index: number;
          track_context: string;
        },
        config?: AxiosRequestConfig,
      ) => AxiosPromise<AddPublicTemplateResponse>;
    };
    replace: {
      post: (
        seriesId: string,
        questionId: string,
        data: {
          public_template_id: number | undefined;
          track_context: string;
        },
        config?: AxiosRequestConfig,
      ) => AxiosPromise<AddPublicTemplateResponse>;
    };
  };
  qfa: {
    get: {
      all: (
        seriesId: string,
        page: number,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<QfaGetResponse>;
      rejected: (
        seriesId: string,
        page: number,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<QfaGetResponse>;
      archived: (
        seriesId: string,
        page: number,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<QfaGetResponse>;
      accepted: (
        seriesId: string,
        page: number,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<QfaGetResponse>;
      pending: (
        seriesId: string,
        page: number,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<QfaGetResponse>;
    };
    delete: (
      id: number,
      deviceId: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<undefined>;
    deleteAll: (
      seriesId: string,
      deviceId: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<undefined>;
    archive: (
      id: number,
      deviceId: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<undefined>;
    restore: (
      id: number,
      deviceId: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<undefined>;
    reject: (
      id: number,
      deviceId: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<undefined>;
    accept: (
      id: number,
      deviceId: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<undefined>;
  };
  users: {
    saveUtmFlags: SaveUtmFlagsT;
    updateFlags: UpdateFlagsT;
    updateSettings: UpdateSettingsT;
    fetchUser: FetchUserT;
    getUnreadNotifications: GetNotificationT;
    getUserProfile: GetUserProfileT;
    updateUserProfile: UpdateUserProfileT;
    revokeSessions: RevokeSessionsT;
    profilePicture: {
      post: PostProfilePictureT;
      delete: DeleteProfilePictureT;
    };
    delete: DeleteUserT;
  };
  userOtp: {
    post: (config?: AxiosRequestConfig) => AxiosPromise<UserOtpResponse>;
  };
  subscriptions: {
    get: GetSubscriptionsT;
  };
  prices: {
    get: GetPricesT;
  };
  payments: {
    getTransactions: GetTransactionsT;
    generateCheckoutSession: generateCheckoutSessionT;
    getPaymentMethods: GetPaymentMethodsT;
    getReceipt: GetReceiptT;
  };
  workspaces: {
    settings: {
      get: GetWorkspaceSettings;
      anyoneCanInvite: {
        put: PutAnyoneCanInvite;
      };
      updateDiscoverable: UpdateDiscoverableT;
    };
    getActiveWorkspace: GetActiveWorkspaceT;
    getWorkspacesByDomain: GetWorkspaceByDomainT;
    getWorkspaceByUrl: GetWorkspaceByUrlT;
    updateWorkspace: UpdateWorkspaceT;
    post: CreateWorkspaceT;
    controlledDomains: {
      getVerified: GetVerifiedControlledDomainsT;
      add: AddControlledDomainsT;
      delete: DeleteControlledDomainT;
    };
    folders: {
      post: (
        arg0: {
          name: string;
          parent_id: number | null;
        },
        config?: AxiosRequestConfig,
      ) => AxiosPromise<FolderResponseSnakeCase>;
      put: (
        id: FolderId,
        arg1: { name?: string; parent_id?: number | null },
        config?: AxiosRequestConfig,
      ) => AxiosPromise<void>;
      delete: (id: number, config?: AxiosRequestConfig) => AxiosPromise<void>;
    };
    members: {
      summary: {
        get: GetWorkspaceMembersSummaryT;
      };
      suspend: SuspendWorkspaceMember;
      reinvite: ReinviteWorkspaceMember;
      downgrade: DowngradeWorkspaceMember;
      updateRole: UpdateRoleWorkspaceMember;
    };
    exportMembers: {
      get: GetWorkspaceMembersExportT;
      post: PostWorkspaceMembersExportT;
    };
    exportNonMembers: {
      get: GetWorkspaceNonMembersExportT;
      post: PostWorkspaceNonMembersExportT;
    };
    pendingMemberships: {
      post: PostWorkspacePendingMembershipsT;
      consume: ConsumeWorkspacePendingMembershipsT;
      delete: DeleteWorkspacePendingMembershipsT;
      get: GetWorkspacePendingMembershipsT;
      claim: ClaimInviteTokenT;
    };
    inviteRequests: { get: GetWorkspaceInviteRequestsT };
    requestUpgrade: RequestUpgradeT;
  };
  groups: {
    getAll: GetAllT;
    get: GetGroupT;
    getMembers: GetGroupMembersT;
    create: CreateGroupT;
    delete: DeleteGroupT;
    update: UpdateGroupT;
    members: { delete: RemoveGroupMembersT; add: AddMembersT };
  };
  apiTokens: {
    getAll: GetApiTokensT;
    generate: GenerateApiTokenT;
    revoke: RevokeApiTokenT;
  };
  region: {
    urls: RegionUrlsT;
    workspaces: RegionWorkspaceLookupT;
  };
  auth: {
    saml: {
      confirm: SamlConfirmT;
    };
    resetPassword: (
      email: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<undefined>;
    verifyResetPasswordCode: (
      code: string,
      config?: AxiosRequestConfig,
    ) => AxiosPromise<{ email: string }>;
    changePasswordWithCode: (
      data: { email: string; password: string; code: string },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<undefined>;
  };
  moderation: (params: { questionId: string; seriesId: string }) => {
    get: (
      config?: AxiosRequestConfig,
    ) => AxiosPromise<BlacklistedAnswersResponse>;
    toggle: (answer: string, config?: AxiosRequestConfig) => AxiosPromise<void>;
    clear: (config?: AxiosRequestConfig) => AxiosPromise<void>;
  };
  diagnostics: {
    get: (config?: AxiosRequestConfig) => AxiosPromise<DiagnosticsResponse>;
    put: (config?: AxiosRequestConfig) => AxiosPromise<DiagnosticsResponse>;
  };
  trash: {
    post: (seriesId: string, config?: AxiosRequestConfig) => AxiosPromise<void>;
    get: (config?: AxiosRequestConfig) => AxiosPromise<TrashResponse>;
  };
  academy: {
    get: (config?: AxiosRequestConfig) => AxiosPromise<AcademyResponse>;
  };
  emailVerification: {
    verify: (
      payload: { otp: string },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<void>;
    resend: (config?: AxiosRequestConfig) => AxiosPromise<void>;
  };
  presentation: {
    reactions: {
      results: {
        get: (
          questionId: string,
          config?: AxiosRequestConfig,
        ) => AxiosPromise<ReactionsResults>;
      };
    };
  };
  presentations: {
    me: {
      get: (
        params?: PrivatePresentationsQueryParams,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<PresentationsResponse>;
    };
    sharedWithMe: {
      get: (
        params?: PresentationQueryParamsBase,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<PresentationsResponse>;
    };
    workspace: {
      get: (
        params?: PrivatePresentationsQueryParams,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<PresentationsResponse>;
    };
  };

  responseClusterings: {
    post: (
      payload: { question_admin_key: string },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ResponseClustering>;
    assignResponse: (
      clusterId: string,
      payload: { response_ids: string[] },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<void>;
    clusters: {
      post: (
        clusteringId: string,
        payload: { label: string },
        config?: AxiosRequestConfig,
      ) => AxiosPromise<ResponseCluster>;
      put: (
        cluster: ResponseCluster,
        config?: AxiosRequestConfig,
      ) => AxiosPromise<ResponseCluster>;
    };
  };
  resultsPage: {
    post: (
      data: {
        series_id: string;
        segmentations?:
          | undefined
          | {
              source_question_id: string;
              target_question_id: string;
            }[];
      },
      config?: AxiosRequestConfig,
    ) => AxiosPromise<ResultsPageResponse>;
  };
  admin: {
    getAdminUser: (
      config?: AxiosRequestConfig,
    ) => AxiosPromise<GetAdminUserResponse>;
    auth: {
      devLogin: (
        config?: AxiosRequestConfig,
      ) => AxiosPromise<AdminDevLoginResponse>;
    };
  };
}
