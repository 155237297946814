type PrimitiveT = string | number | boolean | symbol;
// immutable object that holds the returned validator error properties
export type ValidatorErrorT = Readonly<
  Partial<{
    id?: string; // some id
    message?: string; // an error message
    value?: PrimitiveT; // the value of the validator function input
    current?: PrimitiveT; // the current value of the validatable condition i.e. an input length
    expected?: PrimitiveT; // the expected value of the validatable condition i.e. a max length
  }>
>;
// dictionary that holds the validator return objects to be used by the onValidation function
// the individual { [key]: ValidatorErrorT } pairs will only show up if their respective validator is currently in error
// an empty object indicats no validation errors
export type ValidationErrorsT = Partial<{
  [key: string]: ValidatorErrorT | null | undefined;
}>;
export type ValidationCbT = (errors: ValidationErrorsT) => void; // function that takes in a dictionary of validator return errors
export type ValidatorFnT = (value: string) => ValidationErrorsT | null; // function that returns an error if a condition is matched or null if it's not
// protocol that can be implemented to allow value validation
export interface ValidatableT {
  validators?: Array<ValidatorFnT>; // array of n validators
  onValidation?: ValidationCbT; // callback function that will fire if at least one validator has been passed
}

// helper function to run validators in a validatable component, for example:
// const validate = useCallback(
//   (value) =>
//     validators &&
//     onValidation &&
//     runValidators(validators, onValidation)(value),
//   [onValidation, validators],
// );
//
// See ui/app/src/Input.js for a real-life example.
export const runValidators =
  (validators: Array<ValidatorFnT>, onValidation: ValidationCbT) =>
  (value: string) => {
    if (onValidation && validators && validators.length > 0) {
      const errors = validators.reduce(
        (
          validatorsResults: ValidationErrorsT,
          currentValidator: ValidatorFnT,
        ) => Object.assign(validatorsResults, currentValidator(value)),
        {},
      );
      onValidation(Object.freeze(errors));
    }
  };

// A validator is any pure function that takes in a value and returns a { [key]: ValidatorErrorT } pair if a condition is matched.
// The [key] in the returned object should always be the name of the validator.
// Validators should always return null if the condition is not matched.
// Example validators:
export const VALIDATORS = {
  maxLength:
    (maxLength: number): ValidatorFnT =>
    (value: string) => {
      if (value && value.length >= maxLength) {
        return {
          // properties on a validator return object are optional
          // an empty object { } would also suffice
          maxLength: {
            id: 'input.maxLength', // sample id
            message: 'You have reached the maximum length',
            value,
            current: value.length,
            expected: maxLength,
          },
        };
      }
      return null;
    },
  minLength:
    (minLength: number): ValidatorFnT =>
    (value: string) => {
      if (value && value.length <= minLength) {
        return {
          minLength: {
            id: 'input.minLength', // sample id
            expected: minLength,
          },
        };
      }
      return null;
    },
};
