import React from 'react';
import { Svg } from '../../Svg';
import {
  selectItemIconVariants,
  type SelectIconIconVariantProps,
} from './SelectItemIcon.variants';

interface SelectItemIconProps extends SelectIconIconVariantProps {
  disabled?: boolean | undefined;
}

export function SelectItemIcon({
  size,
  disabled,
  variant,
}: SelectItemIconProps) {
  const variantClasses = selectItemIconVariants({
    size,
    variant: disabled ? 'disabled' : variant,
  });

  return (
    <Svg
      width="24"
      height="10"
      viewBox="0 0 7 10"
      className={variantClasses}
      fill="fill"
    >
      <path
        d="M.188 7.15C.084 7.06 0 6.866 0 6.74v-.246C0 6.22.232 6 .5 6h.248c.14 0 .335.075.432.162L3.5 8.25l2.32-2.088c.1-.09.287-.162.432-.162H6.5c.276 0 .5.23.5.494v.245c0 .136-.087.322-.188.41L3.5 9.987.188 7.148zm0-4.31L3.5 0l3.312 2.84c.1.086.188.272.188.41v.244c0 .265-.224.494-.5.494h-.248c-.145 0-.332-.072-.432-.16L3.5 1.75 1.18 3.827c-.097.087-.293.16-.432.16H.5c-.268 0-.5-.22-.5-.493V3.25c0-.13.084-.322.188-.41z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
