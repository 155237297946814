import type { CaseReducer } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { submitVote } from '../actions/series';

export interface State {
  loading: 'idle' | 'pending';
  failure: string;
  currentRequestId: undefined | string;
}

export const initialState: State = {
  loading: 'idle',
  failure: '',
  currentRequestId: undefined,
};

const resetRequest: CaseReducer<State> = (state) => {
  state.currentRequestId = undefined;
  state.loading = 'idle';
  state.failure = '';
};

export const votingSlice = createSlice({
  name: 'voting',
  initialState,
  reducers: {
    skipQuestion: resetRequest,
    onFollowPresenter: resetRequest,
    onAudiencePaceSlideChange: resetRequest,
    // NOTE: Any "archive" (reseting results) will set state to be initial
    // We do not check which question is being reset.
    archiveResult: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(submitVote.pending, (state, action) => {
      const requestId = action.meta.requestId;
      state.loading = 'pending';
      state.currentRequestId = requestId;
      state.failure = '';
    });

    builder.addCase(submitVote.fulfilled, (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.failure = '';
        state.currentRequestId = undefined;
      }
    });

    builder.addCase(submitVote.rejected, (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.failure = action.payload ?? '';
        state.currentRequestId = undefined;
      }
    });
  },
});

export const votingReducer = votingSlice.reducer;

export const {
  skipQuestion,
  onFollowPresenter,
  onAudiencePaceSlideChange,
  archiveResult,
} = votingSlice.actions;
