import type { PresentationStatePayload } from '@mentimeter/realtime';
import type { SeriesForPresentation } from '@mentimeter/http-clients';
import type { InternalState } from './types';

export const getPresentationPayloadFromState = (
  state: InternalState,
  internalSeries: SeriesForPresentation,
): PresentationStatePayload => ({
  id: state.id,
  slidePublicKey: state.slidePublicKey,
  step: state.step,
  slideIndex: state.slideIndex,
  totalSlides: state.totalSlides,
  hasReachedEnd: state.hasReachedEnd,
  hasNextSlide: state.hasNextSlide,
  hasNextStep: state.hasNextStep,
  hasPreviousSlide: state.hasPreviousSlide,
  hasPreviousStep: state.hasPreviousStep,
  hasPrevious: state.hasPrevious,
  hasNext: state.hasNext,
  internalSeries,
  slideStates: state.slideStates,
  blankScreenVisible: state.blankScreenVisible,
  qrCodePlacement: state.qrCodePlacement,
});

export const getPresentationStateFromPayload = (
  state: PresentationStatePayload,
): InternalState => ({
  id: state.id,
  slidePublicKey: state.slidePublicKey,
  step: state.step,
  slideIndex: state.slideIndex,
  totalSlides: state.totalSlides,
  hasReachedEnd: state.hasReachedEnd,
  hasNextSlide: state.hasNextSlide,
  hasNextStep: state.hasNextStep,
  hasPreviousSlide: state.hasPreviousSlide,
  hasPreviousStep: state.hasPreviousStep,
  hasNext: state.hasNext,
  hasPrevious: state.hasPrevious,
  slideStates: state.slideStates,
  blankScreenVisible: state.blankScreenVisible,
  qrCodePlacement: state.qrCodePlacement,
});
