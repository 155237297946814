import type { MatchCriteriaT } from './types';

export function matches(breakpoint: number, matchCriteria: MatchCriteriaT) {
  const {
    equals,
    lessThan = Infinity,
    greaterThan = -1,
  } = matchCriteria as any;

  return (
    breakpoint < lessThan &&
    breakpoint > greaterThan &&
    (equals === undefined || equals === breakpoint)
  );
}
