import * as React from 'react';
import DeviceContext from './createContext';
import { matches } from './matches';
import type { MatchCriteriaT } from './types';

export function useMatch(matchCriteria: MatchCriteriaT) {
  const { breakpoint } = React.useContext(DeviceContext);

  return matches(breakpoint, matchCriteria);
}
