import React, { forwardRef } from 'react';
import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import type { Colors, ColorsEnum, DSC } from '@mentimeter/ragnar-dsc';
import { cn } from '@mentimeter/ragnar-tailwind-config';

interface PlacementWrapT {
  ignorePointer: boolean;
  bg?: ColorsEnum | undefined;
}

const isThemeColor = (
  theme: DSC,
  c?: ColorsEnum | undefined,
): c is keyof Colors =>
  c === undefined ? false : Object.keys(theme.colors).includes(c);

const rule: Rule<PlacementWrapT> = ({ theme, bg }) => ({
  '> .popper--arrow:before': {
    background: isThemeColor(theme, bg) ? theme.colors[bg] : undefined,
  },
});

const PlacementWrapStyled = styled('div', {
  displayName: 'PlacementWrap',
})(rule);

type PlacementWrapProps = React.ComponentProps<typeof PlacementWrapStyled>;

export const PlacementWrap = forwardRef<
  HTMLDivElement | null,
  PlacementWrapProps
>((props, ref) => {
  const { className, ...rest } = props;

  const defaultClasses = [
    '[&>.popper--arrow]:w-[10px]',
    '[&>.popper--arrow]:h-[10px]',
    '[&>.popper--arrow]:before:w-[10px]',
    '[&>.popper--arrow]:before:h-[10px]',
    '[&>.popper--arrow]:before:bg-high-contrast',
    '[&>.popper--arrow]:before:absolute',
    '[&>.popper--arrow]:before:content-[""]',
    '[&>.popper--arrow]:before:rounded-[2px]',
    '[&>.popper--arrow]:before:rotate-45',
    '[&[data-popper-placement^="right"]_.popper--arrow]:left-[-3px]',
    '[&[data-popper-placement^="top"]_.popper--arrow]:bottom-[-3px]',
    '[&[data-popper-placement^="bottom"]_.popper--arrow]:top-[-3px]',
    '[&[data-popper-placement^="left"]_.popper--arrow]:right-[-3px]',
  ];
  if (props.ignorePointer) {
    defaultClasses.push('pointer-events-none');
  }
  return (
    <PlacementWrapStyled
      tabIndex={-1}
      {...rest}
      ref={ref}
      className={cn(defaultClasses, className)}
    />
  );
});
