import { createSlice } from '@reduxjs/toolkit';
import type { VotingSeries as Series } from '@mentimeter/http-clients';

export const seriesSlice = createSlice({
  name: 'series',
  initialState: {} as Series,
  reducers: {
    fetchSeriesSuccess: (state, action) => action.payload,
    refreshSeriesSuccess: (state, action) => action.payload,
  },
});

export const { fetchSeriesSuccess, refreshSeriesSuccess } = seriesSlice.actions;
