import React, { forwardRef } from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { clsx, cn } from '@mentimeter/ragnar-tailwind-config';
import { inputVariants, type InputVariantProps } from '../utils';
import { Box } from '../../Box';
import { SelectItemIcon } from './SelectItemIcon';

type ValueT = string | number;

export interface OptionT
  extends React.DetailedHTMLProps<
    React.OptionHTMLAttributes<HTMLOptionElement>,
    HTMLOptionElement
  > {
  children: string;
  value?: ValueT | undefined;
  disabled?: boolean | undefined;
}

const SelectItemStyled = styled('select', {
  displayName: 'Select',
})('kosmosSpacing', 'width');

interface SelectItemProps
  extends Omit<
    React.ComponentProps<typeof SelectItemStyled>,
    'p' | 'py' | 'px' | 'pt' | 'pb' | 'pr' | 'pl'
  > {
  inputSize?: InputVariantProps['size'];
  status?: InputVariantProps['variant'];
  disabled?: boolean | undefined;
}

const SelectItem = forwardRef<HTMLElement | null, SelectItemProps>(
  (
    {
      inputSize,
      status,
      className,
      disabled,
      width,
      m,
      mx,
      my,
      mt,
      ml,
      mr,
      mb,
      extend,
      ...props
    },
    ref,
  ) => {
    const optionClasses = [
      '[&>option]:bg',
      '[&>option]:text',
      '[&>option]:font-sans',
      '[&>optgroup]:bg',
      '[&>optgroup]:text',
      '[&>optgroup]:font-sans',
    ];

    const inputVariantClasses = inputVariants({
      size: inputSize,
      variant: status,
    });

    const overrideClasses = [
      'cursor-pointer',
      inputSize === 'compact' ? 'pr-6' : 'pr-8',
    ];

    const marginProps = { m, mx, my, mt, ml, mr, mb };

    return (
      <Box
        width={width}
        extend={extend}
        className={clsx('relative', 'w-full', className)}
        {...marginProps}
      >
        <SelectItemStyled
          ref={ref}
          className={cn(
            'r-select',
            optionClasses,
            inputVariantClasses,
            overrideClasses,
          )}
          disabled={disabled}
          {...props}
        />
        <SelectItemIcon size={inputSize} disabled={disabled} variant={status} />
      </Box>
    );
  },
);

type SelectType = typeof SelectItem;

interface SelectI extends SelectType {
  Option: React.FC<OptionT>;
}

(SelectItem as SelectI).Option = (props) => <option {...props} />;

export type SelectItemT = React.ComponentProps<SelectI>;
export default SelectItem as SelectI;
