import { Box } from '@mentimeter/ragnar-ui';
import React from 'react';
import { PageLayout, ProgressBar } from '../../ui';
import { useIntegration, type IntegrationContextValueT } from '../integrations';
import { Qfa } from '../qfa/Qfa';
import { FinishScreen } from './FinishScreen';
import { FinishScreenFooter } from './FinishScreenFooter';
import type { FinishScreenProps } from './FinishScreenProps';

export const VotingScreenDefaultLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <PageLayout footer={<FinishScreenFooter />}>
      <Box width="100%" maxWidth="320px" alignItems="center">
        {children}
      </Box>
    </PageLayout>
  );
};

export const FinishScreenExperiment = () => {
  return (
    <>
      <ProgressBar progress={1} />
      <FinishScreenExperimentContent qfa={<Qfa isFinishScreen />} />
    </>
  );
};

export const FinishScreenExperimentContent = (props: FinishScreenProps) => {
  const { integrationId }: IntegrationContextValueT = useIntegration();

  if (integrationId === 'MsTeams') {
    return <INTFinishScreenExperiment {...props} />;
  }

  return <FinishScreen {...props} />;
};

export const INTFinishScreenExperiment = (props: FinishScreenProps) => {
  return <FinishScreen {...props} />;
};
