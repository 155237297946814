export function isLinkupEnvironment() {
  return process.env.NEXT_PUBLIC_LINKUP === 'true';
}

export function getClientSideLinkupSubdomain() {
  if (typeof window === 'undefined') return null;
  return window.location.hostname.split('.')[0];
}

export function clientSideReplaceLinkupSubdomain(url: string) {
  const linkupSubdomain = getClientSideLinkupSubdomain();
  if (linkupSubdomain) {
    return url.replace('//www.', `//${linkupSubdomain}.`);
  }
  return url;
}
