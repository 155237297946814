import { cva } from '@mentimeter/ragnar-tailwind-config';
import type { CalloutCardThemeType } from './calloutCardGeneric.types';

interface Container {
  theme?: CalloutCardThemeType;
  size?: 'compact' | 'default';
  hasOnDismiss?: boolean;
}

const container = (props: Container = {}) => {
  const { hasOnDismiss, ...rest } = props;

  const result = cva(
    'w-full border-1 border border-solid rounded-2xl block overflow-auto relative',
    {
      variants: {
        theme: {
          brand: 'bg-brand-weakest text-on-brand-weakest border-brand-weakest',
          negative:
            'bg-negative-weakest text-on-negative-weakest border-negative-weakest',
          positive:
            'bg-positive-weakest on-positive-weakest border-positive-weakest',
          notice:
            'bg-notice-weakest text-on-notice-weakest border-notice-weakest',
          info: 'bg-info-weakest text-on-info-weakest border-info-weakest',
          neutral:
            'bg-neutral-weakest text-on-neutral-weakest border-neutral-weakest',
        },
        size: {
          compact: hasOnDismiss ? 'py-4 pl-4 pr-12' : 'p-4',
          default: hasOnDismiss ? 'py-6 pl-6 pr-14' : 'p-6',
        },
      },
      defaultVariants: {
        theme: 'brand',
        size: 'default',
      },
    },
  );

  return result(rest);
};

const heading = cva('w-full font-normal leading-normal text-inherit mb-1.5', {
  variants: {
    size: {
      compact: 'text-100',
      default: 'text-125',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const text = cva('text-inherit', {
  variants: {
    size: {
      compact: 'text-87.5',
      default: 'text-100',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export const variants = {
  container,
  heading,
  text,
};
