import { useEffect } from 'react';
import { useAppSelector } from '../../redux-hooks';
import { getQuestions } from '../../selectors';
import { importSlideTypeDynamically } from '../../slide-types/importSlideTypeDynamically';

const unique = (id: string, index: number, arr: string[]) =>
  arr.indexOf(id) === index;

export const PrefetchQuestionModules = () => {
  const questions = useAppSelector(getQuestions);
  useEffect(
    () =>
      questions
        .filter((q) => Boolean(q.module_id))
        .map((q) => q.module_id)
        .filter(unique)
        .forEach((moduleId) => importSlideTypeDynamically(moduleId, 'content')),
    [questions],
  );

  return null;
};
