import * as React from 'react';
import {
  SendIcon,
  UserIcon,
  UserSuspendIcon,
} from '@mentimeter/ragnar-visuals';
import { Badge, BadgeWrap } from '../Badge';
import { AvatarCircle } from './AvatarCircle';
import { AvatarDashed } from './AvatarDashed';
import type { AvatarT } from './Avatar.types';

const resolveAvatar = ({ type, ...rest }: AvatarT) => {
  switch (type) {
    case 'pending':
    case 'invitee':
      return (
        <AvatarDashed icon={<SendIcon size={1} color="textWeak" />} {...rest} />
      );
    case 'suspended':
      return (
        <AvatarDashed
          disabled={true}
          icon={<UserSuspendIcon size={2} color="borderStrong" />}
          {...rest}
        />
      );
    case 'non-member':
      return (
        <AvatarDashed icon={<UserIcon size={1} color="textWeak" />} {...rest} />
      );
    default:
      return <AvatarCircle {...rest} />;
  }
};

export const Avatar = (props: AvatarT) =>
  props.badge ? (
    <BadgeWrap
      compact
      placement={2}
      badge={
        <Badge
          compact
          type={props.badge}
          className="border-solid border-[2px] border-[theme(backgroundColor.DEFAULT)]"
        />
      }
    >
      {resolveAvatar(props)}
    </BadgeWrap>
  ) : (
    resolveAvatar(props)
  );
