import * as React from 'react';
import { Button, Box, Text, DrawerTop } from '@mentimeter/ragnar-ui';
import { useRagnar } from '@mentimeter/ragnar-react';
import { useTranslate } from '../localization/Translate';

export const ParticipateAgainNotification = ({
  onClickStartOver,
}: {
  onClickStartOver: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const [show, setShow] = React.useState(true);
  const { theme } = useRagnar();
  const translate = useTranslate();

  return (
    <DrawerTop show={show} zIndex={theme.zIndex.notification} sticky>
      <Box
        bg="bg"
        px={2}
        py={3}
        width="100%"
        alignItems="center"
        boxShadow="close"
      >
        <Text>{translate('messages.do_you_want_to_start_over')}</Text>
        <Box flexDirection="row" mt={2}>
          <Button size="compact" onClick={() => setShow(false)} mr={2}>
            {translate('buttons.hide')}
          </Button>
          <Button variant="primary" size="compact" onClick={onClickStartOver}>
            {translate('buttons.start_over')}
          </Button>
        </Box>
      </Box>
    </DrawerTop>
  );
};
