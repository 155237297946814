import * as React from 'react';
import {
  styled,
  LAYOUT,
  POSITION,
  FLEX_CONTAINER,
} from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { CharacterCountVariantProps } from './CharacterCount.variants';

const FelaCharacterCountWrap = styled('div', {
  displayName: 'CharacterCountWrap',
})(...LAYOUT, ...FLEX_CONTAINER, ...POSITION, 'color', 'kosmosSpacing');

type CharacterCountWrapT = React.ComponentProps<
  typeof FelaCharacterCountWrap
> & {
  multiline?: CharacterCountVariantProps['multiline'];
};

export const CharacterCountWrap = React.forwardRef(
  ({ className, multiline, ...props }: CharacterCountWrapT, ref) => {
    const defaultProps = [
      'relative',
      'flex',
      multiline ? 'items-end' : 'items-center',
    ];
    return (
      <FelaCharacterCountWrap
        className={cn(defaultProps, className)}
        {...props}
        ref={ref}
      />
    );
  },
);
