import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(
  ['font-semibold', 'leading-normal', 'text-[inherit]'],
  {
    variants: {
      size: {
        default: ['mx-2', 'my-1', 'text-87.5'],
        compact: ['mx-1.5', 'my-0.5', 'text-62.5'],
      },
      textTransform: {
        uppercase: ['uppercase'],
        lowercase: ['lowercase'],
        capitalize: ['capitalize'],
        none: ['normal-case'],
      },
    },
    defaultVariants: {
      size: 'default',
      textTransform: 'capitalize',
    },
  },
);

export type BadgeTextVariantProps = VariantProps<typeof variants>;
