import type { ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import io from 'socket.io-client';

export type CreateSocketOptions = Partial<SocketOptions & ManagerOptions>;

const RECONNECTION_DELAY_MS = 1000;
const RECONNECTION_RANDOMIZATION_FACTOR = 0;
const RECONNECTION_DELAY_MAX_MS = 2000;

export const defaultSocketOptions: CreateSocketOptions = {
  transports: ['websocket'],
  randomizationFactor: RECONNECTION_RANDOMIZATION_FACTOR,
  reconnectionDelay: RECONNECTION_DELAY_MS,
  reconnectionDelayMax: RECONNECTION_DELAY_MAX_MS,
  forceNew: true,
};

export const createQuizSocket = (
  quizUrl: string,
  options: CreateSocketOptions,
): Socket => {
  return io(`${quizUrl}/quiz2`, options);
};
