import * as React from 'react';
import {
  Box,
  Clickable,
  type ClickableT,
  Image,
  ModalBody,
  ModalDismiss,
  ModalContainer,
  ModalRoot,
  ModalTrigger,
  ModalHeader,
  ScreenReaderOnly,
} from '@mentimeter/ragnar-ui';
import type { ImageResource } from '@mentimeter/http-clients';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import type { TranslationFn } from '@mentimeter/question-modules-types';

interface QuestionImageT extends ClickableT {
  src: ImageResource | null | undefined;
  translateFn: TranslationFn;
}

const DEFAULT_LABEL = 'Question Image';

export const QuestionImage = ({
  src,
  translateFn,
  ...props
}: QuestionImageT) => {
  if (!src) return null;

  const largeImageUrl = src?.presets?.huge?.url;
  const smallImageUrl = src?.presets?.medium?.url;

  const label = src?.alt_description;

  return (
    <ModalRoot>
      <ModalTrigger>
        <Clickable
          type="button"
          alignItems="center"
          aria-label={`${translateFn('buttons.show_image_for')} ${label || DEFAULT_LABEL}`}
          mb="space6"
          width="100%"
          {...props}
        >
          <Box
            alignItems="center"
            borderRadius="lg"
            overflow="hidden"
            maxHeight="300px"
          >
            <Image
              src={smallImageUrl}
              alt={label || DEFAULT_LABEL}
              style={{ objectFit: 'contain', overflow: 'hidden' }}
            />
          </Box>
        </Clickable>
      </ModalTrigger>
      <ModalContainer size="large" p="space1.5">
        <ScreenReaderOnly>
          <ModalHeader />
        </ScreenReaderOnly>
        <ModalDismiss>
          <Clickable
            type="button"
            aria-label={translateFn('close_image')}
            position="absolute"
            top={0}
            right={0}
            p="space2"
            mr="space4"
            mt="space4"
            bg="bg"
            borderRadius="full"
            zIndex={2}
          >
            <CrossIcon size={3} color="text" />
          </Clickable>
        </ModalDismiss>

        <ModalBody px="space0" pb="space0" pt="space0" borderRadius="xl">
          <Box borderRadius="xl" overflow="hidden">
            <Image src={largeImageUrl} alt={label ?? 'Image preview'} />
          </Box>
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
};
