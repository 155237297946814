/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import type { BoxT } from '../Box';
import { CharacterCount, CharacterCountWrap } from '../CharacterCount';

interface FocusabledCharacterCountT extends BoxT {
  maxLength?: number | undefined;
  value: string;
  render: (arg0: {
    value: string;
    onFocus: React.FocusEventHandler;
    onBlur: React.FocusEventHandler;
  }) => React.ReactNode;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  multiline?: boolean;
  iconTrailing?: boolean;
}

export default function FocusableCharacterCount({
  id,
  render,
  maxLength,
  value,
  multiline,
  onFocus,
  onBlur,
  iconTrailing,
  className,
  ...rest
}: FocusabledCharacterCountT) {
  const [inFocus, setInFocus] = React.useState(false);

  const handleFocus = (e: React.FocusEvent) => {
    setInFocus(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent) => {
    setInFocus(false);
    if (onBlur) onBlur(e);
  };

  return (
    <CharacterCountWrap
      multiline={multiline}
      className={clsx(['w-full', 'flex-1'], className)}
      {...rest}
    >
      {render({
        onFocus: handleFocus,
        onBlur: handleBlur,
        value,
      })}
      {inFocus && maxLength && (
        <CharacterCount
          maxLength={maxLength}
          value={value}
          multiline={multiline}
          iconTrailing={iconTrailing}
          id={id}
        />
      )}
    </CharacterCountWrap>
  );
}
