import * as React from 'react';
import { Image, Box } from '@mentimeter/ragnar-ui';
import { useAppSelector } from '../../redux-hooks';
import { useTranslate } from '../localization/Translate';

export const SeriesLogo = ({ ...props }) => {
  const logoUrl = useAppSelector(
    (state) => state.series.theme.logo?.presets?.small?.url,
  );
  const translate = useTranslate();

  if (!logoUrl) return null;

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      pb={['space10', 'space18']}
      {...props}
    >
      <Image
        src={logoUrl}
        alt={translate('aria.mentimeter_logo')}
        extend={() => ({ display: 'block' })}
        maxHeight="28px"
        width="auto"
      />
    </Box>
  );
};
