import type { BadgeTypes } from './badge.types';
import { BADGE_MAP } from './badgeMap';

export function badgeLabel(type: BadgeTypes, text: string | undefined) {
  const badgeTitle = text || BADGE_MAP[type]?.title;

  return typeof badgeTitle === 'string'
    ? `${badgeTitle} badge`
    : `${type} badge`;
}
