import React from 'react';
import {
  alpha,
  contrast,
  darkPalette,
  isLight,
  themes,
  whiteBase,
} from '@mentimeter/ragnar-colors';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { Theme } from './Theme';

export interface ThemeBgSensitiveT {
  children: React.ReactNode;
  themeBg: string;
}

export const ThemeBgSensitive = ({ children, themeBg }: ThemeBgSensitiveT) => {
  const themeBgIsLight = isLight(themeBg);
  const comparisonColor = themeBgIsLight
    ? themes.light.colors.bg
    : themes.dark.colors.bg;

  const contrastValue = contrast(themeBg, comparisonColor);
  const contrastThreshold = 1.1;

  const handleContrast = (contrastValue: number, themeBgIsLight: boolean) => {
    switch (true) {
      case themeBgIsLight && contrastValue <= contrastThreshold:
        return themes.light;
      case themeBgIsLight && contrastValue > contrastThreshold:
        return {
          colors: {
            ...themes.light.colors,
            surfaceOverlayTransparent: alpha(whiteBase, 0.6),
            surfaceOverlayTransparentHover: alpha(whiteBase, 0.95),
          },
        };
      case !themeBgIsLight && contrastValue <= contrastThreshold:
        return themes.dark;
      case !themeBgIsLight && contrastValue > contrastThreshold:
        return {
          colors: {
            ...themes.dark.colors,
            surfaceOverlayTransparent: alpha(darkPalette.gray1300, 0.6),
            surfaceOverlayTransparentHover: alpha(darkPalette.gray1300, 0.95),
          },
        };
      default:
        return themes.light;
    }
  };

  const theme = handleContrast(contrastValue, themeBgIsLight);

  return (
    <Theme theme={{ fonts: designSystemConfig.fonts, ...theme }}>
      {children}
    </Theme>
  );
};
