import { styled } from '@mentimeter/ragnar-styled';
import React, { forwardRef } from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { inputVariants, type InputVariantProps } from '../utils';

export interface TextareaItemT
  extends React.ComponentProps<typeof TextareaItemStyled> {
  inputSize?: InputVariantProps['size'];
  status?: InputVariantProps['variant'];
  disabled?: boolean | undefined;
}

const TextareaItemStyled = styled('textarea')(
  'minHeight',
  'maxHeight',
  'kosmosSpacing',
);

export const TextareaItem = forwardRef<
  HTMLTextAreaElement | null,
  TextareaItemT
>(({ inputSize, className, ...props }, ref) => {
  const defaultClasses = ['r-textarea', 'resize-y', 'min-h-[70px]'];
  const variantClasses = inputVariants({
    variant: props?.status,
    size: inputSize,
  });

  return (
    <TextareaItemStyled
      ref={ref}
      className={cn(defaultClasses, variantClasses, className)}
      {...props}
    />
  );
});
