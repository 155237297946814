import * as React from 'react';
import { SpeechBubblePen } from '@mentimeter/ragnar-visuals';
import { Box, MotionContainer } from '@mentimeter/ragnar-ui';
import { Idle } from '../../ui';
import { useTranslate } from '../localization/Translate';

const HasVotedOnQuestion = ({
  pictogramColor,
}: {
  pictogramColor: string | undefined;
}) => {
  const translate = useTranslate();

  return (
    <Idle
      preTitle={translate('messages.please_wait')}
      title={translate('messages.already_responded')}
    >
      <MotionContainer animation="fade">
        <MotionContainer animation="float">
          <Box height="112px" my="space14">
            <SpeechBubblePen userColor={pictogramColor} />
          </Box>
        </MotionContainer>
      </MotionContainer>
    </Idle>
  );
};

export default HasVotedOnQuestion;
