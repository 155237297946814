import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const characterCount = cva(
  [
    'leading-none',
    'text-62.5',
    'font-body',
    'font-semibold',
    'transition-colors',
    'duration-300',
    'ease-in-out',
    'absolute',
    'right-0',
    'pointer-events-none',
  ],
  {
    variants: {
      color: {
        default: 'text',
        negative: 'text-negative',
      },
      iconTrailing: {
        true: 'pr-[35px]',
        false: 'pr-2',
      },
      multiline: {
        true: 'pb-2',
        false: '',
      },
    },
    defaultVariants: {
      color: 'default',
      iconTrailing: false,
      multiline: false,
    },
  },
);

export type CharacterCountVariantProps = VariantProps<typeof characterCount>;
