import { cva } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(
  [
    'bg-primary',
    'text-on-primary',
    'flex-row',
    'items-center',
    'rounded-full',
    'select-none',
  ],
  {
    variants: {
      variant: {
        basic: ['bg-positive-weak', 'text-on-positive-weak'],
        pro: ['bg-positive-weak', 'text-on-positive-weak'],
        'pro-multi': ['bg-neutral-weak', 'text-on-neutral-weak'],
        enterprise: ['bg-positive-weak', 'text-on-positive-weak'],
        new: ['bg-notice-weak', 'text-on-notice-weak'],
        beta: ['bg-info-weak', 'text-on-info-weak'],
        upgrade: ['bg-positive-weak', 'text-on-positive-weak'],
        shared: ['bg-info-weak', 'text-on-info-weak'],
        template: ['bg-info-weak', 'text-on-info-weak'],
        example: ['bg-neutral-weak', 'text-on-neutral-weak'],
        team: ['bg-info-weak', 'text-on-info-weak'],
        admin: ['bg-notice-weak', 'text-on-notice-weak'],
        soon: ['bg-info-weak', 'text-on-info-weak'],
        text: ['bg-info-weak', 'text-on-info-weak'],
        'view-only': ['bg-neutral-weak', 'text-on-neutral-weak'],
        'view-only-icon': ['bg-neutral-weak', 'text-on-neutral-weak'],
        archived: ['bg-neutral-weak', 'text-on-neutral-weak'],
        '50-licenses': ['bg-neutral-weak', 'text-on-neutral-weak'],
        warning: ['bg-notice-weak', 'text-on-notice-weak'],
        play: ['bg-high-contrast', 'text-on-high-contrast'],
        'published-template': ['bg-info-weak', 'text-on-info-weak'],
        premium: ['bg-positive-weak', 'text-on-positive-weak'],
        free: ['bg-notice-weak', 'text-on-notice-weak'],
        accessibility: ['bg-notice-weak', 'text-on-notice-weak'],
      },
    },
  },
);
