export const average = (numbers: number[]) => {
  const sum = numbers.reduce((s, value) => s + value, 0);
  return sum / numbers.length;
};
export const median = (values: number[]): number => {
  const sortedValues = [...values].sort((a, b) => a - b);
  const middleIndex = Math.floor(sortedValues.length / 2);
  const middleValue = sortedValues[middleIndex];
  const beforeMiddleValue = sortedValues[middleIndex - 1];

  if (!middleValue) {
    return 0;
  }

  if (!beforeMiddleValue) {
    return middleValue;
  }
  return (beforeMiddleValue + middleValue) / 2.0;
};

export const standardDeviation = (numbers: number[]) => {
  const avg = average(numbers);
  const squareDiffs = numbers.map((value) => {
    const diff = value - avg;
    const sqrDiff = diff * diff;
    return sqrDiff;
  });
  const avgSquareDiff = average(squareDiffs);
  return Math.sqrt(avgSquareDiff) || 0;
};

export const round = (value: number) => Math.round(value * 100) / 100;
