import type { VariantProps } from '@mentimeter/ragnar-tailwind-config';
import { cva } from '@mentimeter/ragnar-tailwind-config';

export const prefixWrapperVariants = cva(
  [
    'flex-row',
    'flex-wrap',
    'items-center',
    'overflow-auto',
    'inline-flex',
    'min-h-[40px]',
    'gap-1',
    'pl-2',
    'h-auto',
    'w-full',
    'box-border',
    'font-body',
    '[&_input]:!border-0',
    '[&_input]:!shadow-none',
    '[&_input]:!outline-none',
    '[&_input]:!bg-transparent',
    'has-[:focus]:outline',
    'has-[:focus]:outline-offset-2',
    'has-[:focus]:outline-4',
    'has-[:focus]:outline-interactive-focused',
    'has-[:disabled]:bg-disabled-strong',
    'has-[:disabled]:text-on-disabled-strong',
    'has-[:disabled]:placeholder:text-on-disabled-strong',
    'has-[:disabled]:cursor-not-allowed',
    'pl-2',
    'rounded-lg',
    'text-87.5',
    'leading-100',
  ],
  {
    variants: {
      variant: {
        idle: [
          'bg-input',
          'text',
          'has-[:enabled]:shadow-[inset_0_0_0_2px_theme(borderColor.weaker)]',
          'has-[:enabled]:hover:shadow-[inset_0_0_0_2px_theme(colors.secondary.DEFAULT)]',
          'has-[:enabled:focus]:shadow-[inset_0_0_0_2px_theme(colors.secondary.DEFAULT)]',
        ],
        error: [
          'bg-negative-weakest',
          'text-on-negative-weakest',
          'has-[:enabled]:hover:bg',
          'has-[:enabled]:shadow-[inset_0_0_0_2px_theme(colors.negative.DEFAULT)]',
        ],
      },
    },
    defaultVariants: {
      variant: 'idle',
    },
  },
);

export type PrefixWrapperVariantsProps = VariantProps<
  typeof prefixWrapperVariants
>;
