import * as React from 'react';
import { Button as ButtonR, type ButtonT } from '@mentimeter/ragnar-ui';
import { connect } from 'formik';

export const Button: React.ComponentType<ButtonT> = connect(
  ({ formik, ...props }) => {
    const { isSubmitting, isValid } = formik;
    return (
      <ButtonR
        disabled={isSubmitting || !isValid}
        state={isSubmitting ? 'loading' : undefined}
        type="submit"
        {...props}
      />
    );
  },
);
